<template>
    <b-row class="steps">
        <!-- <b-col class="p-0"><div class="select-step"></div><div class="point"></div></b-col> -->
        <b-col class="p-0" v-for="item in stepsCount" v-bind:key="item">
            <div :class="item>currentStep? 'step' : 'select-step'"></div><div v-if="item>currentStep" class="point"></div>
            <div v-if="item == currentStep" class="msg-step">{{title.toUpperCase()}}</div>
        </b-col>
    </b-row>
</template>

<script>

export default {
    name: "Steps",
    props: {
        stepsCount:Number,
        currentStep:Number,
        title:String
    },
    data() {
        return {
        };
    },
    methods: {
    },
};
</script>
<style lang="scss" scoped>
.steps{
    .step{
        height: 1px;
        background-color: #D8D9E2;
        margin-left: 3px;
    }
    .select-step{
        background-color: #484DCA;
        height: 2px;
        margin-top: -0.5px;
        margin-right: -3px;

    }
    .point{
        height: 3px;
        width: 3px;
        background-color: #484DCA;
        margin-left: 100%;
        margin-right: 3px;
        margin-top: -2px;
    }
    .msg-step{
        color: #8687A4;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        padding-top: 24px;
    }
}
</style>