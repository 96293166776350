var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab"},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"ml-2"},[_vm._v("Loading...")])],1)]):_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('b-row',[_c('b-col',{staticClass:"head-title"},[_vm._v("Job Information")]),(!_vm.isEdit)?_c('b-col',{staticClass:"text-end"},[_vm._v("Edit")]):_c('b-col',{staticClass:"text-end"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Save")])],1)],1),_c('div',{staticClass:"title"},[_vm._v("Summary of job")]),_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Name of Work Order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Name of Work Order "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter name of Work Order","required":"","disabled":!_vm.isEdit},model:{value:(_vm.details.title),callback:function ($$v) {_vm.$set(_vm.details, "title", $$v)},expression:"details.title"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Work order #","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Work order # "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter work order #","required":"","disabled":!_vm.isEdit},model:{value:(_vm.details.airtable_project_id),callback:function ($$v) {_vm.$set(_vm.details, "airtable_project_id", $$v)},expression:"details.airtable_project_id"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Branch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Branch "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter branch","required":"","disabled":!_vm.isEdit},model:{value:(_vm.details.branch),callback:function ($$v) {_vm.$set(_vm.details, "branch", $$v)},expression:"details.branch"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"justify-content-md-start "},[_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Period date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Period date "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter start date","required":"","disabled":!_vm.isEdit},model:{value:(_vm.details.start_date),callback:function ($$v) {_vm.$set(_vm.details, "start_date", $$v)},expression:"details.start_date"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"col-auto p-0 date-margin pt-2"},[_vm._v("-")]),_c('b-col',{staticClass:"date-margin"},[_c('ValidationProvider',{attrs:{"name":"Period date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter start date","required":"","disabled":!_vm.isEdit},model:{value:(_vm.details.start_date),callback:function ($$v) {_vm.$set(_vm.details, "start_date", $$v)},expression:"details.start_date"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Job Duration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Job Duration "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter job Duration","required":"","disabled":!_vm.isEdit},model:{value:(_vm.details.job_duration),callback:function ($$v) {_vm.$set(_vm.details, "job_duration", $$v)},expression:"details.job_duration"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"name":"People needed","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("People needed "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter people needed","required":"","disabled":!_vm.isEdit},model:{value:(_vm.details.workers.required),callback:function ($$v) {_vm.$set(_vm.details.workers, "required", $$v)},expression:"details.workers.required"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"People Added","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("People Added "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter people Added","required":"","disabled":!_vm.isEdit},model:{value:(_vm.details.workers.confirmed),callback:function ($$v) {_vm.$set(_vm.details.workers, "confirmed", $$v)},expression:"details.workers.confirmed"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"People Working","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("People Working "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter people Working","required":"","disabled":!_vm.isEdit},model:{value:(_vm.details.workers.workers),callback:function ($$v) {_vm.$set(_vm.details.workers, "workers", $$v)},expression:"details.workers.workers"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Job Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Job Category "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter job Category","required":"","disabled":!_vm.isEdit},model:{value:(_vm.details.job_category),callback:function ($$v) {_vm.$set(_vm.details, "job_category", $$v)},expression:"details.job_category"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Job specialty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Job specialty "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter job specialty ","required":"","disabled":!_vm.isEdit},model:{value:(_vm.details.job_specialty),callback:function ($$v) {_vm.$set(_vm.details, "job_specialty", $$v)},expression:"details.job_specialty"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('ValidationProvider',{attrs:{"name":"Job location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Job location "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter job location","required":"","disabled":!_vm.isEdit},model:{value:(_vm.details.job_category),callback:function ($$v) {_vm.$set(_vm.details, "job_category", $$v)},expression:"details.job_category"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Work hours","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Work hours "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter work hours","required":"","disabled":!_vm.isEdit},model:{value:(_vm.details.work_hours),callback:function ($$v) {_vm.$set(_vm.details, "work_hours", $$v)},expression:"details.work_hours"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }