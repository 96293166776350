<template>
  <div class="tab">
    <div class="head-title">Reviews</div>
    <b-form-textarea
      class="mt-4"
      placeholder="Click and write your own review ..."
      v-model="note"
    ></b-form-textarea>
    <div class="text-end mt-3 mb-3"><span class="add-comment" v-on:click="addComment()">Add comment</span></div>
    <hr/>
    <div v-if="loading">
      <div class="text-center text-secondary my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Loading...</strong>
      </div>
    </div>
    <div v-else>
      <div v-if="reviews.length > 0">
        <comment
          v-for="(item,index) in reviews"
          v-bind:key="index"
          :comment="item"/>
      </div>
      <div v-else class="comments-not-found">Comments not found</div>
    </div>
  </div>
</template>

<script>
import Comment from './components/Comment.vue';
export default {
    name: "Reviews",
    props: {
        type:String
    },
    components: {
        Comment
    },
    computed: {
        reviews() {
          return this.$store.state[this.type].reviews;
        },
        loading(){
          return this.$store.state[this.type].loadingReviews;
        }
    },
    data() {
        return {
          note:''
        };
    },
    methods: {
      addComment(){
        if(this.note.length > 0){
          let date = {
            id: this.$route.params.id,
            note: this.note
          }
          this.$store.dispatch(this.type + '/addComment',date);
        }
      }
    },
};
</script>
<style  scoped>
.comments-not-found{
  text-align: center;
  font-size: 20px;
  padding: 30px;
}
</style>