<template>
    <div>
        <b-modal id="right-modal" :visible="isShowLocal" @hide="closeModal">
            <div class="edit">
                <b-row class="justify-content-md-center mb-3">
                    <b-col class="title p-0">Edit Work Orders</b-col>
                    <b-col class="col-auto required"><span class="star">*</span> Required</b-col>
                </b-row>
                <steps
                    class="steps"
                    :currentStep="currentStep"
                    :stepsCount="6"
                    :title="getTitle()"
                    
                />
                <div v-if="loading">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                </div>
                <div v-else>
                    <initial-information
                        v-if="currentStep == 1"
                        type="edit"
                        @next="currentStep++"
                        class="tab"
                    />
                    <job-information
                        v-if="currentStep == 2"
                        type="edit"
                        @next="currentStep++"
                    />
                    <positions-tab
                        v-if="currentStep == 3" 
                        type="edit"
                        @next="currentStep++"
                    />
                    <contact
                        v-if="currentStep == 4"
                        type="edit"
                        @next="currentStep++"
                    />
                    <billing-information
                        v-if="currentStep == 5"
                        type="edit"
                        @next="currentStep++"
                    />
                    <files
                        v-if="currentStep == 6"
                        type="edit"
                        @next="currentStep++"
                    />
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import Steps from '@/components/common/Steps.vue';
import JobInformation from '@/components/workOrders/createAndEdit/JobInformation.vue';
import InitialInformation from '@/components/workOrders/createAndEdit/InitialInformation.vue';
import PositionsTab from '@/components/workOrders/createAndEdit/PositionsTab.vue';
import Contact from '@/components/workOrders/createAndEdit/Contact.vue';
import BillingInformation from '@/components/workOrders/createAndEdit/BillingInformation.vue';
import Files from '@/components/workOrders/createAndEdit/Files.vue';
export default {
    props:{
        isShow:Boolean,
        id:Number
    },
    components: {
        Steps,
        InitialInformation,
        JobInformation,
        PositionsTab,
        Contact,
        BillingInformation,
        Files
    },
    watch: {
        isShow() {
            this.isShowLocal = this.isShow;
            this.$store.state.workOrders.positions = [];
            this.$store.dispatch('workOrders/getEditWorkOrder', this.id);
            this.$store.dispatch('workOrders/getEditPositions', this.id);
        }
    },
    data() {
        return {
            isShowLocal:false,
            currentStep:1
        }
    }, 
    computed: {
        loading(){
            return this.$store.state.workOrders.loadingWorkOrder;
        }
    },
    methods:{
        getTitle(){
            switch (this.currentStep) {
                case 1:
                return 'Initial info';
                case 2:
                return 'Job info';            
                case 3:
                return 'Position(s) info';            
                case 4:
                return 'Contact person info';           
                case 5:
                return 'Billing info';          
                case 6:
                return 'attachments';
                default:
                return;
            }
        },
        closeModal(){
            this.$emit('closeModal');
        },
    }
}
</script>
<style scoped>
.edit{
    padding: 28px 32px 0px 32px;
}
</style>