<template>
    <div>
        <label class="label">Title of work experience</label>
        <b-form-input
            type="text"
            placeholder="Enter title"
            v-model="experience.title"
        ></b-form-input> 
        <label class="label">Experience level</label>
        <b-select 
            v-model="experience.type_employment" 
            :options="levels" 
            placeholder="Select type">
            <template #first>
                <b-form-select-option class="placeholder" :value="null">Select level</b-form-select-option>
            </template>
        </b-select>
        <label class="label">Job year</label>
        <b-form-input
            type="number"
            placeholder="Enter year"
            v-model="experience.years_of_experiences"
        ></b-form-input> 
    </div>
</template>
<script>
export default {
    props:{
        experience:Object
    },
    computed:{
        levels(){
            return this.$store.state.applicants.levels;
        }
    }
}
</script>