<template>
    <div> 
        <div v-bind:class="[type == 'edit' ? 'edit-tab' : '','create-or-edit-tab']">
            <div  v-if="type == 'add'" class="head-title mb-4">You can add some files, if you need</div>
            <div class="files">
                <div v-if="files.length == 0">Here will be your filles</div>
                <div v-else> 
                    <div class="your-files">Your files</div>
                    <b-row v-for="(item, index) in files" v-bind:key="index" class="justify-content-md-center files-list">
                        <b-col><img src="@/assets/icons/paperclip.svg" class="mr-2 mt-n1"/>{{item.name}}</b-col>
                        <b-col class="delete col-auto" v-on:click="deleteFile(index)"><img src="@/assets/icons/delete.svg" class="mt-n1"/></b-col>
                    </b-row>
                </div>
            </div>
            <label for="select-file" class="label-file"><img src="@/assets/icons/paperclip-white.svg" class="mr-2"/>Add attachment</label>
            <b-form-file  
                id="select-file"
                plain accept=".doc, .pdf, .docx, .jpg, .jpeg, .png"
                v-on:input="onChange"
            />
        </div>      
        <div v-bind:class="type == 'add'? 'bottom-btns' : 'mt-3'">
            <b-row>
                <b-col v-bind:class="type == 'add' ? 'text-center' : 'pl-0'"><span v-on:click="cancel" class="cancel">Cancel</span></b-col>
                <b-col v-bind:class="type == 'add' ? 'text-center' : 'text-end pr-0'">
                    <b-row>
                        <b-col><b-button v-on:click="previous" variant="outlined">Previous</b-button></b-col>
                        <b-col><b-button type="submit" variant="primary">Next</b-button></b-col>                            
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>

export default {
    name: "Files",
    props:{
        type:String
    },
    computed: {
        files(){
            return this.type == 'add'? this.$store.state.workOrders.files : this.$store.state.workOrders.workOrder.attachments ;
        }
    },
    methods: {
        save(){
            this.$emit('next');
        },
        previous(){
            this.$emit('previous');
        },
        cancel(){            
            this.$router.push({ name: 'WorkOrders' });
        },
        onChange(value){
            if(this.files.find(f => f.name == value.name && f.size == value.size && f.lastModified == value.lastModified) == undefined){
                this.files.push(value);
            }
        },
        deleteFile(index){
            this.files.splice(index, 1);
        }
    },
};
</script>
<style lang="scss" scoped>

</style>