<template>
    <div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <div v-bind:class="[type == 'edit' ? 'edit-tab' : '','create-or-edit-tab']">
                    <div v-if="type == 'add'" class="head-title">Please enter initial information</div>
                    <div class="title">General Info</div>
                    <ValidationProvider
                        name="Name of work order"
                        rules="required"
                        v-slot="{ failed, errors }">
                            <label class="label">Name of work order<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter name of work order"
                                v-model="workOrder.title"
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Company"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Company<span class="star">*</span></label>
                            <b-select 
                                v-model="workOrder.company_id" 
                                @change="updateCompany"
                                :class="[{ 'has-danger': failed }]">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select company</b-form-select-option>
                                    </template>
                                    <option
                                        v-for="(option, index) in params.companies"
                                        :key="index"
                                        :value="index" >
                                            {{ option }}
                                        </option>
                                </b-select>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <label class="label">Branch<span class="star">*</span></label>
                    <b-select v-model="workOrder.branch_id">
                        <template #first>
                            <b-form-select-option class="placeholder" :value="null">Select branch</b-form-select-option>
                        </template>
                        <option
                            v-for="(option, index) in branches"
                            :key="index"
                            :value="index" >
                                {{ option }}
                        </option>
                    </b-select>
                    <ValidationProvider
                        name="Job Status"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Job Status<span class="star">*</span></label>
                            <b-select 
                                v-model="workOrder.status" 
                                :class="[{ 'has-danger': failed }]">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select job Status</b-form-select-option>
                                    </template>
                                    <option
                                        v-for="(option, index) in params.job_statuses"
                                        :key="index"
                                        :value="index" >
                                            {{ option }}
                                        </option>
                                </b-select>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <label class="label">Number of Workers</label>
                    <b-form-input
                        type="number"
                        placeholder="Enter name of work order"
                        v-model="workOrder.number_of_workers"
                        ></b-form-input> 


                    <div class="title">Address Info</div>
                    <ValidationProvider
                        name="Address"
                        rules="required"
                        v-slot="{ failed, errors }">
                            <label class="label">Address<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter address"
                                v-model="workOrder.job_location.address"
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="State"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">State<span class="star">*</span></label>
                            <b-select 
                                v-model="workOrder.job_location.state" 
                                @change="updateState" 
                                :class="[{ 'has-danger': failed }]">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select state</b-form-select-option>
                                    </template>
                                    <option
                                        v-for="(option, index) in  params.states"
                                        :key="index"
                                        :value="index" >
                                            {{ option }}
                                        </option>
                                </b-select>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="City"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">City<span class="star">*</span></label>
                            <b-select 
                                v-model="workOrder.job_location.city"
                                @change="updateCity" 
                                :class="[{ 'has-danger': failed }]">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select city</b-form-select-option>
                                    </template>
                                    <option
                                        v-for="(option, index) in cities"
                                        :key="index"
                                        :value="index" >
                                            {{ option }}
                                        </option>
                                </b-select>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Zip Code"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Zip Code<span class="star">*</span></label>
                            <b-select 
                                v-model="workOrder.job_location.zipcode" 
                                :class="[{ 'has-danger': failed }]">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select zip code</b-form-select-option>
                                    </template>
                                    <option
                                        v-for="(option, index) in zipcode"
                                        :key="index"
                                        :value="index" >
                                            {{ option }}
                                        </option>
                                </b-select>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                </div>
                <div v-bind:class="type == 'add'? 'bottom-btns' : 'mt-3'">
                    <b-row >
                        <b-col v-bind:class="type == 'add' ? 'text-center' : 'pl-0'"><span v-on:click="cancel" class="cancel">Cancel</span></b-col>
                        <b-col v-bind:class="type == 'add' ? 'text-center' : 'text-end pr-0'"><b-button type="submit" variant="primary">Next</b-button></b-col>
                    </b-row>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
extend('required', required);

export default {
    name: "InitialInformation",
    data() {
        return {
        };
    },
    props:{
        type:String
    },
    computed: {
        workOrder(){
            if(this.type == 'edit'){
                let edit = this.$store.state.workOrders.workOrder;
                if(edit.company_id != null && edit.company_id != undefined)             
                    this.$store.dispatch('workOrders/branchesByCompanyId', edit.company_id);

                if(edit.job_location != undefined && edit.job_location.state != undefined && edit.job_location.state != null)             
                    this.$store.dispatch('common/getCitiesByIdState', edit.job_location.state);

                if(edit.job_location != undefined && edit.job_location.city != undefined && edit.job_location.city != null)             
                    this.$store.dispatch('common/getZipCodesByIdCity', edit.job_location.city);
                
                return edit;
            }
            else
                return this.$store.state.workOrders.newWorkOrder;
        },
        params(){
            return this.$store.state.workOrders.params;
        },
        branches(){
            return this.$store.state.workOrders.branches;
        },
        cities(){
            return this.$store.state.common.cities;
        },
        zipcode(){
            return this.$store.state.common.zipCodes;
        }
    },
    methods: {
        submit(){
            this.$emit('next');
        },
        cancel(){            
            this.$router.push({ name: 'WorkOrders' });
        },
        updateCompany(){
            if(this.$store.state.workOrders.newWorkOrder.company_id != null)
                this.$store.dispatch('workOrders/branchesByCompanyId', this.$store.state.workOrders.newWorkOrder.company_id);
        },
        updateState(){
            if(this.$store.state.workOrders.newWorkOrder.job_location.state != null)
                this.$store.dispatch('common/getCitiesByIdState', this.$store.state.workOrders.newWorkOrder.job_location.state);
        },
        updateCity(){                
            if(this.$store.state.workOrders.newWorkOrder.job_location.city != null)
                this.$store.dispatch('common/getZipCodesByIdCity', this.$store.state.workOrders.newWorkOrder.job_location.city);
        },
    },
};
</script>
<style lang="scss" scoped>
</style>