<template>
    <b-modal :visible="isShowLocal" @hide="closeModal" centered>
        <h3>{{title}}</h3>
        <h6>{{msg}}</h6>
        <div class="text-end">
            <b-button v-on:click="ok()" variant="outlined">Cancel</b-button>
            <b-button v-on:click="ok()" variant="primary">Ok</b-button>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: "InfoModal",
    props:{
        isShow:Boolean,
        title:String,
        msg:String
    },
    watch: {
        isShow() {
            this.isShowLocal = this.isShow;
        }
    },
    data() {
        return {
            isShowLocal:false
        };
    },
    methods: {
        ok(){
            this.$emit('ok');
        },
        closeModal(){
            this.$emit('close');
        }
    },
};
</script>
<style  scoped>
</style>