var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"right-modal","visible":_vm.isShowLocal},on:{"hide":_vm.closeModal}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{staticClass:"title"},[_vm._v("Assign Workers")]),_c('b-col',{staticClass:"col-auto required"},[_c('span',{staticClass:"star"},[_vm._v("*")]),_vm._v(" Required")])],1),_c('div',{staticClass:"title-2"},[_vm._v("Initial Info")]),_c('ValidationProvider',{attrs:{"name":"Work Order","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Work Order Title"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-select',{class:[{ 'has-danger': failed }],attrs:{"disabled":_vm.step == 2},on:{"scroll":_vm.scrollWorkers,"change":_vm.applicantsAddToWorkOrder},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{staticClass:"placeholder",attrs:{"value":null}},[_vm._v("Select work order ")])]},proxy:true}],null,true),model:{value:(_vm.workOrderId),callback:function ($$v) {_vm.workOrderId=$$v},expression:"workOrderId"}},_vm._l((_vm.workOrders),function(option,index){return _c('option',{key:index,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.title)+" ")])}),0),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Position","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Position"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-select',{class:[{ 'has-danger': failed }],attrs:{"disabled":_vm.step==1},on:{"scroll":_vm.scrollWorkers,"change":_vm.changePosition},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{staticClass:"placeholder",attrs:{"value":null}},[_vm._v("Select position")])]},proxy:true}],null,true),model:{value:(_vm.newWorkOrder.position_id),callback:function ($$v) {_vm.$set(_vm.newWorkOrder, "position_id", $$v)},expression:"newWorkOrder.position_id"}},_vm._l((_vm.workOrdersData.positions),function(option,index){return _c('option',{key:index,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.title)+" ")])}),0),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Start Date","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Start Date"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-datepicker',{class:[{ 'has-danger': failed }],attrs:{"disabled":_vm.step==1},model:{value:(_vm.newWorkOrder.start_date),callback:function ($$v) {_vm.$set(_vm.newWorkOrder, "start_date", $$v)},expression:"newWorkOrder.start_date"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"title-2"},[_vm._v("Pay Rate")]),_c('ValidationProvider',{attrs:{"name":"Regular hourly rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Payroll regular hourly rate"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter regular hourly rate","required":"","disabled":_vm.step==1},model:{value:(_vm.newWorkOrder.regular_pay_rate),callback:function ($$v) {_vm.$set(_vm.newWorkOrder, "regular_pay_rate", $$v)},expression:"newWorkOrder.regular_pay_rate"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Payroll overtime hourly rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Payroll overtime hourly rate"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter payroll overtime hourly rate","required":"","disabled":_vm.step==1},model:{value:(_vm.newWorkOrder.overtime_pay_rate),callback:function ($$v) {_vm.$set(_vm.newWorkOrder, "overtime_pay_rate", $$v)},expression:"newWorkOrder.overtime_pay_rate"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Payroll doubletime hourly rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Payroll doubletime hourly rate"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter payroll doubletime hourly rate","required":"","disabled":_vm.step==1},model:{value:(_vm.newWorkOrder.doubletime_pay_rate),callback:function ($$v) {_vm.$set(_vm.newWorkOrder, "doubletime_pay_rate", $$v)},expression:"newWorkOrder.doubletime_pay_rate"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Payroll hourly calculator","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Payroll hourly calculator"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-select',{class:[{ 'has-danger': failed }],attrs:{"disabled":_vm.step==1},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{staticClass:"placeholder",attrs:{"value":null}},[_vm._v("Select payroll hourly calculator")])]},proxy:true}],null,true),model:{value:(_vm.newWorkOrder.payroll_hourly_calculator),callback:function ($$v) {_vm.$set(_vm.newWorkOrder, "payroll_hourly_calculator", $$v)},expression:"newWorkOrder.payroll_hourly_calculator"}},_vm._l((_vm.workOrdersData.calculators),function(option,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"title-2"},[_vm._v("Bill Rate")]),_c('ValidationProvider',{attrs:{"name":"Billing regular hourly rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Billing regular hourly rate"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter billing regular hourly rate","required":"","disabled":_vm.step==1},model:{value:(_vm.newWorkOrder.regular_bill_rate),callback:function ($$v) {_vm.$set(_vm.newWorkOrder, "regular_bill_rate", $$v)},expression:"newWorkOrder.regular_bill_rate"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Billing overtime hourly rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Billing overtime hourly rate"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter billing overtime hourly rate","required":"","disabled":_vm.step==1},model:{value:(_vm.newWorkOrder.overtime_bill_rate),callback:function ($$v) {_vm.$set(_vm.newWorkOrder, "overtime_bill_rate", $$v)},expression:"newWorkOrder.overtime_bill_rate"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Billing doubletime hourly rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Billing doubletime hourly rate"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter billing doubletime hourly rate","required":"","disabled":_vm.step==1},model:{value:(_vm.newWorkOrder.doubletime_bill_rate),callback:function ($$v) {_vm.$set(_vm.newWorkOrder, "doubletime_bill_rate", $$v)},expression:"newWorkOrder.doubletime_bill_rate"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Invoice hourly calculator","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Invoice hourly calculator"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-select',{class:[{ 'has-danger': failed }],attrs:{"disabled":_vm.step == 1},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{staticClass:"placeholder",attrs:{"value":null}},[_vm._v("Select invoice hourly calculator")])]},proxy:true}],null,true),model:{value:(_vm.newWorkOrder.invoice_hourly_calculator),callback:function ($$v) {_vm.$set(_vm.newWorkOrder, "invoice_hourly_calculator", $$v)},expression:"newWorkOrder.invoice_hourly_calculator"}},_vm._l((_vm.workOrdersData.calculators),function(option,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-row',{staticClass:"justify-content-md-center mt-4"},[_c('b-col',[_c('b-button',{attrs:{"variant":"outlined"},on:{"click":_vm.closeModal}},[_vm._v("Cancel")])],1),_c('b-col',{staticClass:"col-auto"},[(_vm.step == 2)?_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Save")]):_vm._e()],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }