<template>
    <div class="comment">
        <div class="author" v-on:click="redirectWorker(comment.author.id)">{{comment.author.name}}</div>
        <div class="date">{{getDate()}}</div>
        <div class="msg">{{comment.note}}</div>
        <hr/>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: "Comment",
    props: {
        comment:Object
    },
    methods: {
        getDate(){
            return moment(this.comment.created_at).format('lll');
        },
        redirectWorker(id){
            this.$router.push({ name: 'WorkerDetail' , params: { id: id } });
        }
    },
};
</script>
<style  scoped>

</style>