<template>
  <b-button type="submit" variant="primary" class="login_btn" @click="goToLoginPage()">Go to Login</b-button>
</template>

<script>
export default {
  name: "GoToLoginPage",
  methods: {
    goToLoginPage() {
      this.$router.push('/login');
      this.$store.dispatch('auth/clearUnloggedData');
    }
  }
}
</script>

<style scoped>

</style>