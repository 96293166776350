<template>
    <div>
        <!-- Loading -->
        <div v-if="loadingBreaksTypes || dataEntryUpdateLoading || dataEntryUpdate == null" class="loading">
            <div class="text-center text-secondary my-2 mt-5 pt-5">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
            </div>
        </div>
        <div v-else class="time-sheet">
            <!-- Header -->
            <b-row class="justify-content-md-center mb-3">
                <b-col class="bread-crumbs">
                    <span class="redirect" v-on:click="redirect('Dashboard')">Dashboard</span><span class="arrow">></span>
                    <span class="redirect" v-on:click="redirect('DataEntry')">Time sheet</span><span class="arrow">></span>
                    <span class="active">View time sheet</span>
                </b-col>
            </b-row>
            <!-- Total,paid,unpaid hours -->
            <hours/>

            <!-- Info -->
            <h5 class="mt-5">Team member</h5>
            <b-row>
                <b-col md="12" lg="6">
                    <div>
                        <span class="label">Name:</span>
                        <span v-if="dataEntryUpdate.user_name == null" class="not_chosen">Not chosen</span>
                        <span v-else>{{dataEntryUpdate.user_name}}</span>
                    </div>
                    <div>
                        <span class="label">Email:</span> 
                        <span v-if="dataEntryUpdate.user_email == null" class="not_chosen">Not chosen</span>
                        <span v-else class="color-blue">{{dataEntryUpdate.user_email}}</span>
                    </div>
                    <div>
                        <span class="label">Verified:</span> 
                        <span v-if="dataEntryUpdate.verified == 0" class="no">No</span>
                        <span v-else class="yes">Yes</span>
                        
                    </div>
                    <div>
                        <span class="label">File:</span>
                        <span v-if="dataEntryUpdate.file == null" class="not_chosen">Not chosen</span>
                        <span v-else v-on:click="openDocument(dataEntryUpdate.file.link)" class="cursor-pointer">
                            <img src="@/assets/icons/paperclip.svg" class="mr-1"/>{{dataEntryUpdate.file.name}}
                        </span>
                    </div>
                </b-col>
                <b-col md="12" lg="6">
                    <div>
                        <span class="label">Company:</span>
                        <span v-if="dataEntryUpdate.company == null" class="not_chosen">Not chosen</span>
                        <span v-else>{{dataEntryUpdate.company}}</span>
                    </div>
                    <div>
                        <span class="label">Work order:</span> 
                        <span v-if="dataEntryUpdate.work_order_name == null" class="not_chosen">Not chosen</span>
                        <span v-else>{{dataEntryUpdate.work_order_name}}</span>
                    </div>
                    <div>
                        <span class="label">Position:</span> 
                        <span v-if="dataEntryUpdate.user_position == null" class="not_chosen">Not chosen</span>
                        <span v-else>{{dataEntryUpdate.user_position}}</span>
                    </div>
                    <div>
                        <span class="label">Location:</span> 
                        <span v-if="dataEntryUpdate.user_location == null" class="not_chosen">Not chosen</span>
                        <span v-else>{{dataEntryUpdate.user_location}}</span>
                    </div>
                </b-col>
            </b-row>
            <div class="mt-2">
                <span class="label">Notes:</span>
                <span v-if="dataEntryUpdate.notes == null" class="not_chosen">Not filled</span>
                <span v-else>{{dataEntryUpdate.notes}}</span> 
            </div>

            <!-- Table hours -->
            <h5 class="mt-5">Hours</h5>
            <b-table :fields="headers" :items="days" >
                <template #cell(start)="data">
                    <div v-if="data.item.dateStart == null || data.item.timeStart == null ">Not chosen</div>
                    <div v-else>{{getDate(data.item.dateStart + 'T' + data.item.timeStart)}}</div>
                </template>
                <template #cell(end)="data">
                    <div v-if="data.item.dateEnd == null || data.item.timeEnd == null ">Not chosen</div>
                    <div v-else>{{getDate(data.item.dateEnd + 'T' + data.item.timeEnd)}}</div>
                </template>
                <template #cell(breaks)="data">
                    <div v-if="data.item.breaks.length == 0">Not chosen</div>
                    <div v-else>
                        <div v-for="(item,index) in data.item.breaks" :key="index">
                            {{item.timeStart}} - {{item.timeEnd}} 
                            <span v-if="breaksTypes[item.type] != null">({{breaksTypes[item.type].name}} {{breaksTypes[item.type].duration}}min {{breaksTypes[item.type].paid ? 'paid' : 'unpaid'}})</span>
                        </div>
                    </div>
                </template>
                <template #cell(total)="data">
                    <div>{{getTotalDay(data.item)}}</div>
                </template>
                <template #cell(paid)="data">
                    <div>{{getPaidUnpaidHoursDay(data.item)[0]}}</div>
                </template>
                <template #cell(unpaid)="data">
                    <div>{{getPaidUnpaidHoursDay(data.item)[1]}}</div>
                </template>
            </b-table>   

            <!-- Table history -->
            <h5 class="mt-5">History of changes</h5>
            <b-table :fields="headersHistory" :items="dataEntryUpdate.usersHistory"  class="mb-5"/>
             
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import Hours from '../../components/timeSheat/Hours.vue';
import helpers from '../../assets/const/helpersTimeSheet';
export default {
  components: { Hours },
    name: "ViewTimeSheet", 
    data() {
        return {
            headers: [
                { key: 'start', label: 'Start'},
                { key: 'end', label: 'End'},
                { key: 'breaks', label: 'Breaks'},
                { key: 'total', label: 'Total'},
                { key: 'paid', label: 'Paid'},
                { key: 'unpaid', label: 'Unpaid'},
            ],
            headersHistory: [
                { key: 'action', label: 'Action'},
                { key: 'user_name', label: 'User'},
                { key: 'date', label: 'Date'},
            ],
        };
    },
    mounted(){
        this.$store.dispatch('timeSheet/getBreaksTypes').then(() =>{
            this.$store.dispatch('timeSheet/getDataEntryUpdate', this.$route.params.id);
        });
    },
    computed: {
        days(){
            return this.$store.state.timeSheet.days;
        },
        dataEntryUpdate(){
            return this.$store.state.timeSheet.dataEntryUpdate;
        },
        breaksTypes(){
            return this.$store.state.timeSheet.breaksTypes;
        },
        loadingBreaksTypes(){
            return this.$store.state.timeSheet.loadingBreaksTypes;
        },
        dataEntryUpdateLoading(){
            return this.$store.state.timeSheet.dataEntryUpdateLoading;
        },
        totalHours(){
            return this.$store.getters['timeSheet/totalHours'];
        },
        paidUnpaidHours(){
            return this.$store.getters['timeSheet/paidUnpaidHours'];
        },
    },
    methods: {
        getDate(value){
            return moment(value).format('llll');
        },
        getTotalDay(item){
            let total = '0:00'
            if(item.dateStart != null && item.dateEnd != null && item.timeStart != null && item.timeEnd != null){
                let diff = helpers.getDiffMinutes(item.dateStart+'T'+item.timeStart, item.dateEnd+'T'+item.timeEnd);
                total = helpers.getTimeString(diff); 
            }
            return total;
        },
        getPaidUnpaidHoursDay(item){
            let res = [this.getTotalDay(item),'0:00'];
            for(let j = 0; j < item.breaks.length; j++){
                if(item.breaks[j].type != null && item.breaks[j].timeStart !=null && item.breaks[j].timeEnd){
                        let diff = helpers.getDiffMinutes(item.dateStart+'T'+item.breaks[j].timeStart,item.dateEnd+'T'+item.breaks[j].timeEnd);
                        let unpaidHours = res[1].split(':');
                        let unpaidHoursMin = parseInt(unpaidHours[0]) * 60 + parseInt(unpaidHours[1]);

                        let paidHours = res[0].split(':');
                        let paidHoursMin = parseInt(paidHours[0]) * 60 + parseInt(paidHours[1] == undefined ? 0 : paidHours[1]);
                        if(this.breaksTypes[item.breaks[j].type].paid){
                            if(this.breaksTypes[item.breaks[j].type].duration < diff)
                            {
                                diff -= this.breaksTypes[item.breaks[j].type].duration;
                                res[1] = helpers.getTimeString(unpaidHoursMin + diff);
                                res[0] = helpers.getTimeString(paidHoursMin - diff); 
                            }
                        }
                        else{
                            res[1] = helpers.getTimeString(unpaidHoursMin + diff);
                            res[0] = helpers.getTimeString(paidHoursMin - diff);
                        }
                    }
                if((parseFloat(res[0]) < 0 || parseFloat(res[0]) < 0 ) && item.breaks.length > 0)
                    res[0] = res[1] = '0.00';
            }
            return res;
        },
        openDocument(value){
            window.open(value);
        },
        redirect(name){            
            this.$router.push({ name: name });
        }
        
    },
};
</script>