<template>
  <div class="tab">
    <div class="head-title">Employment History</div>
    <div class="id-user">{{userId}}</div>
        <div v-if="loading">
            <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
            </div>
        </div>
        <div v-else>
          <employment
            v-for="(item, index) in employmentHistory"
            v-bind:key="index"
            :employment="item"/>
        </div>
  </div>
</template>

<script>
import Employment from './components/Employment.vue';
export default {
  components: { Employment },
    name: "EmploymentHistory",
    props:{
        userId:Number
    },
    watch: {
        userId(id) {
            if(id != undefined)
                this.getEmploymentHistory();
        }
    },
    data() {
        return {
        };
    },
    computed: {
        employmentHistory() {
          return this.$store.state.workers.employmentHistory;
        },
        loading(){
          return this.$store.state.applicants.loadingEmploymentHistory;
        }
    },
    methods: {
      getEmploymentHistory() {
        this.$store.dispatch('workers/employmentHistory',this.userId);
      }
    },
};
</script>
<style  scoped>

</style>