<template>
    <div>
        <b-row  class="justify-content-md-center position">
            <b-col>Position ({{index}})</b-col> 
            <b-col v-bind:class="[isShow? '':'rotate-img','col-auto']" v-on:click="isShow = !isShow"><img src="@/assets/icons/down.svg" class="mr-2 mt-n1"/></b-col>
            <b-col class="col-auto delete" v-on:click="deletePosition" v-if="length>1"><img src="@/assets/icons/delete.svg" class="mr-2 mt-n1"/></b-col>
        </b-row>
        <div v-if="isShow">
            <div class="title">General Position Info</div>
            <ValidationProvider
                name="Title of position"
                :rules="{ required: true }" 
                v-slot="{ failed, errors }">
                    <label class="label">Title of position<span class="star">*</span></label>
                    <b-form-input
                        type="text"
                        placeholder="Enter title of position"
                        v-model="position.title"
                        required
                        :class="[{ 'has-danger': failed }]"
                    ></b-form-input>             
                    <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>
            <ValidationProvider
                name="People needed"
                :rules="{ required: true }" 
                v-slot="{ failed, errors }">
                    <label class="label">People needed<span class="star">*</span></label>
                    <b-form-input
                        type="number"
                        placeholder="Enter title of position"
                        v-model="position.people_needed"
                        required
                        :class="[{ 'has-danger': failed }]"
                    ></b-form-input>      
                    <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>
            <ValidationProvider
                name="Skills"
                :rules="{ required: true }" 
                v-slot="{ failed, errors }">
                    <label class="label">Skills<span class="star">*</span></label>
                    <el-select v-model="position.skills" 
                        multiple 
                        placeholder="Select skills" 
                        :class="[{ 'has-danger': failed }]">
                            <el-option
                            v-for="(item, index) in params.skills"
                            v-bind:key="index"
                            :label="item"
                            :value="+index">
                            </el-option>
                    </el-select>       
                    <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>
            <ValidationProvider
                name="Job Description"
                :rules="{ required: true }" 
                v-slot="{ failed, errors }">
                    <label class="label">Job Description<span class="star">*</span></label>
                    <b-form-textarea
                        v-model="position.description"
                        placeholder="Write job description"
                        rows="5"
                        :class="[{ 'has-danger': failed }]"
                    ></b-form-textarea>
                    <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>

            <div class="title">Additional Information</div>
            <ValidationProvider
                name="Dept code"
                :rules="{ required: true }" 
                v-slot="{ failed, errors }">
                    <label class="label">Dept code<span class="star">*</span></label>
                    <b-form-input
                        type="text"
                        placeholder="Enter Dept code"
                        v-model="position.dept_code"
                        required
                        :class="[{ 'has-danger': failed }]"
                    ></b-form-input>             
                    <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>
            <ValidationProvider
                name="Workers Comp code"
                :rules="{ required: true }" 
                v-slot="{ failed, errors }">
                    <label class="label">Workers Comp code<span class="star">*</span></label>
                    <b-form-input
                        type="text"
                        placeholder="Enter workers comp code"
                        v-model="position.comp_code"
                        required
                        :class="[{ 'has-danger': failed }]"
                    ></b-form-input>             
                    <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>

            <div class="title">Pay Rate</div>
            <ValidationProvider
                name="Regular hourly rate"
                rules="required"
                v-slot="{ failed, errors }">
                    <label class="label">Payroll regular hourly rate<span class="star">*</span></label>
                    <b-form-input
                        type="number"
                        placeholder="Enter regular hourly rate"
                        v-model="position.regular_pay_rate"
                        required
                        :class="[{ 'has-danger': failed }]"
                    ></b-form-input> 
                    <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>
            <ValidationProvider
                name="Payroll overtime hourly rate"
                rules="required"
                v-slot="{ failed, errors }"
                >
                    <label class="label">Payroll overtime hourly rate<span class="star">*</span></label>
                    <b-form-input
                        type="number"
                        placeholder="Enter payroll overtime hourly rate"
                        v-model="position.overtime_pay_rate"
                        required
                        :class="[{ 'has-danger': failed }]"
                    ></b-form-input> 
                    <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>
            <ValidationProvider
                name="Payroll doubletime hourly rate"
                rules="required"
                v-slot="{ failed, errors }"
                >
                    <label class="label">Payroll doubletime hourly rate<span class="star">*</span></label>
                    <b-form-input
                        type="number"
                        placeholder="Enter payroll doubletime hourly rate"
                        v-model="position.doubletime_pay_rate"
                        required
                        :class="[{ 'has-danger': failed }]"
                    ></b-form-input> 
                    <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>
            <ValidationProvider
                name="Payroll hourly calculator"
                :rules="{ required: true }" 
                v-slot="{ failed, errors }"
                >
                    <label class="label">Payroll hourly calculator<span class="star">*</span></label>
                    <b-select 
                        v-model="position.payroll_hourly_calculator" 
                        :class="[{ 'has-danger': failed }]">
                            <template #first>
                                <b-form-select-option class="placeholder" :value="null">Select payroll hourly calculator</b-form-select-option>
                            </template>
                            <option
                                v-for="(option, index) in params.calculators"
                                :key="index"
                                :value="index" >
                                    {{ option }}
                            </option>
                        </b-select>
                        <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>


            <div class="title">Bill Rate</div>
            <ValidationProvider
                name="Billing regular hourly rate"
                rules="required"
                v-slot="{ failed, errors }"
                >
                    <label class="label">Billing regular hourly rate<span class="star">*</span></label>
                    <b-form-input
                        type="number"
                        placeholder="Enter billing regular hourly rate"
                        v-model="position.regular_bill_rate"
                        required
                        :class="[{ 'has-danger': failed }]"
                    ></b-form-input> 
                    <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>
            <ValidationProvider
                name="Billing overtime hourly rate"
                rules="required"
                v-slot="{ failed, errors }"
                >
                    <label class="label">Billing overtime hourly rate<span class="star">*</span></label>
                    <b-form-input
                        type="number"
                        placeholder="Enter billing overtime hourly rate"
                        v-model="position.overtime_bill_rate"
                        required
                        :class="[{ 'has-danger': failed }]"
                    ></b-form-input> 
                    <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>
            <ValidationProvider
                name="Billing doubletime hourly rate"
                rules="required"
                v-slot="{ failed, errors }"
                >
                    <label class="label">Billing doubletime hourly rate<span class="star">*</span></label>
                    <b-form-input
                        type="number"
                        placeholder="Enter billing doubletime hourly rate"
                        v-model="position.doubletime_bill_rate"
                        required
                        :class="[{ 'has-danger': failed }]"
                    ></b-form-input> 
                    <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>
            <ValidationProvider
                name="Invoice hourly calculator"
                :rules="{ required: true }" 
                v-slot="{ failed, errors }"
                >
                    <label class="label">Invoice hourly calculator<span class="star">*</span></label>
                    <b-select 
                        v-model="position.invoice_hourly_calculator" 
                        :class="[{ 'has-danger': failed }]">
                            <template #first>
                                <b-form-select-option class="placeholder" :value="null">Select invoice hourly calculator</b-form-select-option>
                            </template>
                            <option
                                v-for="(option, index) in  params.calculators"
                                :key="index"
                                :value="index" >
                                    {{ option}}
                            </option>
                    </b-select>
                   <div class="has-danger-error">{{ errors[0]}}</div>
            </ValidationProvider>
        </div>           
    </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required} from 'vee-validate/dist/rules';
extend('required', required);
export default {
    name: "Position",
    props:{
        index:Number
    },
    data() {
        return {
            isShow:true,
        };
    },
    computed: {
        position(){
            return this.$store.state.workOrders.positions[this.index-1];
        },
        params(){
            return this.$store.state.workOrders.params;
        },
        length(){
            return this.$store.state.workOrders.positions.length
        }
    },
    methods: {
        deletePosition(){
            if(this.$store.state.workOrders.positions.length>1){
                this.$store.state.workOrders.positions.splice(this.index -1, 1);
                this.$emit("delete")
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.rotate-img{
    transform: rotate(180deg);
}
.delete{
    margin-right: -58px;

}
</style>