<template>
  <div class="table-list">
    <!-- head -->
    <div class="table-head">
      <b-row class="justify-content-md-center">
        <b-col class="col-auto pr-2 pt-2" v-if="type == 'applicants'">
          <div v-on:click="isShowFilterBy = !isShowFilterBy" class="filter-by-btn">Filter by<div v-bind:class="!isShowFilterBy ? 'triangle-down' : 'triangle-up'"></div></div>
          <filter-by
            v-if="isShowFilterBy"
            :type="type"
            @updateFilters="updateFilters"/>
        </b-col>
        <b-col v-if="type != 'workers-work-history' && type != 'timeSheet'" class="col-auto pl-2 pr-3 pt-2">
          <b-input-group>
            <b-input-group-prepend is-text class="icon-search">
              <b-icon icon="search" ></b-icon>
            </b-input-group-prepend>
            <b-form-input v-model="name" placeholder="Search" class="search" v-on:change="getItem(true)"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col v-if="type == 'timeSheet'" class="col-auto pt-2">
          <el-date-picker
            v-model="filters.date"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            @change="updateFilters(filters)">
          </el-date-picker>
        </b-col>
        <b-col v-if="type == 'timeSheet'" class="col-auto pt-2">
          <el-select v-model="filters.verified" placeholder="Select verified filter"  @change="updateFilters(filters)">
            <el-option
              v-for="(item, index) in verifiedFilters"
              v-bind:key="index"
              :label="item"
              :value="+index">
            </el-option>
          </el-select>
        </b-col>
        <b-col class="col-auto px-2 pt-2"  v-if="type == 'applicants'">
          <el-select class="stages" v-model="filterStage" collapse-tags multiple placeholder="Select stage" @change="getItem(true)">
            <el-option
              v-for="(item, index) in stages"
              v-bind:key="index"
              :label="item"
              :value="+index">
            </el-option>
          </el-select>
        </b-col>
        <b-col class="col-auto min-w-160 px-2 pt-2" v-if="type == 'applicants' || type == 'workers'">
          <b-button variant="primary" v-on:click="addToWorkOrderClick()">Add to Work Order</b-button>
        </b-col>
        <!-- <b-col class="mr-3 px-2 pt-2 col-auto">
          <b-row class="view-menu">
            <b-col class="view-menu-tab" v-on:click="changeView()"><img src="@/assets/icons/menu-list.svg" class="mr-1"/></b-col>
            <b-col class="view-menu-tab-selected"><img src="@/assets/icons/menu-table.svg" class="mr-1"/></b-col>
          </b-row>
        </b-col> -->
        <b-col v-if="type != 'workers-work-history'"></b-col>
        <b-col v-if="type != 'workers-work-history'" class="col-auto px-2 pt-2" v-click-outside="hideSettings">
          <div class="setting" v-on:click="isShowSettings = !isShowSettings">
            <b-icon icon="gear" scale="1"></b-icon>
          </div>
          <div class="settings-header" v-if="isShowSettings">
            <span class="triangle"></span>
            <div v-for="(item, i) in settingsList" v-bind:key="i">
              <b-checkbox v-model="item.value" @click.native ="settingsClick(item)">{{ item.label }}</b-checkbox>
            </div>
          </div>
        </b-col>
        <b-col v-if="type == 'applicants'" class="col-auto pl-2 pt-2" >
          <b-row class="mt-1">
            <b-col class="pr-1">
              <span class="icon_arr">
                <b-icon icon="chevron-left" scale="1"></b-icon>
              </span>
            </b-col>
            <b-col class="pl-1" v-on:click="moveRight()">
              <span class="icon_arr">
                <b-icon icon="chevron-right" scale="1"></b-icon>
              </span>
            </b-col>
          </b-row>
        </b-col>
        <!-- Workers work history -->
        <b-col v-if="type == 'workers-work-history'" class="col-auto pr-1">
          <b-select 
            v-model="filtersWorkHistory.month" 
            :options="months"
            class="work-history-filters">
            <template #first>
              <b-form-select-option class="placeholder" :value="null">Select month</b-form-select-option>
            </template>
          </b-select>
        </b-col>

        <b-col v-if="type == 'workers-work-history'" class="col-auto px-1">
          <b-select 
            v-model="filtersWorkHistory.year" 
            :options="years"
            class="work-history-filters">
            <template #first>
              <b-form-select-option class="placeholder" :value="null">Select year</b-form-select-option>
            </template>
          </b-select>
        </b-col>

        <b-col v-if="type == 'workers-work-history'" class="pl-1">
          <b-button variant="primary" v-on:click="getItem(true)">Search</b-button>
        </b-col>
      </b-row>
    </div>
    <!--body -->
    <div v-if="loading" class="loading">
      <div class="text-center text-secondary my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Loading...</strong>
      </div>
    </div>
    <div v-else-if="items.length > 0" class="table-body">
      <b-table
        id="tableAndFilters"
        ref="tableAndFilters"
        :busy="loading" 
        :items="items"
        :fields="headers"
        :per-page="countInPage.value"
        :current-page="filters.page"
        hover
        :sort-by.sync="sorting.name"
        :sort-desc.sync="sorting.ASC"
        @sort-changed="handleSortChange"
      >
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
        <template v-slot:head(actions)>
          <div class="ml-4 pl-1">
            <b-checkbox @change="changeAllSelectedElement"></b-checkbox>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="actions" v-if="isAdmin || type == 'timeSheet'">
            <b-icon icon="three-dots-vertical" class="mt-1 mr-2 cursor-pointer" scale="1" v-on:click="elementMenuClick('element-menu' + (type == 'timeSheet' ? data.item.data_entries_id : data.item.id))"/>
            <b-toast :id="'element-menu'+ (type == 'timeSheet' ? data.item.data_entries_id : data.item.id)" static v-click-outside="closeToast" :autoHideDelay="50000">
              <div v-if="type == 'timeSheet'" class="element-menu" v-on:click="goToView(data.item)">View</div>
              <div class="element-menu" v-on:click="showEditModal(data.item)">Edit</div>
              <div v-if="type == 'workOrders'" class="element-menu" v-on:click="showCreateDuplicate(data.item)">Duplicate</div>
              <div v-else-if="type != 'timeSheet'" class="element-menu" v-on:click="addToWorkOrderOneUser(data.item.id)">Add to Work Order</div>
              <div v-if="false" class="element-menu">Generate PDF</div>
              <div v-if="false" class="element-menu">Delete</div>
            </b-toast>
            <b-checkbox v-model="selectedItems[data.index]"></b-checkbox>
          </div>
        </template> 
        <template #cell(name)="data">
          <div v-on:click="redirectToDetail(data.item.id)" class="name">
            <div v-if="type == 'applicants'">{{data.item.first_name}} {{data.item.last_name}}</div>
            <div v-else-if="type == 'workOrders'">{{data.item.title}}</div>
            <div v-else>{{data.item.name}}</div>
          </div>
        </template>
        <template #cell(positionApplied)="data">
          <div class="position-applied">{{data.item.position}}</div>
        </template>
        <template #cell(applied)="data">
          <div class="applied">{{getApplied(data.item.apply_date)}}</div>
        </template>
         <template #cell(contacts)="data">
          <div v-if="type == 'workers'" class="contacts">
            <div>{{data.item.contacts.email}}</div>
            <div class="pt-2">{{data.item.contacts.phone}}</div>
          </div>
          <div v-else class="contacts">
            <div>{{data.item.email}}</div>
            <div class="pt-2">{{data.item.phone}}</div>
            <div v-if="data.item.phone!=null" class="pt-2">{{data.item.phone2}}</div>
          </div>
        </template>
        <template #cell(resumeUpdateOn)="data">
          <div class="min-w-160">
            <div v-if="data.item.resume == null" class="resumeNotFound">Resume not found</div>
            <div v-else class="resumeUpdateOn" v-on:click="openResume(data.item.resume.url)">
              <div class="name mb-1"><img src="@/assets/icons/paperclip.svg" class="mr-1"/>{{data.item.resume.title}}</div>
              <div class="updated-at">{{getResumeDate(data.item.updated_at)}}</div>
            </div>
          </div>
        </template>
        <template #cell(skills)="data">
          <div class="skills" v-on:click="toastedClick(data.item.id, 'skills'+data.item.id, 'getUserSkills')">List of Skills</div>
          <b-toast   v-if="openToastId == 'skills'+data.item.id" :id="'skills'+data.item.id"  :title="'Skills ' + (data.item.name == undefined ? '' : data.item.name)" static  v-click-outside="closeToast">
            <div class="text-center" v-if="loadingStagesHistory">
              <b-spinner variant="primary"></b-spinner>
            </div>
             <div v-else>
              <div v-for='(item,index) in userSkills' v-bind:key="index">{{item.name}}</div>
              <div v-if="userSkills.length == 0">Skills are missing</div>
            </div>
          </b-toast>
        </template>
        <template #cell(stage)="data">
          <div v-if="data.item.stage == null" class="not-chosen">Not chosen</div>
          <div v-else class="stage" v-on:click="toastedClick(data.item.id, 'stage'+data.item.id, 'getUserStagesHistory')">
            <span class="stage-body">{{stages[data.item.stage]}}</span><span class='triangle-down'></span>
          </div>
          <b-toast :id="'stage'+data.item.id" :title="'History stages ' + (data.item.name == undefined ? '' : data.item.name)" static  v-click-outside="closeToast">
            <div class="text-center" v-if="loadingSkills">
              <b-spinner variant="primary"></b-spinner>
            </div>           
            <div v-else>
              <div v-for='(item,index) in stagesHistory' v-bind:key="index">{{item.name}}</div>
              <div v-if="stagesHistory.length == 0">History are missing</div>
            </div>
          </b-toast>
        </template>
        <template #cell(address)="data">
          <div v-if="data.item.address == null || (data.item.address.address == null  && data.item.address.zipcode == null 
            && data.item.address.city == null && data.item.address.state == null)" class="not-chosen">Not chosen</div>
          <div v-else class="px-3">
            <b-row class="min-w-160 justify-content-md-center">
              <b-col v-if="data.item.address.state !=null" class="col-auto pl-0 pr-1">{{data.item.address.state.state_short}}</b-col>
              <b-col v-if="data.item.address.city !=null" class="col-auto px-1">{{data.item.address.city.name}}</b-col>
              <b-col v-if="data.item.address.zipcode !=null" class="px-1">{{data.item.address.zipcode.zipcode}}</b-col>
            </b-row>
            <b-row v-if="data.item.address.address !=null" class="mt-2">{{data.item.address.address}}</b-row>
          </div>
        </template>
        <template #cell(recruiter)="data">
          <div v-if="data.item.recruiter == null || data.item.recruiter.length ==0" class="not-chosen">Not chosen</div>
          <div v-else class="recruiter">{{data.item.recruiter}}</div>
        </template>
        <template #cell(firstReview)="data">
          <div v-if="data.item.firstReview == null" class="not-chosen">Not chosen</div>
          <div v-else class="firstReview">{{data.item.first_reviewer}}</div>
        </template>
        <template #cell(mainReview)="data">
          <div v-if="data.item.mainReview == null" class="not-chosen">Not chosen</div>
          <div v-else class="mainReview">{{data.item.main_interview}}</div>
        </template>
        <template #cell(experience)="data">
          <div v-if="data.item.experience == null" class="not-chosen">Not chosen</div>
          <div v-else class="mainReview">{{data.item.experience.title}}</div>
        </template>
        <template #cell(industry)="data">
          <div v-if="data.item.industry == null || data.item.industry.length ==0" class="not-chosen">Not chosen</div>
          <div v-else class="mainReview">{{data.item.industry}}</div>
        </template>
        <template #cell(car)="data">
          <div v-if="data.item.car == null">No</div>
          <div v-else class="car">Yes</div>
        </template> 
        <!-- work orders -->
        <template #cell(airtable_project_id)="data">
          <div v-if="data.item.airtable_project_id == null">Not found</div>
          <div v-else>{{data.item.airtable_project_id}}</div>
        </template>
        <template #cell(workers)="data">
          <div>R - {{data.item.workers.required}}, C - {{data.item.workers.confirmed}}, W - {{data.item.workers.working}}</div>
        </template>
        <template #cell(status)="data">
          <div v-if="type == 'workers'">
            <span v-if="data.item.status" class="active">Active</span>
            <span v-else class="inactive">Inactive</span>
          </div>
          <span 
            v-else
            v-bind:class="[data.item.status == 'planning'  ? 'planning' 
              : data.item.status == 'confirmed' || data.item.status == 'complete' ? 'confirmed' 
              : data.item.status == 'ongoing' ? 'ongoing' 
              : data.item.status == 'cancelled' ? 'cancelled' :'','status']">
            {{getStatusName(data.item.status)}}
          </span>
        </template>
        <template #cell(start_date)="data">
          <div>{{getDate(data.item.start_date)}}</div>
        </template>
        <template #cell(end_date)="data">          
          <div>{{getDate(data.item.end_date)}}</div>
        </template>
        <!-- workers -->
        <template #cell(ssn)="data">          
          <div v-if="data.item.ssn != null" class="ssn">&bull;&bull;&bull;&bull;&bull; {{getSSN(data.item.ssn)}}</div>
          <div v-else>Not installed</div>
        </template>
        <template #cell(all_stats)="data">          
          <div v-if="data.item.stats.days > 0 || data.item.stats.hours != null" class="all-stats">
            <b-row class="mb-2"><b-col class="p-0">Total days:</b-col><b-col class="value">{{data.item.stats.days}} d</b-col></b-row>
            <b-row><b-col class="p-0">Total hours:</b-col><b-col class="value">{{data.item.stats.hours}} Hrs</b-col></b-row>
          </div>
          <div v-else>Not installed</div>
        </template>
        <template #cell(category)="data">          
          <div v-if="data.item.category != null" class="category">{{data.item.category}}</div>
          <div v-else>Not installed</div>
        </template>
        <template #cell(speciality)="data">          
          <div v-if="data.item.speciality != null" class="speciality">{{data.item.speciality}}</div>
          <div v-else>Not installed</div>
        </template>
        <!-- workers work history -->

        <template #cell(date)="data">          
          <div v-if="data.item.date != null" class="date">{{data.item.date}}</div>
          <div v-else>Not installed</div>
        </template>
        <template #cell(project)="data">          
          <div v-if="data.item.project != null" class="project">{{data.item.project}}</div>
          <div v-else>Not installed</div>
        </template>

        <template #cell(position)="data">          
          <div v-if="data.item.position != null" class="position">{{data.item.position}}</div>
          <div v-else>Not installed</div>
        </template>

        <template #cell(wokedHours)="data">          
          <div v-if="data.item.worked_hours != null" class="worked_hours">{{getWokedHours(data.item.worked_hours)}}</div>
          <div v-else>Not installed</div>
        </template>
          <!-- time sheet -->
        <template #cell(dates)="data"> 
          <div v-for="(item, index) in data.item.dates" :key="index" class="my-1">{{item.date}}</div>        
        </template>
        <template #cell(company)="data"> 
          <div v-if="data.item.company == null">Not installed</div>
          <div v-else>{{ data.item.company}}</div>
        </template>
        <template #cell(user)="data"> 
          <div>{{ data.item.user_name}}</div>
          <div>{{ data.item.user_email}}</div>
          <div v-if="data.item.user_name == null && data.item.user_email == null">Not installed</div>
        </template>
        <template #cell(verified)="data"> 
          <span v-if="data.item.verified == 1" class="confirmed status">Verified</span>
          <span v-else  class="cancelled status">Pending verification</span>
        </template>
        <template #cell(notes)="data"> 
          <div v-if="data.item.notes == null">Not installed</div>
          <div v-else class="notes">{{data.item.notes }}</div>
        </template>
      </b-table>      
    </div>
    <div v-else class="items-not-found">Items not found</div>
    <!-- selected -->
    <div class="selected" v-if="countSelectedElement > 0">
      <b-row class="justify-content-md-center">
        <b-col class="col-auto">{{countSelectedElement}} selected</b-col>
        <b-col class="col-auto" v-if="false">Edit</b-col>
        <b-col class="col-auto" v-if="false">Generate PDF</b-col>
        <b-col class="col-auto" v-if="false">Delete</b-col>
        <b-col class="text-right">{{meta.total}} applicants</b-col>
      </b-row>
    </div>
    <!-- pagination -->
    <div class="pagination-in-table">
      <b-row 
        v-if="items.length > 0 && !loading">
          <b-col md="3">
            <div class="pt-1">Page {{getAllFilters.page}} of {{meta.last_page}}</div>
          </b-col>
            <b-col md="6">
              <b-pagination
                v-if="meta.total > countInPage.value"
                v-model="pageInPagination"
                :total-rows="meta.total"
                :per-page="countInPage.value"
                aria-controls="tableAndFilters"
                first-number
                last-number
              >
              </b-pagination>
            </b-col>
              <b-col md="3" class="text-right padding2 body-size show_page">
                Show
                <b-form-select
                  class="select"
                  v-model="countInPage.value"
                  :options="countInPageList"
                  @change="countInPageChenge"
                ></b-form-select
                >on page
              </b-col>
        </b-row>
    </div>
    <add-and-edit-user
      v-if="type == 'applicants'"
      :filters="getAllFilters"
      :isShow="isShowEditElement"
      type="edit"
      :editUser="editElement"
      @closeModal="isShowEditElement = false"/>
    <assign-workers
      :isShow="isShowAssign"
      :users="selectedIdItems"/>
    <info-modal
      :isShow="isShowApplicantsNotSelected"
      title="Applicants not selected"
      msg="Please select at least one applicant"
    />
    <ok-cancel-modal
      :isShow="isShowCreateDuplicate"
      title="Create duplicate"
      msg="Do you want to duplicate this work order ?"
      @ok="createDuplicate()"
      @closeModal="isShowCreateDuplicate = false"
    />
    <edit-work-order
      :isShow="isShowEditWorkOrder"
      :id="editElement.id"
      @closeModal="isShowEditWorkOrder = false"
    />
  </div>
</template>
<script>
import { applicantHeaders, workOrdersHeaders, workersHeaders, workersWorkHistoryHeaders,timeSheetHeaders } from '../../assets/const/headers';
import { months } from '../../assets/const/months';
import { years } from '../../assets/const/years';
import roles from '../../assets/const/roles';
import moment from 'moment';
import FilterBy from './FilterBy.vue';
import AddAndEditUser from '../applicants/AddAndEditUser.vue';
import AssignWorkers from '../applicants/AssignWorkers.vue';
import InfoModal from './InfoModal.vue';
import OkCancelModal from './OkCancelModal.vue';
import EditWorkOrder from '../workOrders/Edit.vue';
import checkRole from "../../assets/const/checkRole";
export default {
  components: { 
    FilterBy, 
    AddAndEditUser,
    AssignWorkers,
    InfoModal,
    OkCancelModal,
    EditWorkOrder 
    },
  props:{
    type:String,
  },
  data() {
    return {
      //headers
      applicantHeaders:applicantHeaders,
      workOrdersHeaders:workOrdersHeaders,
      workersHeaders:workersHeaders,
      workersWorkHistoryHeaders:workersWorkHistoryHeaders,
      timeSheetHeaders:timeSheetHeaders,
      //lists
      months:months,
      years:years,
      countInPageList: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
        { value: 30, text: "50" },
      ],
      settingsList:[],
      //filters
      filters:{},
      paginate:10,
      name:'',
      filterStage:[],        
      sorting:{
        name:null,
        ASC: null
      },
      filtersWorkHistory:{
        month:null,
        year:null
      },
      isShowSettings:false,
      isShowFilterBy:false,
      //paginate
      page:1,
      count:100,
      countInPage:{ value: 10, text: "10" },
      //modals
      isShowCreateDuplicate: false,
      isShowEditWorkOrder: false,
      isShowEditElement:false,
      isShowAssign:false,
      isShowApplicantsNotSelected:false,
      //other
      openToastId:-1,
      editElement:{},
      selectedItems:[],
      selectedIdItems:[],
      verifiedFilters:['Pending verification','Verified']
    };
  },
  mounted(){
    // localStorage.clear();
    this.getItem(true);    
    if(this.type != 'workers-work-history'){
      this.settingsList = this.type == 'applicants' ? this.applicantHeaders : 
        this.type == 'workOrders' ? this.workOrdersHeaders : 
        this.type == 'timeSheet' ? this.timeSheetHeaders : this.workersHeaders;
      this.$store.dispatch(this.type + '/initHeaders').then(()=>{
        this.$store.state[this.type].headers.forEach(element => {
          if(element.key != 'actions')
          this.settingsList.find(e => e.key == element.key).value = true;
        });
      })
    }
  },
  computed: {
    headers() {
      let headers = this.type == 'workers-work-history'?  this.workersWorkHistoryHeaders : this.$store.state[this.type].headers;
      return this.isAdmin ? headers : headers.filter(item => item.canAdmin != true);
    },
    stages() {
      return this.$store.state[this.type].stages;
    },
    items(){
      return this.type == 'workers-work-history'?  this.$store.state.workers.workHistory : this.$store.state[this.type].items;
    },
    loading(){
      return this.type == 'workers-work-history' ? this.$store.state.workers.loadingWorkHistory : this.$store.state[this.type].loading;
    },
    currentUser(){
      return JSON.parse(localStorage.getItem('currentUser'));
    }, 
    isAdmin(){
      return checkRole.isAdmin(this.currentUser);
    },
    loadingSkills() {
      return this.$store.state[this.type].loadingSkills;
    },
    userSkills() {
      return this.$store.state[this.type].userSkills;
    },
    loadingStagesHistory() {
      return this.$store.state[this.type].loadingStagesHistory;
    },
    stagesHistory() {
      return this.$store.state[this.type].stagesHistory;
    },
    rows(){
      return this.items.length;
    },
    meta(){
      return this.type == 'workers-work-history' ? this.$store.state.workers.metaWorkHistory : this.$store.state[this.type].meta;
    },
    pageInPagination:{
      get(){
        return this.page;
      },
      set(value){
        this.page = value;
        this.getItem(false);
      }
    },
    countSelectedElement(){
      let count = 0;
      for(let i = 0; i< this.selectedItems.length; i++){
        if(this.selectedItems[i] == true)
        count++;
      }
      return count;
    },
    getAllFilters(){
      return {
        filters: this.type == 'workers-work-history'? this.filtersWorkHistory : this.filters,
        page: this.page,
        paginate: this.paginate,
        sorting:this.sorting,
        name:this.name,
      }
    },
  },
  methods:{
    //element initialization 
    getItem(resetList){
      if(resetList){
        this.page = 1;
      }
      if(this.type == 'timeSheet' && !this.isAdmin){
          this.$store.dispatch('timeSheet/getWorkOrdersListForWorker',  this.currentUser.id).then(()=>{
            let workOrders = this.$store.state.timeSheet.workOrders;
            this.filters.workers = []
            if (workOrders.length > 0) {
              for (let i = 0; i < workOrders.length; i++) {
                this.filters.workers.push(workOrders[i].worker_id);
              }
              let date = this.getAllFilters;
              this.$store.dispatch(this.type + '/getList', date).then(() => {
                this.initSelectedElements();
              });
            }
          });
      }
      else{
        let date = this.getAllFilters;
        if(this.type == 'applicants')
          date.stage =this.filterStage;
        if(this.type == 'workers-work-history'){
          date.id = this.$route.params.id
          this.$store.dispatch('workers/workHistory',date);
        } else if(this.type == 'workOrders'){
          date.isAdmin = this.isAdmin;
          this.$store.dispatch(this.type + '/getList', date).then(()=>{
            this.initSelectedElements();
          });
        }
        else{
          this.$store.dispatch(this.type + '/getList', date).then(()=>{
            this.initSelectedElements();
          });
        }
      }
    },
    //methods of correctly displaying data in a table
    getDate(value){
      return value == null ? 'Not found' : moment(value).format('ll');
    },
    getResumeDate(date){
      return moment(date).format("MMM Do YY");
    },
    getApplied(value){
      return moment(value).fromNow();
    },
    getWokedHours(value){
      let wokedHours = value.toString().split('.');
      let hours = wokedHours[0] + ' Hrs ' + ( wokedHours[1] == undefined ? '' : (wokedHours[1].length == 1 ? wokedHours[1] +'0': wokedHours[1]) + ' m');
      return hours;
    },
    getSSN(value){
      return value.substr(value.length - 5, value.length -1);
    },
    getStatusName(name){
      return name.charAt(0).toUpperCase() + name.slice(1)
    },


    //paginate
    countInPageChenge(){
      this.filters.paginate = this.countInPage.value;
      this.getItem(true);
    },


    //settings
    settingsClick(item){
      if(!item.value == true){
        this.$store.dispatch(this.type + '/addHeader',item);
      }
      else 
        this.$store.dispatch(this.type + '/removeHeader',item)
    },
    hideSettings(){
      this.isShowSettings = false;
    },

    //duplicate
    showCreateDuplicate(element){
      this.editElement = element;
      this.isShowCreateDuplicate = true;
      this.closeToast();
    },
    createDuplicate(){
      this.page = 1;
      this.isShowCreateDuplicate = false;
      this.$store.dispatch(this.type + '/createDuplicate',this.editElement.id);
    },

    //add to work order
    addToWorkOrderClick(){
      if(this.countSelectedElement > 0){
        for(let i =0; i < this.items.length; i++){
          if(this.selectedItems[i])
            this.selectedIdItems.push(this.items[i].id);
        }
        this.isShowAssign = true;
      }
      else 
        this.isShowApplicantsNotSelected = true;
    },
    addToWorkOrderOneUser(id){
      this.selectedItems = [];
      this.selectedIdItems.push(id);
      this.isShowAssign = true;
    },


    //actions on table elements
    openResume(value){
      window.open(value);
    },
    toastedClick(id, idToast, typeToast){
      this.$bvToast.hide(this.openToastId);
      this.openToastId = idToast;
      this.$store.dispatch(this.type + '/' +typeToast,id);
      this.$bvToast.show(idToast);
    },
    closeToast(){
      this.$bvToast.hide(this.openToastId);
    },
    elementMenuClick(idToast){
      this.$bvToast.hide(this.openToastId);
      this.openToastId = idToast;
      this.$bvToast.show(idToast);
    },
    initSelectedElements(){
      this.selectedItems = [];
      for(let i = 0; i< this.items.length; i++){
        this.selectedItems.push(false);
      }
    },
    changeAllSelectedElement(value){
      this.selectedItems = [];
      for(let i = 0; i< this.items.length; i++){
        this.selectedItems.push(value);
      }
    },


    //filters
    updateFilters(filters){
      this.filters = filters;
      if(filters.date != null && filters.date != undefined){
        if(this.type == 'timeSheet')
        {
          this.filters.date_from = moment(filters.date[0]).format("YYYY-MM-DD");
          this.filters.date_to = moment(filters.date[1]).format("YYYY-MM-DD");
        }
        else{
          this.filters.date = moment(filters.date[0]).format('L') + '-' + moment(filters.date[1]).format('L');
        }
        
      }

      this.getItem(true);
    },

    //modals
    showEditModal(element) {
      this.$bvToast.hide(this.openToastId);
      if(this.type == 'timeSheet')
        this.$router.push({ name: 'DataEntryUpdate', params: { id: element.data_entries_id } });
      else if(this.type == 'workOrders')
        this.isShowEditWorkOrder = true;
      else
        this.isShowEditElement = true;
      this.editElement = element;
    },
    // sorting
    handleSortChange(context){
      this.sorting.name = context.sortBy;
      this.sorting.ASC = context.sortDesc;
      this.getItem(true);

    },

    //other
    goToView(element){
        this.$router.push({ name: 'DataEntryView', params: { id: element.data_entries_id } });
    },
    redirectToDetail(id){
      this.$router.push({ name: this.type == 'applicants' ? 'ApplicantDetail' : this.type == 'workOrders' ? 'WorkOrdersDetail' : 'WorkersDetail', params: { id: id } });
    },
    moveRight(){
      //document.getElementById('tableAndFilters').offsetLeft += 100;
    },
    changeView(){
      this.$emit('changeView',false);
    },
  }
};
</script>
<style>

</style>