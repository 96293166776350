<template>
  <div>
    <b-row class="justify-content-md-center mb-3">
      <b-col class="bread-crumbs"><span class="redirect" v-on:click="redirect('Dashboard')">Dashboard</span><span class="arrow">></span><span class="active">Workers</span></b-col>
      <b-col class="col-auto"><b-button variant="primary" v-on:click="createWorker" >Create worker</b-button></b-col>
    </b-row>
    <table-and-filters 
      v-if="isTable" 
      type="workers"
      @changeView="changeView"/>
    <list-and-filters 
      v-else 
      type="workers"
      @changeView="changeView"/>
    <add-and-edit-workers
      type="add"
      :isShow="isShowAddNewWorkers"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import TableAndFilters from '../components/common/TableAndFilters.vue';
import ListAndFilters from '../components/common/ListAndFilters.vue';
import AddAndEditWorkers from '../components/workers/AddAndEditWorkers.vue'
export default {
  name: "Aplicants",
  components: {
    TableAndFilters,
    ListAndFilters,
    AddAndEditWorkers
  },
  data() {
    return {
      isTable:true,
      isShowAddNewWorkers:false
    };
  },
  methods:{
    changeView(value){
      this.isTable = value;
    },
    closeModal(){
      this.isShowAddNewWorkers = false;
    },
    createWorker(){ 
      this.isShowAddNewWorkers = true;
    },
    redirect(name){            
      this.$router.push({ name: name });
    }
  }
};
</script>
<style  scoped>
</style>
