<template>
    <div class="work-experience">
        <div class="title">{{workExperience.title}}</div>
        <div class="ml-3">{{workExperience.company}} &#8226; {{workExperience.state.state_name}} &#8226; {{workExperience.date_of_start}} - 
            {{workExperience.present ? 'Present' : workExperience.date_of_start}}</div>
        <div class="job-description ml-3">Job Description</div>
        <div class="ml-3">{{workExperience.description}}</div>
    </div>
</template>

<script>
export default {
    name: "WorkExperience",
    props: {
        workExperience:Object
    },
    methods: {
    },
};
</script>
<style  scoped>

</style>