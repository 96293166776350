<template>
    <div class="employment">
        <div class="title">{{employment.title}}</div>
        <div class="my-2">
            <span>#{{employment.work_order.airtable_project_id}} &bull; </span>
            <span>{{employment.work_order.title}}</span>
            <span v-if="employment.work_order.first_day != null && employment.work_order.last_day != null">
                <span> &bull; </span>
                <span>{{employment.work_order.first_day}} &bull; </span>
                <span v-if="employment.work_order.last_day !=null">{{employment.work_order.last_day}}</span>
                <span v-else>To today</span>
            </span>
        </div>
        <b-row>
            <b-col>
                <b-row>
                    <b-col class="label mw-120">Pay Rate</b-col>
                    <b-col class="employment-data">{{employment.pay_rate}}</b-col>
                </b-row>
            </b-col>
            <b-col>
                <b-row>
                    <b-col class="label mw-230">Work Status</b-col>
                    <b-col class="employment-data">{{getStatusName(employment.work_status)}}</b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-row>
                    <b-col class="label mw-120">Days Worked</b-col>
                    <b-col class="employment-data"></b-col>
                </b-row>
            </b-col>
            <b-col>
                <b-row>
                    <b-col class="label mw-230 ">Worker Status & Sub Status: </b-col>
                    <b-col class="employment-data">{{employment.worker_status}}</b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// import moment from 'moment';
export default {
    name: "Employment",
    props: {
        employment:Object
    },
    methods: {
        getStatusName(name){
            return name.charAt(0).toUpperCase() + name.slice(1)
        },
    },
};
</script>
<style  scoped>
.mw-230{
    max-width: 230px;
}
.mw-120{
    max-width: 120px !important;
}
</style>