<template>
    <div>        
        <!-- Loading -->
        <div v-if="loadingWorkOrders || loadingCompanies || loadingBreaksTypes || dataEntryUpdateLoading || dataEntryUpdate == null" class="loading">
            <div class="text-center text-secondary my-2 mt-5 pt-5">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
            </div>
        </div>
        <div v-else :class="[!privatePage && currentUser == null? 'private':'','time-sheet']">
            <!-- Header -->
            <div class="mb-3 mt-2 text-center" v-if="!privatePage && currentUser == null">
                <h3>New time sheet</h3>
                <div class="login">If you have an account, please <span v-on:click="redirect('Login')" class="btn-login">Login</span></div>
            </div>
            <b-row v-else class="justify-content-md-center mb-3">
                <b-col class="bread-crumbs">
                    <span class="redirect" v-on:click="redirect('Dashboard')">Dashboard</span><span class="arrow">></span>
                    <span class="redirect" v-on:click="redirect('DataEntry')">Time sheet</span><span class="arrow">></span>
                    <span class="active">{{ !isUpdate ? 'New time sheet' : 'Update time sheet'}}</span>
                </b-col>
            </b-row>
            <!-- Total,paid,unpaid hours -->
            <hours/>

            <!-- User info -->
            <b-row class="team-member-details mt-5 mb-3">
                <b-col><h5>Team member details</h5></b-col>
                <!-- Possibility to fill for another user -->
                <b-col v-if="currentUser != null && isAdmin && !isUpdate" class="fill-out col-auto"><span class="add" @click="privatePage =! privatePage">{{ privatePage ?  'Fill out for another user' : 'Fill out for me'}}</span></b-col>
            </b-row>
            <form @submit.prevent="submit">
                <!-- Information about user -->
                <user-info :privatePage="privatePage" :isUpdate="isUpdate"/>     
                
                <!-- Time sheets -->
                <recorded-hours ref="recordedHours"/>

                <!-- Notes -->
                <h5 class="mt-5 mb-3">Notes</h5>
                <b-form-textarea v-model="dataEntryUpdate.notes" placeholder="Enter notes..." rows="5"></b-form-textarea>

                <!-- File -->
                <b-row class="mt-5 mb-3">
                    <b-col class="col-auto"><h5>File:</h5></b-col>
                    <b-col v-if="isShowFile" class="col-auto select-file" @click="openDocument(dataEntryUpdate.file.link)">{{dataEntryUpdate.file.name}}</b-col>
                    <b-col v-if="isShowFile" class="trash-icon" @click="dataEntryUpdate.file = null"><b-icon icon="trash"></b-icon></b-col>
                </b-row>
                <b-form-file v-if="isShowFileSelect" id="file-shift" v-on:input="onChange" class="file"/>

                <!-- Recaptcha -->
                <b-row class="mt-5 justify-content-md-center " v-if="!privatePage &&  currentUser == null">
                    <b-col></b-col>
                    <b-col class="col-auto">                        
                    <vue-recaptcha
                        ref="recaptcha"
                        @verify="onVerify"
                        :sitekey="sitekey"></vue-recaptcha>
                    </b-col>
                </b-row>

                <div class="text-end mt-5 mb-3">
                  <div class="flex-container">
                    <div class="left">
                      <b-button v-if="dataEntryVerified" variant="danger" class="px-4 mr-3">Verified</b-button>
                    </div>
                    <div class="right">
                      <b-button v-if="canDelete" class="px-4 mr-3" @click="deleteEntries()" :disabled="loadingSaveTimeSheet">Delete</b-button>
                      <b-button v-if="isUpdate && isAdmin" variant="success" class="px-4 mr-3" @click="validateClick()" :disabled="loadingSaveTimeSheet" formaction="Approve">Approve</b-button>
                      <b-button v-if="!dataEntryVerified" id="save-btn" variant="primary" type="submit" class="px-5" :disabled="isDisabled">
                        <b-spinner small v-if="loadingSaveTimeSheet" class="mr-1"></b-spinner>
                        Save
                      </b-button>
                    </div>
                  </div>
                </div>                        
            </form>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { VueRecaptcha } from 'vue-recaptcha';

import Hours from '../components/timeSheat/Hours.vue';
import UserInfo from '../components/timeSheat/UserInfo.vue';
import RecordedHours from '../components/timeSheat/RecordedHours.vue';
import helpers from '../assets/const/helpersTimeSheet';
import userStatuses from "../assets/const/userStatuses";
import store from '../store/index';
import checkRole from "../assets/const/checkRole";

async function init(to) {
    store.state.timeSheet.isUpdate = to.name == 'DataEntryUpdate';
    store.state.timeSheet.privatePage = localStorage.getItem('accessToken') != null;
    store.dispatch('common/getCompanyList');
    store.dispatch('common/getWorkOrdersList');

    if(store.state.timeSheet.privatePage && !store.state.timeSheet.isUpdate){
        store.state.timeSheet.isValidateRecaptcha = true;
        store.dispatch('timeSheet/getWorkOrdersListForWorker',  JSON.parse(localStorage.getItem('currentUser')).id );
    }

    store.dispatch('timeSheet/getBreaksTypes').then(() =>{
        if(store.state.timeSheet.isUpdate){
            store.dispatch('timeSheet/getDataEntryUpdate', to.params.id).then(()=>{
                if(store.state.timeSheet.dataEntryUpdate.worker_id != null){
                    store.state.timeSheet.workOrder = store.state.common.workOrders.find(e => e.id == store.state.timeSheet.dataEntryUpdate.work_order_id);
                    store.dispatch('common/getWorkersListByWorkOrder',store.state.timeSheet.workOrder.id).then( () =>{
                        store.state.timeSheet.worker = store.state.common.workers.find(e => e.id == store.state.timeSheet.dataEntryUpdate.worker_id)
                    })
                        
                }
            })
        }
    });
}
export default {
    name: "CreateOrUpdateTimeSheet", 
    components:{VueRecaptcha, Hours, UserInfo, RecordedHours},
    data() {
        return {
            sitekey:process.env.VUE_APP_RECAPTCHA,
            isValidate: false
        };
    },
    async beforeRouteEnter(to, from, next) {
        init(to);
        next();
    },
    async beforeRouteUpdate(to, from, next) {
        init(to);
        next();
    },
    computed: {
        isAdmin(){
          return checkRole.isAdmin(this.currentUser)
        },
        days(){
            return this.$store.state.timeSheet.days;
        },
        companies() {
            return this.$store.state.common.companies;
        },
        currentUser(){
            return JSON.parse(localStorage.getItem('currentUser'));
        },
        dataEntryUpdate(){
            return this.$store.state.timeSheet.dataEntryUpdate;
        },
        dataEntryVerified(){
          return this.dataEntryUpdate.verified;
        },
        canDelete(){
          return this.isUpdate && (!this.dataEntryVerified || this.isAdmin);
        },
        loadingCompanies() {
            return this.$store.state.common.loadingCompanies;
        },
        loadingWorkOrders(){
            return this.$store.state.timeSheet.loadingWorkOrders;
        },
        breaksTypes(){
            return this.$store.state.timeSheet.breaksTypes;
        },
        loadingBreaksTypes(){
            return this.$store.state.timeSheet.loadingBreaksTypes;
        },
        loadingSaveTimeSheet(){
            return this.$store.state.timeSheet.loadingSaveTimeSheet;
        },
        dataEntryUpdateLoading(){
            return this.$store.state.timeSheet.dataEntryUpdateLoading;
        },
        totalHours(){
            return this.$store.getters['timeSheet/totalHours'];
        },
        paidUnpaidHours(){
            return this.$store.getters['timeSheet/paidUnpaidHours'];
        },
        worker: {
            get() {
                return this.$store.state.timeSheet.worker;
            },
            set(value) {
                this.$store.state.timeSheet.worker = value;
            }
        },
        // update
        workOrders(){
            return this.$store.state.common.workOrders;
        },
        workers(){
            return this.$store.state.common.workers;
        },
        //
        isShowFile(){
            return this.dataEntryUpdate.file != null && this.dataEntryUpdate.file.link != undefined;
        },
        isShowFileSelect(){
            return this.dataEntryUpdate.file == null || this.dataEntryUpdate.file.link == undefined;
        },
        //page
        privatePage: {
            get() {
                return this.$store.state.timeSheet.privatePage;
            },
            set(value) {
                this.$store.state.timeSheet.privatePage = value;
            }
        },
        isUpdate(){
            return this.$store.state.timeSheet.isUpdate;
        },
        isValidateRecaptcha: {
            get() {
                return this.$store.state.timeSheet.isValidateRecaptcha;
            },
            set(value) {
                this.$store.state.timeSheet.isValidateRecaptcha = value;
            }
        },
        isDisabled(){
            let isError = false;
            for(let i = 0; i < this.days.length; i++){
                if(this.days[i].errors.length > 0){
                    isError = true;
                    break;
                }
            }
            return (!this.isValidateRecaptcha && this.currentUser == null)
                || this.loadingSaveTimeSheet
                || isError
                || (this.currentUser !== null && this.currentUser.status !== userStatuses.ACTIVE);
        }
    },
    methods: {
        submit(){ 
            let isHaveError = false;
            // Checking filled fields
            for(let i = 0; i < this.days.length; i++){
                this.days[i].errors = [];
                if(this.days[i].timeStart == null || this.days[i].timeEnd == null ||  this.days[i].dateStart== null || this.days[i].dateEnd == null){
                    isHaveError = true;
                    this.days[i].errors.push({text: 'You need to fill in all fields', type: 'time'});
                }
                for(let j = 0; j < this.days[i].breaks.length; j++){
                    if(this.days[i].breaks[j].type == null || this.days[i].breaks[j].timeStart == null || this.days[i].breaks[j].timeEnd == null){
                        isHaveError = true;
                        this.days[i].errors.push({text: 'You need to fill in all fields', type: 'break'});
                        break;
                    }
                }
            }
            
            if(!isHaveError){
                let form_data = new FormData();
                // User information
                if(this.isUpdate){
                    form_data.append('user_name', this.dataEntryUpdate.user_name);
                    form_data.append('user_email', this.dataEntryUpdate.user_email);
                    form_data.append('company_id', this.$store.state.timeSheet.workOrder.company_id);
                    form_data.append('worker_id', this.worker.id );

                }
                else if(this.privatePage){                
                    form_data.append('worker_id', this.worker.worker_id);
                    form_data.append('company_id', this.worker.work_order.company.id);
                    form_data.append('user_location', this.worker.location.state + ' ' + this.worker.location.city
                        + ' ' + this.worker.location.address);
                    form_data.append('user_position', this.worker.position.title);
                    
                }
                else{
                    form_data.append('company_id', this.dataEntryUpdate.company.id);
                    form_data.append('user_name', this.dataEntryUpdate.user_name);
                    form_data.append('user_email', this.dataEntryUpdate.user_email);
                    form_data.append('user_location', this.dataEntryUpdate.user_location);
                    form_data.append('user_position', this.dataEntryUpdate.user_position);
                }
                // Validate
                if(this.isValidate)
                    form_data.append('verified', 1);
                // Notes
                if(this.dataEntryUpdate.notes != null && this.dataEntryUpdate.notes.length >0)
                    form_data.append('notes', this.dataEntryUpdate.notes);
                // File
                if(this.dataEntryUpdate.file != null && this.dataEntryUpdate.file.link == undefined)
                    form_data.append('worker_timesheet_file', this.dataEntryUpdate.file);

                // Delete days and breaks
                if(this.isUpdate){
                    for(let i = 0; i < this.$store.state.timeSheet.dataToDelete.days.length; i++)
                    {
                        form_data.append('data_to_delete[days]['+ i + ']', this.$store.state.timeSheet.dataToDelete.days[i]);
                    }
                    for(let i = 0; i < this.$store.state.timeSheet.dataToDelete.breaks.length; i++)
                    {
                        form_data.append('data_to_delete[breaks]['+ i + ']', this.$store.state.timeSheet.dataToDelete.breaks[i]);
                    }
                }
                // Adding days
                for(let i = 0; i < this.days.length; i++){   
                    let breaksCount = 0;
                    let break_total = 0;
                    let break_paid = 0;
                    let break_unpaid = 0;
                    // Adding breaks
                    for(let j = 0; j < this.days[i].breaks.length; j++){                    
                        if(this.days[i].breaks[j].type != null && this.days[i].breaks[j].timeStart !=null && this.days[i].breaks[j].timeEnd)
                        {
                            form_data.append('dates['+ i + '][breaks][' + j + '][id]',  this.days[i].breaks[j].id);
                            form_data.append('dates['+ i + '][breaks][' + j + '][start]',  this.days[i].breaks[j].timeStart.substr(0, 5));
                            form_data.append('dates['+ i + '][breaks][' + j + '][end]',  this.days[i].breaks[j].timeEnd.substr(0, 5));
                            form_data.append('dates['+ i + '][breaks][' + j + '][paid]',  this.breaksTypes[this.days[i].breaks[j].type].paid? 1 : 0);
                            form_data.append('dates['+ i + '][breaks][' + j + '][break_type]',  this.breaksTypes[this.days[i].breaks[j].type].id);

                            breaksCount++;
                        
                            let diff = helpers.getBreakDiffMinutes(this.days[i].breaks[j].timeStart, this.days[i].breaks[j].timeEnd);

                            if(this.breaksTypes[this.days[i].breaks[j].type].paid){
                                if(this.breaksTypes[this.days[i].breaks[j].type].duration < diff)
                                {
                                    break_paid += parseInt(this.breaksTypes[this.days[i].breaks[j].type].duration);
                                    break_unpaid += diff - parseInt(this.breaksTypes[this.days[i].breaks[j].type].duration)
                                }
                                else{
                                    break_paid += parseInt(diff);
                                }
                            }
                            else{
                                break_unpaid += diff;
                            }
                            break_total += parseInt(diff);
                        }
                        
                    }
                    break_paid = parseFloat(Math.trunc(break_paid/60) + '.' + ((break_paid % 60 < 10 ? '0' + break_paid % 60: break_paid % 60)/60).toFixed(2).toString().substr(2, 2));

                    // Day info
                    let total = 0;
                    let diff = helpers.getDiffMinutes(this.days[i].dateStart+'T'+this.days[i].timeStart, this.days[i].dateEnd+'T'+this.days[i].timeEnd); 
                    diff -= break_unpaid
                    let mins = (parseInt( diff%60 < 10 ? '0' + diff%60: diff%60)/60).toFixed(2).toString().substr(2, 2)
                    
                    total = parseFloat(Math.trunc(diff/60) + '.' + (mins.length == 0 ? '00': mins));

                    form_data.append('dates['+ i + '][date]', this.days[i].dateStart);
                    form_data.append('dates['+ i + '][total_hours]', parseFloat(total));
                    form_data.append('dates['+ i + '][nightshift]', (new Date(new Date(this.days[i].dateStart).getTime()).getDate() != new Date(this.days[i].dateEnd).getDate()? 1 : 0));
                    form_data.append('dates['+ i + '][time][start]', this.days[i].timeStart.replace(".", ":").substr(0, 5));
                    form_data.append('dates['+ i + '][time][end]', this.days[i].timeEnd.replace(".", ":").substr(0, 5));
                    form_data.append('dates['+ i + '][time][id]', this.days[i].timeId);
                    form_data.append('dates['+ i + '][timesheet_id]', this.days[i].timesheet_id);
                    // Break info
                    if(breaksCount == 0)
                        form_data.append('dates['+ i + '][breaks]',  null);
                    form_data.append('dates['+ i + '][break]', parseFloat(Math.trunc(break_total/60) + '.' + ((break_total % 60 < 10 ? '0' + break_total % 60: break_total % 60)/60).toFixed(2).toString().substr(2, 2)));
                    form_data.append('dates['+ i + '][break_paid]', break_paid);
                }
                // save
                this.saveData(form_data);
            }
            else{
                this.scrollToRecordedHours();
            }
        },
        // Recaptcha
        onVerify (response) {
            if (response) this.isValidateRecaptcha = true;
        },
        // Change file
        onChange(value){
            this.dataEntryUpdate.file = value;
        },
        // Other
        redirect(name){            
            this.$router.push({ name: name });
        },
        openDocument(value){
            window.open(value);
        },
        validateClick(){
            this.isValidate = true;
            document.getElementById("save-btn").click();
        },
        saveData(form_data){
          if (this.isUpdate)
            this.$store.dispatch('timeSheet/updateTimeSheet', {
              id: this.dataEntryUpdate.data_entries_id,
              data: form_data
            });
          else
            this.$store.dispatch('timeSheet/createTimeSheet', form_data);
        },
        deleteEntries(){
          if(confirm('Do you want to delete the data entry data?')){
            this.$store.dispatch('timeSheet/deleteTimeSheet', this.dataEntryUpdate.data_entries_id);
          }

        },
        scrollToRecordedHours() {
          let element = document.getElementById("recordedHoursTitle");
          window.scrollTo(0, this.getOffset(element).top);
        },
        getOffset(el) {
          const rect = el.getBoundingClientRect();
          return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
          };
        }
    },
};
</script>