<template>
    <div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <div v-bind:class="[type == 'edit' ? 'edit-tab' : '','create-or-edit-tab']">
                    <div v-if="type == 'add'"  class="head-title">Please tell more about job </div>
                    <div class="title">Job Info</div>
                    <ValidationProvider
                        name="Job category"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Job category<span class="star">*</span></label>
                            <b-select 
                                v-model="workOrder.job_info.job_category" 
                                :class="[{ 'has-danger': failed }]">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select job category</b-form-select-option>
                                    </template>
                                    <option
                                        v-for="(option, index) in params.job_categories"
                                        :key="index"
                                        :value="index" >
                                            {{ option }}
                                        </option>
                                </b-select>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <!-- <ValidationProvider
                        name="Job specialty"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Job specialty<span class="star">*</span></label>
                            <b-select 
                                v-model="workOrder.job_info.job_specialty" 
                                :class="[{ 'has-danger': failed }]">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select job specialty</b-form-select-option>
                                    </template>
                                    <option
                                        v-for="(option, index) in []"
                                        :key="index"
                                        :value="index" >
                                            {{ option }}
                                        </option>
                                </b-select>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider> -->
                    <ValidationProvider
                        name="Job Description"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Job Description<span class="star">*</span></label>
                            <b-form-textarea
                                v-model="workOrder.job_info.job_description"
                                placeholder="Write job description"
                                rows="5"
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-textarea>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>


                    <div class="title">Shedule Work Order Period</div>
                    <ValidationProvider
                        name="Job Description"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Data range</label>
                            <el-date-picker
                                v-model="startEnd"
                                type="daterange"
                                range-separator="To"
                                start-placeholder="Start date"
                                end-placeholder="End date"
                                @change="updateStartEnd"
                                :class="[{ 'has-danger': failed }, 'data-range']">
                            </el-date-picker>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <label class="label">Start Time</label>
                    <b-input
                        type="time"
                        v-model="workOrder.start_time"
                        >
                    </b-input> 
                    <label class="label">End Time</label>
                    <b-input
                        type="time"
                        v-model="workOrder.end_time"
                    ></b-input> 
                </div>
                <div v-bind:class="type == 'add'? 'bottom-btns' : 'mt-3'">
                    <b-row >
                        <b-col v-bind:class="type == 'add' ? 'text-center' : 'pl-0'"><span v-on:click="cancel" class="cancel">Cancel</span></b-col>
                        <b-col v-bind:class="type == 'add' ? 'text-center' : 'text-end pr-0'">
                            <b-row>
                                <b-col><b-button v-on:click="previous" variant="outlined">Previous</b-button></b-col>
                                <b-col><b-button type="submit" variant="primary">Next</b-button></b-col>                            
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required} from 'vee-validate/dist/rules';
extend('required', required);
export default {
    name: "JobInformation",
    data() {
        return {
            startEnd:null
        };
    },
    props:{
        type:String
    },
    computed: {
        workOrder(){

            if(this.type == 'edit'){
                let edit = this.$store.state.workOrders.workOrder;
                let vm = this
                if(edit.start_date != null  && edit.end_date != null)
                    vm.startEnd = [new Date(edit.start_date),new Date(edit.end_date)]
                return edit;
            }
            else
                return this.$store.state.workOrders.newWorkOrder;
        },
        params(){
            return this.$store.state.workOrders.params;
        },
    },
    methods: {        
        submit(){
            this.$emit('next');
        },
        cancel(){            
            this.$router.push({ name: 'WorkOrders' });
        },
        previous(){
            this.$emit('previous');
        },
        updateStartEnd(){
            this.$store.state.workOrders.newWorkOrder.start_date = (this.startEnd[0].getMonth() + 1 > 9 ? (this.startEnd[0].getMonth() + 1): '0' + (this.startEnd[0].getMonth() + 1))
                + '/' + (this.startEnd[0].getDate() + 1 > 9 ? (this.startEnd[0].getDate() + 1): '0' + (this.startEnd[0].getDate() + 1))
                + '/' + this.startEnd[0].getFullYear();
            this.$store.state.workOrders.newWorkOrder.end_date = (this.startEnd[1].getMonth() + 1 > 9 ? (this.startEnd[1].getMonth() + 1): '0' + (this.startEnd[1].getMonth() + 1))
                + '/' + (this.startEnd[1].getDate() + 1 > 9 ? (this.startEnd[1].getDate() + 1): '0' + (this.startEnd[1].getDate() + 1))
                + '/' + this.startEnd[1].getFullYear();
        }
    },
};
</script>
<style lang="scss" scoped>
.data-range{
    width: 100%;
}
</style>