<template>
  <div>
    <b-row class="justify-content-md-center mb-3">
      <b-col class="bread-crumbs"><span class="redirect" v-on:click="redirect('Dashboard')">Dashboard</span><span class="arrow">></span><span class="active">Time sheet</span></b-col>
      <b-col class="col-auto"><b-button variant="primary" v-on:click="redirect('DataEntryCreate')" >Create time sheet</b-button></b-col>
    </b-row>
    <table-and-filters 
      v-if="isTable" 
      type="timeSheet"
      @changeView="changeView"/>
    <list-and-filters 
      v-else 
      type="timeSheet"
      @changeView="changeView"/>
  </div>
</template>

<script>
import TableAndFilters from '../components/common/TableAndFilters.vue';
import ListAndFilters from '../components/common/ListAndFilters.vue';
export default {
  name: "TimeSheet",
  components: {
    TableAndFilters,
    ListAndFilters,
  },
  data() {
    return {
      isTable:true,
      isShowAddNewWorkers:false
    };
  },
  methods:{
    changeView(value){
      this.isTable = value;
    },
    redirect(name){            
      this.$router.push({ name: name });
    }
  }
};
</script>
<style  scoped>
</style>
