var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{class:[_vm.type == 'edit' ? 'edit-tab' : '','create-or-edit-tab']},[(_vm.type == 'add')?_c('div',{staticClass:"head-title"},[_vm._v("Please enter initial information")]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v("General Info")]),_c('ValidationProvider',{attrs:{"name":"Name of work order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Name of work order"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter name of work order"},model:{value:(_vm.workOrder.title),callback:function ($$v) {_vm.$set(_vm.workOrder, "title", $$v)},expression:"workOrder.title"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Company"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-select',{class:[{ 'has-danger': failed }],on:{"change":_vm.updateCompany},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{staticClass:"placeholder",attrs:{"value":null}},[_vm._v("Select company")])]},proxy:true}],null,true),model:{value:(_vm.workOrder.company_id),callback:function ($$v) {_vm.$set(_vm.workOrder, "company_id", $$v)},expression:"workOrder.company_id"}},_vm._l((_vm.params.companies),function(option,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('label',{staticClass:"label"},[_vm._v("Branch"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-select',{scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{staticClass:"placeholder",attrs:{"value":null}},[_vm._v("Select branch")])]},proxy:true}],null,true),model:{value:(_vm.workOrder.branch_id),callback:function ($$v) {_vm.$set(_vm.workOrder, "branch_id", $$v)},expression:"workOrder.branch_id"}},_vm._l((_vm.branches),function(option,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('ValidationProvider',{attrs:{"name":"Job Status","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Job Status"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-select',{class:[{ 'has-danger': failed }],scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{staticClass:"placeholder",attrs:{"value":null}},[_vm._v("Select job Status")])]},proxy:true}],null,true),model:{value:(_vm.workOrder.status),callback:function ($$v) {_vm.$set(_vm.workOrder, "status", $$v)},expression:"workOrder.status"}},_vm._l((_vm.params.job_statuses),function(option,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('label',{staticClass:"label"},[_vm._v("Number of Workers")]),_c('b-form-input',{attrs:{"type":"number","placeholder":"Enter name of work order"},model:{value:(_vm.workOrder.number_of_workers),callback:function ($$v) {_vm.$set(_vm.workOrder, "number_of_workers", $$v)},expression:"workOrder.number_of_workers"}}),_c('div',{staticClass:"title"},[_vm._v("Address Info")]),_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Address"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter address"},model:{value:(_vm.workOrder.job_location.address),callback:function ($$v) {_vm.$set(_vm.workOrder.job_location, "address", $$v)},expression:"workOrder.job_location.address"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"State","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("State"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-select',{class:[{ 'has-danger': failed }],on:{"change":_vm.updateState},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{staticClass:"placeholder",attrs:{"value":null}},[_vm._v("Select state")])]},proxy:true}],null,true),model:{value:(_vm.workOrder.job_location.state),callback:function ($$v) {_vm.$set(_vm.workOrder.job_location, "state", $$v)},expression:"workOrder.job_location.state"}},_vm._l((_vm.params.states),function(option,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"City","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("City"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-select',{class:[{ 'has-danger': failed }],on:{"change":_vm.updateCity},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{staticClass:"placeholder",attrs:{"value":null}},[_vm._v("Select city")])]},proxy:true}],null,true),model:{value:(_vm.workOrder.job_location.city),callback:function ($$v) {_vm.$set(_vm.workOrder.job_location, "city", $$v)},expression:"workOrder.job_location.city"}},_vm._l((_vm.cities),function(option,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Zip Code","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Zip Code"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-select',{class:[{ 'has-danger': failed }],scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{staticClass:"placeholder",attrs:{"value":null}},[_vm._v("Select zip code")])]},proxy:true}],null,true),model:{value:(_vm.workOrder.job_location.zipcode),callback:function ($$v) {_vm.$set(_vm.workOrder.job_location, "zipcode", $$v)},expression:"workOrder.job_location.zipcode"}},_vm._l((_vm.zipcode),function(option,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{class:_vm.type == 'add'? 'bottom-btns' : 'mt-3'},[_c('b-row',[_c('b-col',{class:_vm.type == 'add' ? 'text-center' : 'pl-0'},[_c('span',{staticClass:"cancel",on:{"click":_vm.cancel}},[_vm._v("Cancel")])]),_c('b-col',{class:_vm.type == 'add' ? 'text-center' : 'text-end pr-0'},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Next")])],1)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }