<template>
    <b-modal :visible="isShowLocal" @hide="closeModal" centered >
        <div><b-icon  icon="x" class="close-modal"  @click="closeModal()"/></div>
        <h5>There is already a created time sheet for the selected date. Instead of creating a new one, you can edit by clicking on the links</h5>
        <div v-for="(item,index) in links" :key="index" @click="openLink(item)">{{index+1}}) <span class="click-to-edit">Click to view</span></div>
    </b-modal>
</template>

<script>
export default {
    name: "CheckOnFilledDataForTheSelectedDate",
    props:{
        isShow:Boolean,
    },
    watch: {
        isShow() {
            this.isShowLocal = this.isShow;
        }
    },
    data() {
        return {
            isShowLocal:false
        };
    },
    computed:{
        checkDate() {
            return this.$store.state.timeSheet.checkDate;            
        },
        links() {
            return [].concat(this.checkDate.regular, this.checkDate.simplified);
        }
    },
    methods: {
        closeModal(){
            this.$emit('close');
        },
        openLink(link){
            window.open(link);
        }
    },
};
</script>