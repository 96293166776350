<template>
    <div class="tab">
        <div class="head-title">Basic Information</div>
        <div v-if="loading">
            <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
            </div>
        </div>
        <div v-else>
            <div v-if="basicInfo == null || (basicInfo.about == null && basicInfo.work_history.length == 0 
                && basicInfo.education.length == 0 && basicInfo.attachments.length == 0)" class="empty">
                The base information is not set yet, once it is selected it will be displayed here.
            </div>
            <div v-else>
                <!-- About -->
                <div v-if="basicInfo.about != null">
                    <div class="title">About</div>
                    <div class="ml-3">{{basicInfo.about}}</div>    
                </div>
                <!--Work Experience  -->
                <div v-if="basicInfo.work_history.length > 0">
                    <b-row v-if="basicInfo.experience !=null"  class="title justify-content-md-center">
                        <b-col>Work Experience<span class="date"> ({{basicInfo.experience.years_of_experiences}} years)</span></b-col>
                        <b-col 
                            v-if="basicInfo.work_history.length > 1"
                            class="col-auto view-all" 
                            v-on:click="isShowAllWorkExperience = !isShowAllWorkExperience">
                                {{isShowAllWorkExperience ? 'Hide' : 'View All'}}
                        </b-col>
                    </b-row> 
                    <div v-if="!isShowAllWorkExperience" class="ml-3">            
                        <work-experience :workExperience="basicInfo.work_history[0]"/> 
                    </div>
                    <div v-else class="ml-3">
                        <div v-for="(item, index) in basicInfo.work_history" v-bind:key="index">
                            <work-experience :workExperience="item"/>    
                            <hr v-if="index != basicInfo.work_history.length - 1"/>
                        </div>
                    </div> 
                </div>
                <!-- Education  -->
                <div v-if="basicInfo.education.length > 0">
                    <b-row class="title justify-content-md-center">
                        <b-col>Education</b-col>
                        <b-col 
                            v-if="basicInfo.education.length > 1"
                            class="col-auto view-all" 
                            v-on:click="isShowAllEducation= !isShowAllEducation">
                                {{isShowAllEducation ? 'Hide' : 'View All'}}
                        </b-col>
                    </b-row>
                    <div v-if="!isShowAllWorkExperience" class="ml-3">            
                        <education :education="basicInfo.education[0]"/> 
                    </div>
                    <div v-else class="ml-3">
                        <div v-for="(item, index) in basicInfo.education" v-bind:key="index">
                            <education :education="item"/>    
                            <hr v-if="index != basicInfo.education.length - 1"/>
                        </div>
                    </div>
                </div>     
                <!--  Attachments Documen -->
                <div v-if="basicInfo.attachments.length > 0" >
                    <b-row class="title justify-content-md-center">
                        <b-col>Attachments Document<span v-if="!isShowAllDocument && basicInfo.attachments.length > 1" class="date"> (1 doc in {{basicInfo.attachments.length}} docs)</span></b-col>
                        <b-col 
                            v-if="basicInfo.attachments.length > 1"
                            class="col-auto view-all" 
                            v-on:click="isShowAllDocument = !isShowAllDocument">
                                {{isShowAllDocument ? 'Hide' : 'View All'}}
                        </b-col>
                    </b-row>
                    <div v-if="!isShowAllDocument" class="ml-3">            
                        <document :document="basicInfo.attachments[0]"/> 
                    </div>
                    <div v-else class="ml-3">
                        <div v-for="(item, index) in basicInfo.attachments" v-bind:key="index">
                            <document :document="item"/>    
                            <hr v-if="index != basicInfo.attachments.length - 1"/>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WorkExperience from './components/WorkExperience.vue';
import Education from './components/Education.vue';
import Document from './components/Document.vue'
export default {
    name: "BasicInformation",
    props:{
        type:String
    },
    components: {
        WorkExperience,
        Education,
        Document
    },
    computed: {
        basicInfo() {
          return this.$store.state[this.type].basicInformation;
        },
        loading(){
          return this.$store.state[this.type].loadingBasicInformation;
        }
    },
    data() {
        return {
            isShowAllWorkExperience: false,
            isShowAllEducation:false,
            isShowAllDocument:false
        };
    },
};
</script>
<style  scoped>
</style>