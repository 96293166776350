var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{class:[_vm.type == 'edit' ? 'edit-tab' : '','create-or-edit-tab']},[(_vm.type == 'add')?_c('div',{staticClass:"head-title"},[_vm._v("Please tell more about job ")]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v("Job Info")]),_c('ValidationProvider',{attrs:{"name":"Job category","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Job category"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-select',{class:[{ 'has-danger': failed }],scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{staticClass:"placeholder",attrs:{"value":null}},[_vm._v("Select job category")])]},proxy:true}],null,true),model:{value:(_vm.workOrder.job_info.job_category),callback:function ($$v) {_vm.$set(_vm.workOrder.job_info, "job_category", $$v)},expression:"workOrder.job_info.job_category"}},_vm._l((_vm.params.job_categories),function(option,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Job Description","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Job Description"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-textarea',{class:[{ 'has-danger': failed }],attrs:{"placeholder":"Write job description","rows":"5"},model:{value:(_vm.workOrder.job_info.job_description),callback:function ($$v) {_vm.$set(_vm.workOrder.job_info, "job_description", $$v)},expression:"workOrder.job_info.job_description"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"title"},[_vm._v("Shedule Work Order Period")]),_c('ValidationProvider',{attrs:{"name":"Job Description","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Data range")]),_c('el-date-picker',{class:[{ 'has-danger': failed }, 'data-range'],attrs:{"type":"daterange","range-separator":"To","start-placeholder":"Start date","end-placeholder":"End date"},on:{"change":_vm.updateStartEnd},model:{value:(_vm.startEnd),callback:function ($$v) {_vm.startEnd=$$v},expression:"startEnd"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('label',{staticClass:"label"},[_vm._v("Start Time")]),_c('b-input',{attrs:{"type":"time"},model:{value:(_vm.workOrder.start_time),callback:function ($$v) {_vm.$set(_vm.workOrder, "start_time", $$v)},expression:"workOrder.start_time"}}),_c('label',{staticClass:"label"},[_vm._v("End Time")]),_c('b-input',{attrs:{"type":"time"},model:{value:(_vm.workOrder.end_time),callback:function ($$v) {_vm.$set(_vm.workOrder, "end_time", $$v)},expression:"workOrder.end_time"}})],1),_c('div',{class:_vm.type == 'add'? 'bottom-btns' : 'mt-3'},[_c('b-row',[_c('b-col',{class:_vm.type == 'add' ? 'text-center' : 'pl-0'},[_c('span',{staticClass:"cancel",on:{"click":_vm.cancel}},[_vm._v("Cancel")])]),_c('b-col',{class:_vm.type == 'add' ? 'text-center' : 'text-end pr-0'},[_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"variant":"outlined"},on:{"click":_vm.previous}},[_vm._v("Previous")])],1),_c('b-col',[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Next")])],1)],1)],1)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }