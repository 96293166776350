<template>
  <div class="login_main">
    <b-card class="login_card">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div class="user_icons_box">
              <b-icon icon="person" scale="1"></b-icon>
            </div>
          <ValidationProvider name="Email" rules="required|email" v-slot="{ failed, errors ,}" mode="passive">
            <b-form-input type="text" placeholder="Email" v-model="user.email" required :class="[{ 'has-danger': failed }, 'input-login']" name="email"></b-form-input> 
            <div  class="has-danger-error">{{ errors[0]}}</div>
          </ValidationProvider>
          <ValidationProvider name="Password" rules="required" v-slot="{ failed, errors }">
            <b-form-input type="password" placeholder="Password" v-model="user.password" required :class="[{ 'has-danger': failed }, 'input-login']" ></b-form-input> 
            <div class="has-danger-error">{{ errors[0]}}</div>
          </ValidationProvider>
          <b-button type="submit" variant="primary" class="login_btn" :disabled="loading">
            <b-spinner small v-if="loading" class="mr-1"></b-spinner>
            Login
          </b-button>
          </form>
      </ValidationObserver>
      <div @click="forgotPassword()" class="link mt-3">Forgot password?</div>
    </b-card>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import '../../../assets/css/login.scss';
extend('email', email);
extend('required', required);
export default {
  name: "Login",
  data() {
    return {
      user:{
        email: "",
        password: ""
      }
    };
  },
  computed:{
    loading() {
      return this.$store.state.auth.loadingCurrentUser;
    },
  },
  methods: {
    submit() {
      this.$store.dispatch('auth/login', this.user);
    },
    forgotPassword() {
      this.$router.push('/user/forgot-password');
    }
  },
};
</script>

<style scoped>
</style>