<template>
    <b-row class="filter-by justify-content-md-center">
        <b-col class="col-auto">
            <div class="reset-filters" v-on:click="resetAll()"><img src="@/assets/icons/reset.svg" class="reset-icon"/>Reset all</div>
            <div v-for="(item, index) in tabsName[type]" 
                v-bind:key="index"
                v-on:click="activeTab = index"
                v-bind:class="[activeTab == index ? 'tab-selected' : '', 'tab']">
                    {{item}}
            </div>
        </b-col>
        <b-col>
            <div class="text-right reset-filters" v-on:click="resetSection()"><img src="@/assets/icons/reset.svg" class="reset-icon"/>Reset section</div>
            <div v-if="tabsName[type][activeTab] == 'Date'">
                <div class="filter-header mb-2">Date</div>
                <el-date-picker
                    v-model="filters.date"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                     @change="updateFilters">
                </el-date-picker>
            </div>
            <div v-else-if="tabsName[type][activeTab] == 'Address'">
                <div class="filter-header mb-2">State</div>
                <b-select v-model="filters.state" :options="states" @change="updateState">
                </b-select>
                <div class="filter-header my-2">City</div>
                <b-select v-model="filters.city" :options="city" @change="updateCity">
                </b-select>
                <b-row>
                    <b-col>
                        <div class="filter-header my-2">Zip code</div>
                        <b-select v-model="filters.zipCode" :options="zipCode">
                        </b-select>
                    </b-col>
                    <b-col>
                        <div class="filter-header my-2">Radius</div>
                        <b-input v-model="filters.radius"/>
                    </b-col>
                </b-row>
            </div>
            <div v-else-if="tabsName[type][activeTab] == 'Additional'">
                <div class="filter-header mb-2">Certificate title</div>
                <b-input v-model="filters.certificate_title" placeholder="Start to type certificate title" @change="updateFilters"/>
                <div class="filter-header mb-2 mt-4">Job Preferences</div>
                <b-row>
                    <b-col v-for="(item,index) in jobApplicantStatus" 
                        v-bind:key="index"
                        class="col-auto mb-3 ">
                        <div v-bind:class="[selectTag(index,'jobApplicantStatus')  ? 'filter-tag-selected' : '', 'filter-tag']"
                            v-on:click="clickTag(index,'jobApplicantStatus')">{{item}}</div>
                    </b-col>
                </b-row>
                <div class="filter-header mb-2">Type of degree</div>
                <b-row>
                    <b-col v-for="(item,index) in typeOfDegree" 
                        v-bind:key="index"
                        class="col-auto mb-3">
                        <div v-bind:class="[selectTag(index,'degrees')  ? 'filter-tag-selected' : '', 'filter-tag']"
                            v-on:click="clickTag(index,'degrees')">{{item}}</div>
                    </b-col>
                </b-row>                
            </div>
            <div v-else-if="tabsName[type][activeTab] == 'Work Experience'">
                <div class="filter-header mb-2">Experience title</div>
                <b-input v-model="filters.experience_title" placeholder="Start to type experience title"  @change="updateFilters"/>
                <div class="filter-header mb-2 mt-4">Experience level</div>
                <b-row>
                    <b-col v-for="(item,index) in levels" 
                        v-bind:key="index"
                        class="col-auto mb-3">
                        <div v-bind:class="[selectTag(index,'experience_level')  ? 'filter-tag-selected' : '', 'filter-tag']"
                            v-on:click="clickTag(index,'experience_level')">{{item}}</div>
                    </b-col>
                </b-row>
            </div>
            <div v-else-if="tabsName[type][activeTab] == 'Recruiter'">
                <div class="filter-header mb-2">Recruiter</div>
                <el-select v-model="filters.recruiters" multiple placeholder="Select subcategories" @change="updateFilters">
                    <el-option
                    v-for="(item, index) in recruiters"
                    v-bind:key="index"
                    :label="item"
                    :value="+index">
                    </el-option>
                </el-select>
                <div class="filter-header mb-2 mt-4">Interview result</div>
                <b-row>
                    <b-col v-for="(item,index) in interviewResults" 
                        v-bind:key="index"
                        class="col-auto mb-2">
                        <div v-bind:class="[selectTag(index,'interview_results')  ? 'filter-tag-selected' : '', 'filter-tag']"
                            v-on:click="clickTag(index,'interview_results')">{{item}}</div>
                    </b-col>
                </b-row>
            </div>
            <div v-else-if="tabsName[type][activeTab] == 'Skills and Tools'">
                <div class="filter-header mb-2 mt-3">Skills </div>
                <el-select v-model="filters.skills" multiple placeholder="Select subcategories"  @change="updateFilters">
                    <el-option
                    v-for="(item, index) in skills"
                    v-bind:key="index"
                    :label="item"
                    :value="+index">
                    </el-option>
                </el-select>
                <div class="filter-header mb-2 mt-3">Tools </div>
                <el-select v-model="filters.tools" multiple placeholder="Select subcategories"  @change="updateFilters">
                    <el-option
                    v-for="(item, index) in tools"
                    v-bind:key="index"
                    :label="item"
                    :value="+index">
                    </el-option>
                </el-select>
                <div class="filter-header mb-2 mt-3">Subcategories </div>
                <el-select v-model="filters.subCategories" multiple placeholder="Select subcategories" @change="updateFilters">
                    <el-option
                    v-for="(item, index) in subCategories"
                    v-bind:key="index"
                    :label="item"
                    :value="+index">
                    </el-option>
                </el-select>
            </div>
        </b-col>
    </b-row>
</template>


<script>
// import {DatePicker} from 'element-ui';
export default {
    props:{
        type:String
    },
    components:{
        // [DatePicker.name]:DatePicker,
    },
    data() {
        return {
            activeTab:0,
            tabsName:{
                applicants:['Date','Address','Additional','Work Experience','Recruiter','Skills and Tools']
            },
            filters:{}
        }
    },
    created(){
        this.resetAll(false);
    },
    methods:{
        clickTag(value,tagField){
            let el = this.filters[tagField].find(e => e == value)

            if(el == undefined)
                this.filters[tagField].push(value);
            else 
                this.filters[tagField].splice(this.filters[tagField].indexOf(el), 1);
            this.updateFilters();
        },
        selectTag(index,tagField){
            if(this.filters[tagField].find(e => e == index) == undefined)
                return false;
            else 
                return true;
        },
        resetAll(isUpdate=true){
            this.filters = {
                date:null,
                state:[],
                city:[],
                zipCode:[],
                radius:'',
                jobApplicantStatus:[],
                degrees:[],
                certificate_title:'',
                experience_title:'',
                experience_level:[],
                recruiters:[],
                interview_results:[],
                skills:[],
                tools:[],
                subCategories:[]
            };
            if(isUpdate)
                this.$emit('updateFilters', this.filters);
        },
        resetSection(){
            if(this.tabsName[this.type][this.activeTab] == 'Date'){
                this.filters.date = []
            }
            else if(this.tabsName[this.type][this.activeTab] == 'Address')
            {
                this.filters.state = [];
                this.filters.city = [];
                this.filters.zipCode = [];
                this.filters.radius = '';
            }
            else if(this.tabsName[this.type][this.activeTab] == 'Additional'){
                this.filters.jobApplicantStatus = [];
                this.filters.degrees = [];
                this.filters.certificate_title = '';
            }
            else if(this.tabsName[this.type][this.activeTab] == 'Work Experience'){
                this.filters.experience_title = '';
                this.filters.experience_level = [];
            }
            else if(this.tabsName[this.type][this.activeTab] == 'Recruiter'){
                this.filters.recruiters = [];
                this.filters.interview_results = [];
            }
            else if(this.tabsName[this.type][this.activeTab] == 'Skills and Tools'){
                this.filters.skills = [];
                this.filters.tools = [];
                this.filters.subCategories = [];
            }
            this.updateFilters;
        },
        updateFilters(){
            this.$emit('updateFilters', Object.assign({}, this.filters));
        },
        updateState(){
            this.updateFilters();
            this.$store.dispatch('common/getCitiesByIdState', this.filters.state);
        },
        updateCity(){
            this.updateFilters();
            this.$store.dispatch('common/getZipCodesByIdCity', this.filters.city);
        }
    },
    computed: {
        states() {
            return this.$store.state[this.type].states;
        },
        city(){
            return this.$store.state.common.cities;
        },
        zipCode(){
            return this.$store.state.common.zipCodes;
        },
        jobApplicantStatus(){
            return this.$store.state[this.type].jobApplicantStatus;
        },
        typeOfDegree(){
            return this.$store.state[this.type].degrees;
        },
        levels(){
            return this.$store.state[this.type].levels;
        },
        recruiters(){
            return this.$store.state[this.type].recruiters;
        },
        interviewResults(){
            return this.$store.state[this.type].interviewResults;
        },
        skills(){
            return this.$store.state[this.type].skills;
        },
        tools(){
            return this.$store.state[this.type].tools;
        },
        subCategories(){
            return this.$store.state[this.type].subCategories;
        }
    }
}
</script>
<style scoped>

.filter-by{
    position: absolute;
    background: white;
    z-index: 9998;
    box-shadow: 0px 6px 12px rgb(7 7 12 / 8%);
    border-radius: 4px;
    border: 1px solid #D8D9E2;
    width: 700px;
    margin-top: 30px;
    padding: 22px;
}
.tab{
    padding: 10px;
    color: #3F4054;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    cursor:pointer;
    border-radius: 4px;
}
.tab:hover{
    color: #8687A4;
}
.reset-filters{
    font-size: 14px;
    color: #484DCA;
    margin-bottom: 34px;
    cursor: pointer;
}
.reset-filters:hover{
    color: #272ed7 !important;
}
.reset-icon{
    width: 13px;
    margin-right: 10px;
}
.tab-selected{
    color: #484DCA !important;
    border-radius: 4px;
    background: linear-gradient(89.76deg, #a9abe738 -0.67%, rgba(169, 171, 231, 0) 99.79%);
    border: 3px solid #484DCA;
    border: 0px 0px 0px 3px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;;
}
.filter-header{
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #3F4054;
}
.filter-tag{
    border: 1px solid #D8D9E2;
    box-shadow: 0px 6px 12px rgba(7, 7, 12, 0.08);
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #2D2D3B;
    min-width: 102px;
    cursor: pointer;
    padding: 13px 10px;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.filter-tag-selected{
    background: #484DCA;
    color: #FFFFFF;
}
</style>