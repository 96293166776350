<template>
    <div>
        <!-- Actions -->
        <b-row class="mt-4">            
            <b-col class="text-start" v-if="days.length > 1"><span class="remove" v-on:click="$emit('removeDay',index)">Remove day</span></b-col>
            <b-col class="text-end"><span class="add" v-on:click="addBreak(index)">Add break</span></b-col>
        </b-row>
                    
        <div :class="[day.errors.length > 0 ? 'error' : '', 'recorded-hours']">
            <!-- Time errors -->
            <div v-for="(error, index) in day.errors" :key="index" class="error-text">{{error.text}}</div>            

            <!-- One day -->
            <b-row class="item">
                <!-- Date time START -->
                <b-col md="12" lg="2" class="title-col">Clock in</b-col>
                <b-col md="12" lg="4" class="p-0 name-row" >                        
                    <b-form-input v-if="days.length == 1" v-model="days[index].timeStart" type="time" pattern="[0-9]{2}:[0-9]{2}" :show-seconds="false" @change="setDateEnd(index)" />
                    <b-form-input v-else v-model="days[index].timeStart" type="time" pattern="[0-9]{2}:[0-9]{2}" :show-seconds="false" @change="setDateEnd(index)" autofocus/>
                </b-col>
                <b-col md="12" lg="6" class="p-0 name-row"><b-datepicker v-model="days[index].dateStart" :min="minDateClockIn" :max="maxDateClockIn" @hidden="checkOnFilledDataForTheSelectedDate(days[index],index)" @input="setDateEnd(index)"/></b-col>
            </b-row>
            
            <!-- All breaks -->
            <div v-for="(breakItem, indexBreak) in days[index].breaks" :key="indexBreak+'break'">
                <!-- One break -->
                <b-row  class="item">
                    <b-col md="12" lg="2" class="title-col">Break{{breakItem.type != null ? breaksTypes[breakItem.type].paid == true ? ' (paid)' : ' (unpaid)' : '' }}</b-col>
                    <b-col md="12" lg="2" class="p-0 name-row"><b-form-input v-model="breakItem.timeStart" type="time" pattern="[0-9]{2}:[0-9]{2}" show-seconds="false" @change="checkError"/></b-col>
                    <b-col md="12" lg="2" class="p-0 name-row"><b-form-input v-model="breakItem.timeEnd" type="time" pattern="[0-9]{2}:[0-9]{2}" show-seconds="false" @change="checkError" /></b-col>
                    <b-col md="12" lg="6" class="p-0 name-row">
                        <b-select v-model="breakItem.type" @change="checkError">
                            <template #first>
                                <b-form-select-option class="placeholder" :value="null">Select type</b-form-select-option>
                            </template>
                            <option v-for="(option, indexBreaksTypes) in breaksTypes" :key="indexBreaksTypes"  :value="indexBreaksTypes">
                                {{ getBreakName(option) }}
                            </option>
                        </b-select>
                    </b-col>
                </b-row>
                <!-- Close break -->
                <b-icon icon="x" class="close" @click="closeBreak(index, indexBreak)"></b-icon>
            </div>

            <b-row  class="item">
                <!-- Date time END -->
                <b-col md="12" lg="2" class="title-col">Clock out</b-col>
                <b-col md="12" lg="4" class="p-0 name-row">
                    <b-form-input v-model="days[index].timeEnd" type="time" pattern="[0-9]{2}:[0-9]{2}" :show-seconds="false"  @change="setDateEnd(index)"/>
                </b-col>
                <b-col md="12" lg="6" class="p-0 name-row">
                    <b-row class="date-end">
                        <b-col class="col-auto icon-calendar"><b-icon icon="calendar"/></b-col>
                        <b-col class="text">{{days[index].dateEnd == null ? 'Select your working time and start date' : getEndDate(days[index].dateEnd)}}</b-col>
                    </b-row>
                </b-col> 
            </b-row>
        </div> 
        
        <info-modal
            :isShow="isShowInfoModal"
            title="One day"
            msg="You have already added 2 entries for this day"
            @close="isShowInfoModal = false"
        />   
    </div>                 
</template>

<script>
import moment from 'moment';
import helpers from '../../assets/const/helpersTimeSheet';
import daysInterval from "../../assets/const/datepickerDaysInterval";
import InfoModal from "../common/InfoModal.vue";
export default {
    name: "OneDay",
    components:{InfoModal},
    props:{
        index: Number
    },
    data() {
        return {
            errors: [],
            minDateClockIn: daysInterval.MIN_DATE_CLOCK_IN,
            maxDateClockIn: daysInterval.MAX_DATE_CLOCK_IN,
            isShowInfoModal: false
        };

    },
    computed: {
        days(){
            return this.$store.state.timeSheet.days;
        },
        day(){
            return this.$store.state.timeSheet.days[this.index];
        },
        breaksTypes(){
            return this.$store.state.timeSheet.breaksTypes;
        },
        isUpdate(){
            return this.$store.state.timeSheet.isUpdate;
        },
        worker() {
            return this.$store.state.timeSheet.worker;            
        },
    },
    methods: {
        addBreak(index){
            this.days[index].breaks.push({timeStart: null, timeEnd: null,type:null});
        },
        closeBreak(daysIndex,index){
            if(this.isUpdate && this.days[daysIndex].breaks[index].id !== undefined && this.days[daysIndex].breaks[index].id !== null)
                this.$store.state.timeSheet.dataToDelete.breaks.push(this.days[daysIndex].breaks[index].id)
            this.days[daysIndex].breaks.splice(index, 1);
        },
        getBreakName(item){
            return (item.name[0].toUpperCase() + item.name.slice(1) + " ( " +item.duration + " mins" )+ (item.paid ? ", paid )" :", unpaid ) ");
        },
        checkError(){
            this.day.errors = [];

            if(this.day.dateStart != null && this.day.dateEnd != null && this.day.timeStart != null && this.day.timeEnd != null){
                // Checking the day
                let diff = helpers.getDiffHours(this.day.dateStart+'T'+this.day.timeStart, this.day.dateEnd+'T'+this.day.timeEnd);
                if(diff > 24)
                    this.day.errors.push({text:'In one day, you can add a maximum of 24 hours!', type: 'time'});
                else if (diff <= 0)
                    this.day.errors.push({text:'Please enter correct time!', type: 'time'});
                else if(helpers.checkCrossingHoursInSingleDay(this.day, this.days, this.index))
                  this.day.errors.push({text: 'Working hours for the same day must not overlap!', type: 'time'});

                if(helpers.isFutureDay(this.day.dateEnd))
                    this.day.errors.push({text: 'Your shift must end no later than today!', type: 'time'});

                // Checking the breaks
                for(let i = 0; i < this.day.breaks.length; i++){
                    if(helpers.checkBreakOnWithinDate(this.day))
                    {
                        this.day.errors.push({text: 'Break time is incorrect.', type: 'break'});
                        break;
                    }
                    if(helpers.checkBreakForCoincidenceWithOthers(this.day)){
                        this.day.errors.push({text: 'The times of the two breaks are the same.', type: 'break'});
                        break;
                    }
                }
            }
        },
        // Clock out date
        setDateEnd(i){
            if(this.days[i].dateStart != null && this.days[i].timeStart != null && this.days[i].timeEnd != null){
                let start = this.days[i].timeStart.split(':');
                let end = this.days[i].timeEnd.split(':');
                let isNextDay = parseInt(start[0])*60+parseInt(start[1]) > parseInt(end[0])*60+parseInt(end[1])
                this.days[i].dateEnd = isNextDay? moment(this.days[i].dateStart ).add(1,'days').format("YYYY-MM-DD") : this.days[i].dateStart ;  
            }
            else
                this.days[i].dateEnd = null;
            this.checkError();
        },
        getEndDate(value){
            return moment(value).format('dddd') +', ' + moment(value).format("LL")
        },
        checkOnFilledDataForTheSelectedDate(day,index){
            // Is there data for this day

            if(helpers.getCountDate(day, this.days, index) == 2){
                this.days[index].dateStart = null;
                this.days[index].dateEnd = null;
                this.isShowInfoModal = true;
            }
            else if(this.worker !=null && day.dateStart != null){
                this.$store.dispatch('timeSheet/checkOnFilledDataForTheSelectedDate',  { id: this.worker.worker_id == undefined ? this.worker.id : this.worker.worker_id, date: day.dateStart}).then(() =>{
                    if(this.$store.state.timeSheet.checkDate.regular.length > 0 || this.$store.state.timeSheet.checkDate.simplified.length > 0){
                        this.days[index].dateStart = null;
                        this.$emit('isShowCheckData');
                    }
                });
            }
        },
    }          
    
}
</script>