var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login_main"},[_c('b-card',{staticClass:"login_card"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"user_icons_box"},[_c('b-icon',{attrs:{"icon":"person","scale":"1"}})],1),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-input',{class:[{ 'has-danger': failed }, 'input-login'],attrs:{"type":"text","placeholder":"Email","required":"","name":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-input',{class:[{ 'has-danger': failed }, 'input-login'],attrs:{"type":"password","placeholder":"Password","required":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-button',{staticClass:"login_btn",attrs:{"type":"submit","variant":"primary","disabled":_vm.loading}},[(_vm.loading)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_vm._v(" Login ")],1)],1)]}}])}),_c('div',{staticClass:"link mt-3",on:{"click":function($event){return _vm.forgotPassword()}}},[_vm._v("Forgot password?")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }