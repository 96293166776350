<template>
    <div>
        <b-modal id="right-modal" :visible="isShowLocal" @hide="closeModal">
             <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                    <b-row class="justify-content-md-center">
                        <b-col v-if="type=='add'" class="title">Add new applicant</b-col>
                        <b-col v-else class="title">Edit applicant</b-col>
                        <b-col class="col-auto required"><span class="star">*</span> Required</b-col>
                    </b-row>
                    <div class="title-2">Initial Info</div>
                    <ValidationProvider
                        name="First name"
                        rules="required"
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">First name<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter applicants’ first name"
                                v-model="user.first_name"
                                required
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Last name"
                        rules="required"
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Last name<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter applicants’ last name"
                                v-model="user.last_name"
                                required
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Email"
                        rules="required|email"
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Email<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter applicants’ email"
                                v-model="user.email"
                                required
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <label class="label">Phone</label>
                    <vue-phone-number-input
                        v-model="user.phone"
                        v-bind="vuePhone.props"
                        class="person-phone"
                    ></vue-phone-number-input>
                    <label class="label">Alternative Phone </label>
                    <vue-phone-number-input
                        v-model="user.phone2"
                        v-bind="vuePhone.props"
                        class="person-phone"
                    ></vue-phone-number-input>
                    <label class="label">Applicant Stage </label>
                    <b-select 
                        v-model="user.stage" 
                        :options="stages" >
                            <template #first>
                                <b-form-select-option class="placeholder" :value="null">Select stage</b-form-select-option>
                            </template>
                    </b-select>
                    <div class="title-2">Experience Info</div>
                    <!-- <div v-for="(item,index) in user.experience" v-bind:key="index" > -->
                        <experience-info :experience="user.experience"/>
                        <hr class="mt-4"/>
                        <!-- <hr v-if="user.experience.length - 1 != index" class="mt-4"/> -->
                    <!-- </div> -->
                    <!-- <b-button class="add-experience" variant="primary" v-on:click="addExperience()">Add one more experience</b-button> -->

                    <div class="title-2">Adress Info</div>
                    <label class="label">Address</label>
                    <b-form-input
                        type="text"
                        placeholder="Enter address "
                        v-model="user.address.address"
                    ></b-form-input> 
                    <b-row>
                        <b-col>
                            <label class="label">State</label>
                            <b-select 
                                v-if="user.address.state !=null"
                                v-model="user.address.state.id" 
                                :options="states" 
                                @change="updateState">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select state</b-form-select-option>
                                    </template>
                            </b-select>

                        </b-col>
                        <b-col>
                            <label class="label">City</label>
                            <b-select 
                                v-if="user.address.city !=null"
                                v-model="user.address.city.id" 
                                @change="updateCity"
                                :options="cities">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select city</b-form-select-option>
                                    </template>
                            </b-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label class="label">Zip code</label>
                            <b-select 
                                v-if="user.address.zipcode !=null"
                                v-model="user.address.zipcode.id" 
                                :options="zipcode">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select zip code</b-form-select-option>
                                    </template>
                            </b-select>
                        </b-col>
                        <b-col></b-col>
                    </b-row>
                    <b-row class="justify-content-md-center mt-4">
                        <b-col><b-button v-on:click="closeModal" variant="outlined">Cancel</b-button></b-col>
                        <b-col class="col-auto"><b-button type="submit" variant="primary">Save</b-button></b-col>
                    </b-row>
                </form>
            </ValidationObserver>
        </b-modal>
    </div>
</template>
<script>
import ExperienceInfo from './ExperienceInfo.vue'
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
extend('email', email);
extend('required', required);
export default {
    components: { ExperienceInfo},
    props:{
        isShow:Boolean,
        type:String,
        editUser:Object,
        filters:Object,
    },
    watch: {
        isShow() {
            this.isShowLocal = this.isShow;
            if(this.type == 'add'){
                this.initNewUser();
            }else{
                this.user = Object.assign({}, this.editUser);
                if(this.user.address == null)
                    this.user.address = {state:null, zipcode:null, city:null, address:null};
                if(this.user.address.state == null)
                    this.user.address.state = {id: null};
                if(this.user.address.city == null)
                    this.user.address.city = {id: null};
                if(this.user.address.zipcode == null)
                    this.user.address.zipcode = {id: null};

                if(this.user.experience == null){
                    this.user.experience = 
                        {
                            id:null,
                            title:'',
                            type_employment:null,
                            years_of_experiences:'',
                        }
                    // this.addExperience();
                }

                this.updateState();
                this.updateCity();
            }
        }
    },
    data() {
        return {
            isShowLocal:false,
            user:{},
            vuePhone: {
                phone: "",
                props: {
                clearable: true,
                fetchCountry: true,
                preferredCountries: ["US", "GB"],
                noExample: false,
                translations: {
                    countrySelectorLabel: "Country co de",
                    countrySelectorError: "Error",
                    phoneNumberLabel: "Enter your phone",
                    example: "Example:"
                }
                }
            }
        }
    }, 
    computed: {
        stages() {
            return this.$store.state.applicants.stages;
        },
        states(){
            return this.$store.state.applicants.states;
        },
        cities(){
            return this.$store.state.common.cities;
        },
        zipcode(){
            return this.$store.state.common.zipCodes;
        }
    },
    methods:{
        initNewUser(){
            this.user = {
                first_name:'',
                last_name:'',
                email:'',
                phone:'',
                phone2:'',
                stage_id:null,
                experience:[],
                state:null,
                city:null,
                zipcode:null,
                address:{
                    address:'',
                    state:{
                        id:null
                    },
                    city:{
                        id:null
                    },
                    zipcode:{
                        id:null
                    }
                }
            };
            this.addExperience();
        },
        submit(){  
            let data ={
                user:this.user,
                filters:this.filters,
                routeName: this.$route.name
            };
            this.$store.dispatch('applicants/' + (this.type == 'add' ? 'addItem' : 'updateItem'), data);
            this.isShowLocal = false;
        },
        addExperience(){
            this.user.experience.push(
                {
                    id:null,
                    title:'',
                    type_employment:null,
                    years_of_experiences:'',
                }
            );
        },
        updateState(){
            if(this.user.address.state.id != null)
                this.$store.dispatch('common/getCitiesByIdState', this.user.address.state.id);
        },
        updateCity(){                
            if(this.user.address.city.id != null)
                this.$store.dispatch('common/getZipCodesByIdCity', this.user.address.city.id);
        },
        closeModal(){
            this.$emit('closeModal');
        }
    }
}
</script>