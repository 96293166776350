<template>
    <div>
        <b-modal id="right-modal" :visible="isShowLocal" @hide="closeModal">
             <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                    <b-row class="justify-content-md-center">
                        <b-col class="title">Assign Workers</b-col>
                        <b-col class="col-auto required"><span class="star">*</span> Required</b-col>
                    </b-row>
                    <div class="title-2">Initial Info</div>
                    <ValidationProvider
                        name="Work Order"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Work Order Title<span class="star">*</span></label>
                            <b-select 
                                v-on:scroll="scrollWorkers"
                                v-model="workOrderId" 
                                :disabled="step == 2"
                                @change="applicantsAddToWorkOrder"
                                :class="[{ 'has-danger': failed }]">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select work order </b-form-select-option>
                                    </template>
                                    <option

                                        v-for="(option, index) in workOrders"
                                        :key="index"
                                        :value="option.id" >
                                            {{ option.title }}
                                    </option>
                            </b-select>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Position"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }">
                            <label class="label">Position<span class="star">*</span></label>
                            <b-select 
                                v-on:scroll="scrollWorkers"
                                v-model="newWorkOrder.position_id"
                                :disabled="step==1"
                                @change="changePosition" 
                                :class="[{ 'has-danger': failed }]">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select position</b-form-select-option>
                                    </template>
                                    <option
                                        v-for="(option, index) in workOrdersData.positions"
                                        :key="index"
                                        :value="option.id" >
                                            {{ option.title }}
                                    </option>
                            </b-select>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Start Date"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Start Date<span class="star">*</span></label>
                            <b-form-datepicker 
                                v-model="newWorkOrder.start_date"
                                :disabled="step==1"
                                :class="[{ 'has-danger': failed }]"
                            />
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>

                    <div class="title-2">Pay Rate</div>
                    <ValidationProvider
                        name="Regular hourly rate"
                        rules="required"
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Payroll regular hourly rate<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter regular hourly rate"
                                v-model="newWorkOrder.regular_pay_rate"
                                required
                                :disabled="step==1"
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Payroll overtime hourly rate"
                        rules="required"
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Payroll overtime hourly rate<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter payroll overtime hourly rate"
                                v-model="newWorkOrder.overtime_pay_rate"
                                required
                                :disabled="step==1"
                                    :class="[{ 'has-danger': failed }]"
                                ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Payroll doubletime hourly rate"
                        rules="required"
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Payroll doubletime hourly rate<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter payroll doubletime hourly rate"
                                v-model="newWorkOrder.doubletime_pay_rate"
                                required
                                :disabled="step==1"
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Payroll hourly calculator"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Payroll hourly calculator<span class="star">*</span></label>
                            <b-select 
                                :disabled="step==1"
                                v-model="newWorkOrder.payroll_hourly_calculator" 
                                :class="[{ 'has-danger': failed }]">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select payroll hourly calculator</b-form-select-option>
                                    </template>
                                    <option
                                        v-for="(option, index) in workOrdersData.calculators"
                                        :key="index"
                                        :value="index" >
                                            {{ option }}
                                        </option>
                                </b-select>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>

                    <div class="title-2">Bill Rate</div>
                    <ValidationProvider
                        name="Billing regular hourly rate"
                        rules="required"
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Billing regular hourly rate<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter billing regular hourly rate"
                                v-model="newWorkOrder.regular_bill_rate"
                                required
                                :disabled="step==1"
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Billing overtime hourly rate"
                        rules="required"
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Billing overtime hourly rate<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter billing overtime hourly rate"
                                v-model="newWorkOrder.overtime_bill_rate"
                                required
                                :disabled="step==1"
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Billing doubletime hourly rate"
                        rules="required"
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Billing doubletime hourly rate<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter billing doubletime hourly rate"
                                v-model="newWorkOrder.doubletime_bill_rate"
                                required
                                :disabled="step==1"
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Invoice hourly calculator"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }"
                        >
                            <label class="label">Invoice hourly calculator<span class="star">*</span></label>
                            <b-select 
                                v-model="newWorkOrder.invoice_hourly_calculator" 
                                :disabled="step == 1"
                                :class="[{ 'has-danger': failed }]">
                                    <template #first>
                                        <b-form-select-option class="placeholder" :value="null">Select invoice hourly calculator</b-form-select-option>
                                    </template>
                                        <option
                                        v-for="(option, index) in  workOrdersData.calculators"
                                        :key="index"
                                        :value="index" >
                                            {{ option}}
                                        </option>
                                </b-select>
                                <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <b-row class="justify-content-md-center mt-4">
                        <b-col><b-button v-on:click="closeModal" variant="outlined">Cancel</b-button></b-col>
                        <b-col class="col-auto">
                            <b-button v-if="step == 2" type="submit" variant="primary">Save</b-button>
                        </b-col>
                    </b-row>
                </form>
            </ValidationObserver>
        </b-modal>
    </div>
</template>
<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
extend('email', email);
extend('required', required);
export default {
    props:{
        isShow:Boolean,
        users:Array
    },
    watch: {
        isShow() {
            this.isShowLocal = this.isShow;
            this.initWorkOrder()
            this.step = 1;
            this.getWorkOrders();
        }
    },
    data() {
        return {
            step:1,
            isShowLocal:false,
            user:{},
            workOrderId:null,
            newWorkOrder:{},
            workOrder:{
                title:null,
                position:null,
                startDate:null
            },
            workOrderParams:{
                resetList:true,
                filters:{
                    paginate:30
                },
                page:1,
                isAssignWorkers:true
            }
        }
    }, 
    created(){
    },
    computed: {
        workOrders() {
            return this.$store.state.workOrders.itemsAssign;
        },
        loading(){
            return this.$store.state.workOrders.loading
        },
        workOrdersData(){
            return this.$store.state.applicants.workorders
        },
        workOrdersLoading(){
            return this.$store.state.applicants.workordersLoading
        }
    },
    methods:{
        initWorkOrder(){
            this.newWorkOrder = {
                workorder_users:this.users,
                position_id:null,
                start_date: new Date(),
                regular_pay_rate:null,
                overtime_pay_rate:null,
                doubletime_pay_rate:null,
                payroll_hourly_calculator:null,
                regular_bill_rate:null,
                overtime_bill_rate:null,
                doubletime_bill_rate:null,
                invoice_hourly_calculator:null
            };
        },
        applicantsAddToWorkOrder(){             
            let data ={
                applicantsIds:this.users,
                workorder_id: this.workOrderId
            };
            this.$store.dispatch('applicants/applicantsAddToWorkorder', data);
            this.step++;
        },
        submit(){  
            let data ={
                id: this.workOrdersData.workorder.id,
                newWorkOrder: this.newWorkOrder
            }
            this.$store.dispatch('applicants/addWorkOrderUsersToWorkOrder', data);
            this.isShowLocal = false;
        },
        closeModal(){
            this.$emit('closeModal');
        },
        getWorkOrders(){
            this.$store.dispatch('workOrders/getList', this.workOrderParams);
        },

        scrollWorkers({ target: { scrollTop, clientHeight, scrollHeight }}){
            if (scrollTop + clientHeight >= scrollHeight && this.rolesTotal != Object.keys(this.workOrders).length && !this.loading) {
                this.workOrderParams.resetList = false;
                this.workOrderParams.page++;
                this.getWorkOrders();
            }
        },
        changePosition(id){
            if(id == null)
                this.initWorkOrder();
            else{
                let position = this.workOrdersData.positions.find(e => e.id == id);
                this.newWorkOrder.regular_pay_rate = position.regular_pay_rate;
                this.newWorkOrder.overtime_pay_rate = position.overtime_pay_rate;
                this.newWorkOrder.doubletime_pay_rate = position.doubletime_pay_rate;
                this.newWorkOrder.payroll_hourly_calculator = position.payroll_hourly_calculator;
                this.newWorkOrder.regular_bill_rate = position.regular_bill_rate;
                this.newWorkOrder.overtime_bill_rate = position.overtime_bill_rate;
                this.newWorkOrder.doubletime_bill_rate = position.doubletime_bill_rate;
                this.newWorkOrder.invoice_hourly_calculator = position.invoice_hourly_calculator;
            }
        }
    }
}
</script>
<style scoped>
</style>