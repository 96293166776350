<template>
    <div class="document">
        <div class="title">{{document.title != null? document.title : 'Title'}}</div>
        <div class="ml-3">{{document.type}}</div>
        <div class="url ml-3" v-on:click="openDocument(document.url)"><img src="@/assets/icons/paperclip.svg" class="mr-1"/>{{document.name}}</div>
    </div>
</template>

<script>
export default {
    name: "WorkExperience",
    props: {
        document:Object
    },
    methods: {
        openDocument(value){
            window.open(value);
        }
    },
};
</script>
<style  scoped>

</style>