var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login_main"},[_c('b-card',{staticClass:"login_card"},[(!this.$store.state.auth.unlogged.status)?_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"user_icons_box"},[_c('b-icon',{attrs:{"icon":"person","scale":"1"}})],1),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required","vid":"new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-input',{class:[{ 'has-danger': failed }, 'input-login'],attrs:{"type":"password","placeholder":"Password","required":""},model:{value:(_vm.user.new_password),callback:function ($$v) {_vm.$set(_vm.user, "new_password", $$v)},expression:"user.new_password"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password confirmation","rules":"required|confirmed:new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-input',{class:[{ 'has-danger': failed }, 'input-login'],attrs:{"type":"password","placeholder":"Repeat password","required":""},model:{value:(_vm.user.new_password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "new_password_confirmation", $$v)},expression:"user.new_password_confirmation"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-button',{staticClass:"login_btn",attrs:{"type":"submit","variant":"primary","disabled":_vm.loading}},[(_vm.loading)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_vm._v(" Reset password ")],1)],1)]}}],null,false,2699371)})],1):_c('div',[_c('h4',[_vm._v(_vm._s(this.$store.state.auth.unlogged.message))]),_c('go-to-login-page')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }