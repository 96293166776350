const MIN_DATE_CLOCK_IN = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);  // day a week ago
const MAX_DATE_CLOCK_IN = new Date(new Date().getTime());                            // today
const MIN_DATE_CLOCK_OUT = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000); // day a week ago
const MAX_DATE_CLOCK_OUT = new Date(new Date().getTime());                           // today

export default {
    MIN_DATE_CLOCK_IN: MIN_DATE_CLOCK_IN,
    MAX_DATE_CLOCK_IN: MAX_DATE_CLOCK_IN,
    MIN_DATE_CLOCK_OUT: MIN_DATE_CLOCK_OUT,
    MAX_DATE_CLOCK_OUT: MAX_DATE_CLOCK_OUT,
}