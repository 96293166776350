<template>
  <div>
    <b-row class="justify-content-md-center mb-3">
      <b-col class="bread-crumbs"><span class="redirect" v-on:click="redirect('Dashboard')">Dashboard</span><span class="arrow">></span><span class="active">Create Work order</span></b-col>
    </b-row>
    <steps
      class="steps"
      :currentStep="currentStep"
      :stepsCount="6"
      :title="getTitle()"
        
    />
      <initial-information
          v-if="currentStep == 1 && workOrder != null" 
          type="add"
          @next="nextStep"
      />
      <job-information
          v-if="currentStep == 2 && workOrder != null"
          type="add"
          @next="saveWorkOrder"
          @previous="previousJobInformation"
      />
      <positions-tab
          v-if="currentStep == 3 && positions != null"
          type="add" 
          @next="savePositions"
          @previous="currentStep--"
      />
      <contact
          v-if="currentStep == 4 && job_contact != null"
          type="add"
          @next="saveContact"
          @previous="currentStep--"
      />
      <billing-information
          v-if="currentStep == 5 && billing != null"
          type="add"
          @next="saveBilling"
          @previous="currentStep--"
      />
      <files
          v-if="currentStep == 6 && files != null"
          type="add"
          @next="saveFiles"
          @previous="currentStep--"
      />
  </div>
</template>

<script>
import Steps from '../components/common/Steps.vue';
import JobInformation from '../components/workOrders/createAndEdit/JobInformation.vue';
import InitialInformation from '../components/workOrders/createAndEdit/InitialInformation.vue';
import PositionsTab from '../components/workOrders/createAndEdit/PositionsTab.vue';
import Contact from '../components/workOrders/createAndEdit/Contact.vue';
import BillingInformation from '../components/workOrders/createAndEdit/BillingInformation.vue';
import Files from '../components/workOrders/createAndEdit/Files.vue';

export default {
  name: "CreateWorkOrder",
  components: {
    Steps,
    InitialInformation,
    JobInformation,
    PositionsTab,
    Contact,
    BillingInformation,
    Files
  },
  props:{
    type:String
  },
  data() {
    return {
        currentStep:1
    };
  },
  //Initialization of objects for creating work orders
  mounted(){
    this.$store.state.workOrders.newWorkOrder = {
      title:null,
      company_id:null,
      branch_id:null,
      status:null,
      job_location:{
        address:null,
        state:null,
        city:null,
        zipcode:null
      },
      start_date:null,
      end_date:null,
      start_time:null,
      end_time:null,
      number_of_workers:null,
      job_info:{
        job_category: null,  
        job_description: null
      }
    }

    this.$store.state.workOrders.positions = [
      {
        title: null,
        people_needed: null,
        regular_pay_rate: null,
        overtime_pay_rate: null,
        doubletime_pay_rate: null,
        payroll_hourly_calculator: null,
        regular_bill_rate: null,
        doubletime_bill_rate: null,
        overtime_bill_rate: null,
        invoice_hourly_calculator: null,
        description: null,
        dept_code: null,
        comp_code: null,
        skills:[]
      }
    ];

    this.$store.state.workOrders.job_contact = {
      first_name:null,
      last_name:null,
      site_company:null,
      email:null,
      phone_number:null,
    };

    this.$store.state.workOrders.billing = {
      contact_person:null,
      po_number:null,
      email:null,
      phone_number:null,
      address:null,
      invoice_notes:null,
    };

    this.$store.state.workOrders.files = [];
  },
  created(){
    this.$store.dispatch('workOrders/getParamsForStore');
  },
  computed:{
    workOrder(){
      return this.$store.state.workOrders.newWorkOrder;
    },
    positions(){
      return this.$store.state.workOrders.positions;
    },
    job_contact(){
      return this.$store.state.workOrders.job_contact;
    },
    billing(){
      return this.$store.state.workOrders.billing;
    },
    files(){
      return this.$store.state.workOrders.files;
    },
  },
  methods: {
    getTitle(){
      switch (this.currentStep) {
        case 1:
          return 'Initial info';
        case 2:
          return 'Job info';            
        case 3:
          return 'Position(s) info';            
        case 4:
          return 'Contact person info';           
        case 5:
          return 'Billing info';          
        case 6:
          return 'attachments';
        default:
          return;
      }
    },
    redirect(name){            
      this.$router.push({ name: name });
    },
    saveWorkOrder(){
      this.$store.dispatch('workOrders/newWorkOrder', this.$store.state.workOrders.newWorkOrder);
      this.nextStep();
    },
    savePositions(){
      this.$store.dispatch('workOrders/createManyPosition', {
        id:this.$store.state.workOrders.workOrderId,
        positions:this.$store.state.workOrders.positions}
      );
      this.nextStep();
    },
    saveContact(){
      this.$store.dispatch('workOrders/contactUpdate', {
        id:this.$store.state.workOrders.workOrderId,
        contact:this.$store.state.workOrders.job_contact}
      );
      this.nextStep();
    },
    saveBilling(){
      this.$store.dispatch('workOrders/billingUpdate', {
        id:this.$store.state.workOrders.workOrderId,
        billing:this.$store.state.workOrders.billing}
      );
      this.nextStep();
    },
    saveFiles(){
      this.$store.dispatch('workOrders/filesUpdate', {
        id:this.$store.state.workOrders.workOrderId,
        files:this.$store.state.workOrders.files}
      );      
      this.$router.push({ name: 'WorkOrders' });
    },
    nextStep(){
      this.currentStep++;
    },
    previousJobInformation(){
      this.currentStep--;
    }
  },
};
</script>
<style lang="scss" scoped>
.steps{
    padding: 40px 0px;
    max-width: 750px;
    margin: auto;
}
</style>