<template>
  <div class="login_main">
    <b-card class="login_card">
      <h3>Forgot your password?</h3>
      <div v-if="!this.$store.state.auth.unlogged.status">
      <div>Enter your email address to receive a secured link that will allow you to reset your password.</div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <ValidationProvider name="Email" rules="required|email" v-slot="{ failed, errors ,}" mode="passive">
            <b-form-input type="text" placeholder="Email" v-model="user.email" required :class="[{ 'has-danger': failed }, 'input-login']" name="email"></b-form-input>
            <div  class="has-danger-error">{{ errors[0]}}</div>
          </ValidationProvider>
          <b-button type="submit" variant="primary" class="login_btn" :disabled="loading">
            <b-spinner small v-if="loading" class="mr-1"></b-spinner>
            Submit
          </b-button>
        </form>
      </ValidationObserver>
      </div>
      <div v-else>
        {{this.$store.state.auth.unlogged.message}}
      </div>
    </b-card>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import '../../../assets/css/login.scss';
extend('email', email);
extend('required', required);

export default {
  name: "ForgotPassword",
  data() {
    return {
      user:{
        email: ""
      }
    };
  },
  computed:{

    loading() {
      return this.$store.state.auth.loadingCurrentUser;
    },
  },
  methods: {
    submit() {
      this.$store.dispatch('auth/forgotPass', this.user);
    },
  }
}
</script>

<style scoped>

</style>