var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"justify-content-md-center position"},[_c('b-col',[_vm._v("Position ("+_vm._s(_vm.index)+")")]),_c('b-col',{class:[_vm.isShow? '':'rotate-img','col-auto'],on:{"click":function($event){_vm.isShow = !_vm.isShow}}},[_c('img',{staticClass:"mr-2 mt-n1",attrs:{"src":require("@/assets/icons/down.svg")}})]),(_vm.length>1)?_c('b-col',{staticClass:"col-auto delete",on:{"click":_vm.deletePosition}},[_c('img',{staticClass:"mr-2 mt-n1",attrs:{"src":require("@/assets/icons/delete.svg")}})]):_vm._e()],1),(_vm.isShow)?_c('div',[_c('div',{staticClass:"title"},[_vm._v("General Position Info")]),_c('ValidationProvider',{attrs:{"name":"Title of position","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Title of position"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter title of position","required":""},model:{value:(_vm.position.title),callback:function ($$v) {_vm.$set(_vm.position, "title", $$v)},expression:"position.title"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3488528947)}),_c('ValidationProvider',{attrs:{"name":"People needed","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("People needed"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"number","placeholder":"Enter title of position","required":""},model:{value:(_vm.position.people_needed),callback:function ($$v) {_vm.$set(_vm.position, "people_needed", $$v)},expression:"position.people_needed"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2898687714)}),_c('ValidationProvider',{attrs:{"name":"Skills","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Skills"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('el-select',{class:[{ 'has-danger': failed }],attrs:{"multiple":"","placeholder":"Select skills"},model:{value:(_vm.position.skills),callback:function ($$v) {_vm.$set(_vm.position, "skills", $$v)},expression:"position.skills"}},_vm._l((_vm.params.skills),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":+index}})}),1),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3718385617)}),_c('ValidationProvider',{attrs:{"name":"Job Description","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Job Description"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-textarea',{class:[{ 'has-danger': failed }],attrs:{"placeholder":"Write job description","rows":"5"},model:{value:(_vm.position.description),callback:function ($$v) {_vm.$set(_vm.position, "description", $$v)},expression:"position.description"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,889000394)}),_c('div',{staticClass:"title"},[_vm._v("Additional Information")]),_c('ValidationProvider',{attrs:{"name":"Dept code","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Dept code"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter Dept code","required":""},model:{value:(_vm.position.dept_code),callback:function ($$v) {_vm.$set(_vm.position, "dept_code", $$v)},expression:"position.dept_code"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1675940900)}),_c('ValidationProvider',{attrs:{"name":"Workers Comp code","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Workers Comp code"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter workers comp code","required":""},model:{value:(_vm.position.comp_code),callback:function ($$v) {_vm.$set(_vm.position, "comp_code", $$v)},expression:"position.comp_code"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,155231984)}),_c('div',{staticClass:"title"},[_vm._v("Pay Rate")]),_c('ValidationProvider',{attrs:{"name":"Regular hourly rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Payroll regular hourly rate"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"number","placeholder":"Enter regular hourly rate","required":""},model:{value:(_vm.position.regular_pay_rate),callback:function ($$v) {_vm.$set(_vm.position, "regular_pay_rate", $$v)},expression:"position.regular_pay_rate"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,15706184)}),_c('ValidationProvider',{attrs:{"name":"Payroll overtime hourly rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Payroll overtime hourly rate"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"number","placeholder":"Enter payroll overtime hourly rate","required":""},model:{value:(_vm.position.overtime_pay_rate),callback:function ($$v) {_vm.$set(_vm.position, "overtime_pay_rate", $$v)},expression:"position.overtime_pay_rate"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3718692636)}),_c('ValidationProvider',{attrs:{"name":"Payroll doubletime hourly rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Payroll doubletime hourly rate"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"number","placeholder":"Enter payroll doubletime hourly rate","required":""},model:{value:(_vm.position.doubletime_pay_rate),callback:function ($$v) {_vm.$set(_vm.position, "doubletime_pay_rate", $$v)},expression:"position.doubletime_pay_rate"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,110054663)}),_c('ValidationProvider',{attrs:{"name":"Payroll hourly calculator","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Payroll hourly calculator"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-select',{class:[{ 'has-danger': failed }],scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{staticClass:"placeholder",attrs:{"value":null}},[_vm._v("Select payroll hourly calculator")])]},proxy:true}],null,true),model:{value:(_vm.position.payroll_hourly_calculator),callback:function ($$v) {_vm.$set(_vm.position, "payroll_hourly_calculator", $$v)},expression:"position.payroll_hourly_calculator"}},_vm._l((_vm.params.calculators),function(option,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1165663560)}),_c('div',{staticClass:"title"},[_vm._v("Bill Rate")]),_c('ValidationProvider',{attrs:{"name":"Billing regular hourly rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Billing regular hourly rate"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"number","placeholder":"Enter billing regular hourly rate","required":""},model:{value:(_vm.position.regular_bill_rate),callback:function ($$v) {_vm.$set(_vm.position, "regular_bill_rate", $$v)},expression:"position.regular_bill_rate"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,549535518)}),_c('ValidationProvider',{attrs:{"name":"Billing overtime hourly rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Billing overtime hourly rate"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"number","placeholder":"Enter billing overtime hourly rate","required":""},model:{value:(_vm.position.overtime_bill_rate),callback:function ($$v) {_vm.$set(_vm.position, "overtime_bill_rate", $$v)},expression:"position.overtime_bill_rate"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3277832959)}),_c('ValidationProvider',{attrs:{"name":"Billing doubletime hourly rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Billing doubletime hourly rate"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"number","placeholder":"Enter billing doubletime hourly rate","required":""},model:{value:(_vm.position.doubletime_bill_rate),callback:function ($$v) {_vm.$set(_vm.position, "doubletime_bill_rate", $$v)},expression:"position.doubletime_bill_rate"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2472089924)}),_c('ValidationProvider',{attrs:{"name":"Invoice hourly calculator","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Invoice hourly calculator"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-select',{class:[{ 'has-danger': failed }],scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{staticClass:"placeholder",attrs:{"value":null}},[_vm._v("Select invoice hourly calculator")])]},proxy:true}],null,true),model:{value:(_vm.position.invoice_hourly_calculator),callback:function ($$v) {_vm.$set(_vm.position, "invoice_hourly_calculator", $$v)},expression:"position.invoice_hourly_calculator"}},_vm._l((_vm.params.calculators),function(option,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3052754156)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }