var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{class:[_vm.type == 'edit' ? 'edit-tab' : '','create-or-edit-tab']},[(_vm.type == 'add')?_c('div',{staticClass:"head-title mb-4"},[_vm._v("Who can you contact?")]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Contact person first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Contact person first name"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter contact person first name","required":""},model:{value:(_vm.jobContact.first_name),callback:function ($$v) {_vm.$set(_vm.jobContact, "first_name", $$v)},expression:"jobContact.first_name"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Contact Person Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Contact Person Last Name"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter contact Person Last Name","required":""},model:{value:(_vm.jobContact.last_name),callback:function ($$v) {_vm.$set(_vm.jobContact, "last_name", $$v)},expression:"jobContact.last_name"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('label',{staticClass:"label"},[_vm._v("Site company")]),_c('b-form-input',{attrs:{"type":"text","placeholder":"Enter site company"},model:{value:(_vm.jobContact.site_company),callback:function ($$v) {_vm.$set(_vm.jobContact, "site_company", $$v)},expression:"jobContact.site_company"}}),_c('ValidationProvider',{attrs:{"name":"Contact Person Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Contact Person Email"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter contact person email","required":""},model:{value:(_vm.jobContact.email),callback:function ($$v) {_vm.$set(_vm.jobContact, "email", $$v)},expression:"jobContact.email"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Contact Person Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Contact Person Phone"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('vue-phone-number-input',_vm._b({staticClass:"person-phone",class:[{ 'has-danger': failed }],attrs:{"required":""},model:{value:(_vm.jobContact.phone_number),callback:function ($$v) {_vm.$set(_vm.jobContact, "phone_number", $$v)},expression:"jobContact.phone_number"}},'vue-phone-number-input',_vm.vuePhone.props,false)),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{class:_vm.type == 'add'? 'bottom-btns' : 'mt-3'},[_c('b-row',[_c('b-col',{class:_vm.type == 'add' ? 'text-center' : 'pl-0'},[_c('span',{staticClass:"cancel",on:{"click":_vm.cancel}},[_vm._v("Cancel")])]),_c('b-col',{class:_vm.type == 'add' ? 'text-center' : 'text-end pr-0'},[_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"variant":"outlined"},on:{"click":_vm.previous}},[_vm._v("Previous")])],1),_c('b-col',[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Next")])],1)],1)],1)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }