<template>
  <div>
    <b-row class="justify-content-md-center mb-3">
      <b-col class="bread-crumbs">
        <span class="redirect" v-on:click="redirect('Dashboard')">Dashboard</span>
        <span class="arrow">></span>
        <span class="redirect" v-on:click="redirect('Applicants')">Applicants</span>
        <span class="arrow">></span>
        <span class="active">{{user.first_name}} {{user.last_name}}</span>
      </b-col>
    </b-row>
    <details-user type="applicants" @addApplicant="isShowAddNewApplicant = true" @editApplicant="isShowEditNewApplicant = true"/>
    <add-and-edit-user type="add"  :isShow="isShowAddNewApplicant" @closeModal="isShowAddNewApplicant = false" />
    <add-and-edit-user type="edit" :editUser="user.applicant" :isShow="isShowEditNewApplicant" @closeModal="isShowEditNewApplicant = false" />
  </div>
</template>

<script>
import DetailsUser from '../../components/common/DetailsUser.vue';
import store from '../../store/index';
import AddAndEditUser from "@/components/applicants/AddAndEditUser.vue";
async function getUser(id) {
    store.dispatch('applicants/details',id);
}
async function getCurrentUser() {
    store.dispatch('applicants/currentUserDetails');
}
export default {
    name: "ApplicantDetail",
    async beforeRouteEnter(to, from, next) {
      if(to.name == 'ApplicantDetails')
        getCurrentUser();
      else
        getUser(to.params.id);
      next();
    },
    async beforeRouteUpdate(to, from, next) {
      if(to.name == 'ApplicantDetails')
        getCurrentUser();
      else
        getUser(to.params.id);
      next();
    },
    components: { DetailsUser,AddAndEditUser},
    data() {
      return {
        isShowAddNewApplicant: false,
        isShowEditNewApplicant: false
      };
    },
    computed: {
        user() {
          return this.$store.state.applicants.details;
        },
    },
    methods: {
      redirect(name){            
        this.$router.push({ name: name });
      }
    },
};
</script>
<style  scoped>
</style>