<template>
  <div class="tab">
    <div class="head-title">List Of TimeSheets</div>
    <div>{{listOfTimeSheets}}</div>    
  </div>
</template>

<script>
export default {
    name: "ListOfTimeSheets",
    props:{
        userId:Number
    },
    watch: {
        userId(id) {
            if(id != undefined)
                this.getListOfTimeSheets();
        }
    },
    data() {
        return {
        };
    },
    computed: {
        listOfTimeSheets() {
          return this.$store.state.workers.listOfTimeSheets;
        },
        loading(){
          return this.$store.state.workers.loadingListOfTimeSheets;
        }
    },
    methods: {
      getListOfTimeSheets() {
        this.$store.dispatch('workers/listOfTimeSheets',this.userId);
      }
    },
};
</script>
<style  scoped>

</style>