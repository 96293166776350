<template>
    <div class="details">
        <div v-if="loading" class="mt-5 pt-5">
            <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
            </div>
        </div>
        <div v-else-if="isShowCreate" class="add-info">
            <div class="text">Information about you will be displayed here, at the moment it is not yet filled. But you can add information right now.</div>
            <!-- <b-button variant="primary" v-on:click="$emit('addApplicant')">Add info</b-button> -->
            <b-button variant="primary" >Add info</b-button>
        </div>
        <div v-else>
            <b-row  class="justify-content-md-start tags mb-4">
                <b-col class="title col-auto p-0 pt-2">{{user.first_name}} {{user.last_name}}</b-col>

                <b-col v-if="isWorkersOrEmployees" class="text-end"><b-button variant="primary" v-on:click="$emit('editApplicant')">Edit</b-button></b-col>
                <b-col v-if="false" class="col-auto"><b-button variant="primary" >Actions</b-button></b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="mb-1">
                        <span class="label">Adsress:</span>
                        <span v-if=" user.address == null || user.address.address == null " class="not_chosen">Not chosen</span>
                        <span v-else>{{ user.address.address}}</span>
                    </div>
                    <div class="mb-1">
                        <span class="label">Email:</span>
                        <span v-if="user.email == null" class="not_chosen">Not chosen</span>
                        <span v-else class="color-blue">{{user.email}}</span>
                    </div>
                    <div class="mb-1">
                        <span class="label">Phone:</span>
                        <span v-if="user.phone == null" class="not_chosen">Not chosen</span>
                        <span v-else class="color-blue">{{user.phone}}</span>
                    </div>
                    <div class="mb-1">
                        <span class="label">Job Category:</span>                        
                        <span v-if="user.job_category == null || user.job_category.length == 0" class="not_chosen">Not chosen</span>
                        <span v-else>{{user.job_category}}</span>
                    </div>
                    <div>
                        <span class="label">Job Specialty:</span>
                        <span v-if="user.job_speciality == null || user.job_category.length == 0" class="not_chosen">Not chosen</span>
                        <span v-else>{{user.job_speciality}}</span>
                    </div>
                </b-col>
                <b-col>
                    <div class="mb-1"><span class="label">Job Search status:</span>
                        <span v-if="user.job_search_status == null" class="not_chosen">Not chosen</span>
                        <span v-else
                            v-bind:class="user.job_search_status == 'Actively looking' ? 'looking' : (user.job_search_status == 'Interested for new work opportunities' ? 'interested' : 'not-looking')">
                                {{user.job_search_status}}
                        </span>
                    </div>
                    <div class="mb-1">
                        <span class="label">Project preference:</span>
                        <span v-if="user.project_preference == null" class="not_chosen">Not chosen</span>
                        <span v-else>{{user.project_preference}}</span>
                    </div>
                    <div class="mb-1">
                        <span class="label">Hours per week:</span>
                        <span v-if="user.hours_per_week == null" class="not_chosen">Not chosen</span>
                        <span>{{user.hours_per_week}}</span>
                    </div>
                    <div class="mb-1">
                        <span class="label">Hourly Rate:</span>
                        <span v-if="user.hourly_rate == undefined || user.hourly_rate == null || (user.hourly_rate.max == null  || user.hourly_rate.min == null )" class="not_chosen">Not chosen</span>
                        <span v-else>${{user.hourly_rate.min}} - ${{user.hourly_rate.max}}</span>
                    </div>
                    <div>
                        <span class="label">CV:</span>
                        <span v-if="user.certificate == undefined || user.certificate == null" class="not_chosen">Not chosen</span>
                        <span v-else>
                            <span v-on:click="openDocument(user.certificate.url)" class="cursor-pointer"><img src="@/assets/icons/paperclip.svg" class="mr-1"/>{{user.certificate.title}}</span>
                            <span class="url-upd"> (UPD: Dec 29, 2020)</span>
                        </span>
                    </div>
                </b-col>
                <b-col v-if="type == 'applicants' && user.current_stage != null " class="current-stage">
                    <div><span class="title">Current Stage:</span> {{user.current_stage}}</div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col v-if="user.skills != undefined && user.skills != null && Object.keys(user.skills).length>0">
                    <div>Skills</div>
                    <b-row class="justify-content-md-start tags">
                        <b-col 
                            v-for="(item, index) in user.skills" 
                            v-bind:key="index"
                            class="col-auto required tag">{{item}}</b-col>
                    </b-row>
                </b-col>
                <b-col v-if="user.skills != undefined && user.skills != null && Object.keys(user.tools).length>0">
                    <div>Tools</div>
                    <b-row class="justify-content-md-start tags">
                        <b-col 
                            v-for="(item, index) in user.tools" 
                            v-bind:key="index"
                            class="col-auto required tag">{{item}}</b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-tabs content-class="mt-3">
                <b-tab title="Basic Information" active>
                    <basic-information :type="type"/>
                </b-tab>
                <!-- <b-tab title="Activity">
                    <activity
                        :user="user"
                        :type="type"/>
                </b-tab>
                <b-tab v-if="type == 'applicants'" title="Applicant stage">
                    <applicant-stage
                        :user="user"/>
                </b-tab>
                <b-tab v-if="type == 'workers'"  title="Employment History">
                    <employment-history 
                        :userId="user.id"/>
                </b-tab>
                <b-tab v-if="type == 'workers'"  title="Work History">
                    <table-and-filters
                        type="workers-work-history"
                    />
                </b-tab>
                <b-tab v-if="false"  title="List of TimeSheets">
                    <list-of-time-sheets
                        :userId="user.id"/>
                </b-tab>
                <b-tab title="Reviews">
                    <reviews
                        :user="user"
                        :type="type"/>
                </b-tab> -->
            </b-tabs>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import Activity from '../detailsTabs/Activity.vue';
import ApplicantStage from '../detailsTabs/ApplicantStage.vue';
import BasicInformation from '../detailsTabs/BasicInformation.vue';
import Reviews from '../detailsTabs/Reviews.vue';
import ListOfTimeSheets from '../detailsTabs/ListOfTimeSheets.vue';
import EmploymentHistory from '../detailsTabs/EmploymentHistory.vue';
import TableAndFilters from './TableAndFilters.vue';
export default {
    name: "DetailsUser",
    components: {
        BasicInformation,
        Activity,
        Reviews,
        ApplicantStage,
        ListOfTimeSheets,
        EmploymentHistory,
        TableAndFilters
    },
    props:{
        type:String
    },
    created(){
        if(!this.isWorkersOrEmployees){
        this.$store.dispatch(this.type + '/basicInfo',this.$route.params.id);
        this.$store.dispatch(this.type + '/reviews',this.$route.params.id);
        }
    },
    computed: {
        user() {
          return this.isWorkersOrEmployees ? this.$store.state[this.type].details.applicant : this.$store.state[this.type].details;
        },
        loading(){
            return this.$store.state[this.type].loadingDetails;
        },
        isWorkersOrEmployees (){
            if(this.$route.name == 'ApplicantDetails')
                return true;
            return false;
        },
        isShowCreate(){
            if(this.isWorkersOrEmployees && this.$store.state[this.type].details.length == 0)
                return true;
            return false;
        }
    },
    methods: {
        openDocument(value){
            window.open(value);
        },
        getDate(){
            return moment(this.user.certificate.updated_at).format('ll');
        }
    },
};
</script>
<style  scoped>
.tags{
    margin-left: 1px;
    margin-top: 10px;
}
</style>