<template>
    <div class="education">
        <div class="title">{{education.diploma_title}}</div>
        <div class="ml-3">{{education.type_of_degree}} &#8226; {{education.name_of_school}}</div>
        <div class="date mt-2 ml-3">{{education.start_attended}} - {{education.end_attended}}</div>
    </div>
</template>

<script>
export default {
    name: "WorkExperience",
    props: {
        education:Object
    },
    methods: {
    },
};
</script>
<style  scoped>

</style>