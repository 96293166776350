<template>
  <div class="tab">
    <div class="head-title">Applicant stage</div>
    <div>{{applicantStage}}</div>    
  </div>
</template>

<script>
export default {
    name: "ApplicantStage",
    props:{
        user:Object,
    },
    watch: {
        userId(id) {
            if(id != undefined)
                this.getApplicantStage();
        }
    },
    data() {
        return {
        };
    },
    computed: {
        applicantStage() {
          return this.$store.state.applicants.applicantStage;
        },
        loading(){
          return this.$store.state.applicants.loadingApplicantStage;
        },
        userId(){
            if(this.user.id != undefined)
                this.getApplicantStage();
            return this.user.id
        }
    },
    methods: {
      getApplicantStage() {
        this.$store.dispatch('applicants/applicantStage',this.user.id);
      }
    },
};
</script>
<style  scoped>

</style>