<template>
    <div>
        <b-row class="mt-5">
            <b-col class="col-auto"><h5 ref="recordedHoursTitle" id="recordedHoursTitle">Recorded hours</h5></b-col>
            <b-col class="text-start mt-1 add-day"><span class="add" v-on:click="addDay()">Add day</span></b-col>
        </b-row>
        <!-- All days -->
        <div v-for="(day, index) in days" :key="index">
            <one-day :index="index" @removeDay="removeDay" @isShowCheckData="isShowCheckData = true"/>                       
        </div>
        <check-on-filled-data-for-the-selected-date :isShow="isShowCheckData" @close="isShowCheckData = false" />
    </div>
</template>

<script>
import moment from 'moment';
import helpers from '../../assets/const/helpersTimeSheet';
import CheckOnFilledDataForTheSelectedDate from './CheckOnFilledDataForTheSelectedDate.vue';
import OneDay from './OneDay.vue';
export default {
  components: { CheckOnFilledDataForTheSelectedDate, OneDay },
    name: "RecordedHours",
    data() {
        return {
            isShowCheckData:false,
        };
    },
    computed: {
        days(){
            return this.$store.state.timeSheet.days;
        },
        isUpdate(){
            return this.$store.state.timeSheet.isUpdate;
        }
    },
    methods: {
        addDay(){
            this.days.push({
                errors:[],
                breaks:[],
                dateStart:null,
                dateEnd:null,
                timeStart: null,
                timeEnd:null,
            });
        },
        removeDay(index){
            if(this.isUpdate && this.days[index].timesheet_id !== undefined && this.days[index].timesheet_id !== null){
                this.$store.state.timeSheet.dataToDelete.days.push(this.days[index].timesheet_id)
                for(let i = 0; i < this.days[index].breaks.length; i++){
                    if(this.days[index].breaks[i].id !== undefined && this.days[index].breaks[i].id !== null)
                        this.$store.state.timeSheet.dataToDelete.breaks.push(this.days[index].breaks[i].id)
                }
            }
            this.days.splice(index, 1);
        }
    }          
    
}
</script>