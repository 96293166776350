const applicantHeaders = [
    { key: 'name', label: 'Name', value: false, stickyColumn: true, sortable: true },
    { key: 'positionApplied', label: 'Position applied', value: false, sortable: true },
    { key: 'applied', label: 'Applied', value: false, sortable: true },
    { key: 'contacts', label: 'Contacts', value: false, sortable: true },
    { key: 'resumeUpdateOn', label: 'Resume, Update on', value: false, sortable: true },
    { key: 'skills', label: 'Skills', value: false, sortable: false },
    { key: 'stage', label: 'Stage', value: false, sortable: true },
    { key: 'address', label: 'Address', value: false, sortable: true },
    { key: 'recruiter', label: 'Recruiter', value: false, sortable: true },
    { key: 'firstReview', label: '1st Review', value: false, sortable: false },
    { key: 'mainReview', label: 'Main Review', value: false, sortable: false },
    { key: 'industry', label: 'Industry', value: false, sortable: false },
    { key: 'experience', label: 'Experience', value: false, sortable: false },
    { key: 'car', label: 'Car', value: false, sortable: true },
];

const workOrdersHeaders = [ 
    { key: 'name', label: 'Name', sortable: true, stickyColumn: true, value: false  },
    { key: 'airtable_project_id', label: 'Work Order #', sortable: true, value: false  },
    { key: 'company', label: 'Company', sortable: true, value: false  },
    { key: 'workers', label: 'Workers', sortable: true, value: false  },
    { key: 'start_date', label: 'Start Date', sortable: true, value: false  },
    { key: 'end_date', label: 'End Date', sortable: true, value: false  },
    { key: 'status', label: 'Status', sortable: true, value: false  }
];

const workersHeaders = [ 
    { key: 'name', label: 'Name', sortable: true, stickyColumn: true, value: false },
    { key: 'category', label: 'Category', sortable: true, value: false },
    { key: 'speciality', label: 'Speciality', sortable: true, value: false },
    { key: 'contacts', label: 'Contacts', sortable: true, value: false },
    { key: 'ssn', label: 'SSN', sortable: true, value: false },
    { key: 'all_stats', label: 'All stats', sortable: false, value: false },
    { key: 'status', label: 'Status', sortable: false, value: false }
];

const workersWorkHistoryHeaders = [ 
    { key: 'date', label: 'Date', sortable: true, stickyColumn: true, value: false },
    { key: 'project', label: 'Project', sortable: true, value: false },
    { key: 'position', label: 'Position', sortable: true, value: false },
    { key: 'wokedHours', label: 'Woked Hours', sortable: true,  },
];

const timeSheetHeaders = [ 
    { key: 'dates', label: 'Dates', sortable: true, value: false  },
    { key: 'company', label: 'Company', sortable: true, value: false },
    { key: 'user', label: 'User', sortable: true, value: false  },
    { key: 'verified', label: 'Verified', sortable: true , value: false },
    { key: 'notes', label: 'Notes', sortable: true, value: false  },
    { key: 'updated_at', label: 'Last update at', sortable: true, value: false  }
];
export { applicantHeaders };
export { workOrdersHeaders };
export { workersHeaders };
export {workersWorkHistoryHeaders}; 
export {timeSheetHeaders}; 