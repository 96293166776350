<template>
  <div>
    <b-row class="justify-content-md-center mb-3">
      <b-col class="bread-crumbs"><span class="redirect" v-on:click="redirect('Dashboard')">Dashboard</span><span class="arrow">></span><span class="active">Applicants</span></b-col>
      <b-col class="col-auto"><b-button variant="primary" v-on:click="isShowAddNewApplicant = true">Add new applicant</b-button></b-col>
    </b-row>
    <table-and-filters
      v-if="isTable"
      type="applicants"
      @changeView="changeView"
    />
    <list-and-filters v-else type="applicants" @changeView="changeView" />

    <add-and-edit-user type="add" :isShow="isShowAddNewApplicant" @closeModal="closeModal" />
  </div>
</template>

<script>
import TableAndFilters from "../components/common/TableAndFilters.vue";
import ListAndFilters from "../components/common/ListAndFilters.vue";
import AddAndEditUser from "../components/applicants/AddAndEditUser.vue";

export default {
  name: "Aplicants",
  components: {
    TableAndFilters,
    ListAndFilters,
    AddAndEditUser,
  },
  data() {
    return {
      isTable: true,
      isShowAddNewApplicant: false,
    };
  },
  methods: {
    changeView(value) {
      this.isTable = value;
    },
    closeModal() {
      this.isShowAddNewApplicant = false;
    },
    redirect(name){            
      this.$router.push({ name: name });
    }
  },
};
</script>
<style  scoped>
</style>