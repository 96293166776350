<template>
    <div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <div v-bind:class="[type == 'edit' ? 'edit-tab' : '','create-or-edit-tab']">
                    <div v-if="type == 'add'"  class="head-title mb-4">Who can you contact?</div>
                    <ValidationProvider
                        name="Contact person first name"
                        rules="required"
                        v-slot="{ failed, errors }">
                            <label class="label">Contact person first name<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter contact person first name"
                                v-model="jobContact.first_name"
                                required
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Contact Person Last Name"
                        rules="required"
                        v-slot="{ failed, errors }">
                            <label class="label">Contact Person Last Name<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter contact Person Last Name"
                                v-model="jobContact.last_name"
                                required
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                        <label class="label">Site company</label>
                        <b-form-input
                            type="text"
                            placeholder="Enter site company"
                            v-model="jobContact.site_company"
                        ></b-form-input> 
                    <ValidationProvider
                        name="Contact Person Email"
                        rules="required|email"
                        v-slot="{ failed, errors }">
                            <label class="label">Contact Person Email<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter contact person email"
                                v-model="jobContact.email"
                                required
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Contact Person Phone"
                        rules="required"
                        v-slot="{ failed, errors }">
                            <label class="label">Contact Person Phone<span class="star">*</span></label>
                            <vue-phone-number-input
                                v-model="jobContact.phone_number"
                                v-bind="vuePhone.props"
                                class="person-phone"
                                required
                                :class="[{ 'has-danger': failed }]"
                            ></vue-phone-number-input>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                </div>
                <div v-bind:class="type == 'add'? 'bottom-btns' : 'mt-3'">
                    <b-row >
                        <b-col v-bind:class="type == 'add' ? 'text-center' : 'pl-0'"><span v-on:click="cancel" class="cancel">Cancel</span></b-col>
                        <b-col v-bind:class="type == 'add' ? 'text-center' : 'text-end pr-0'">
                            <b-row>
                                <b-col><b-button v-on:click="previous" variant="outlined">Previous</b-button></b-col>
                                <b-col><b-button type="submit" variant="primary">Next</b-button></b-col>                            
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
extend('email', email);
extend('required', required);
export default {
    name: "Contact",
    data() {
        return {
            vuePhone: {
                phone: "",
                props: {
                clearable: true,
                fetchCountry: true,
                preferredCountries: ["US", "GB"],
                noExample: false,
                translations: {
                    countrySelectorLabel: "Country co de",
                    countrySelectorError: "Error",
                    phoneNumberLabel: "Enter your phone",
                    example: "Example:"
                }
                }
            }
        };
    },
    props:{
        type:String
    },
    computed: {
        jobContact(){
            return this.type == 'add' ? this.$store.state.workOrders.job_contact : this.$store.state.workOrders.workOrder.job_contact;
        }
    },
    methods: {
        submit(){
            this.$emit('next');
        },
        previous(){
            this.$emit('previous');
        },
        cancel(){            
            this.$router.push({ name: 'WorkOrders' });
        }
    },
};
</script>
<style lang="scss" scoped>
</style>