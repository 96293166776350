<template>
  <div class="login_main">
    <b-card class="login_card">
      <div v-if="!this.$store.state.auth.unlogged.status">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div class="user_icons_box">
            <b-icon icon="person" scale="1"></b-icon>
          </div>
          <ValidationProvider name="Password" rules="required" v-slot="{ failed, errors }" vid="new_password">
            <b-form-input type="password" placeholder="Password" v-model="user.new_password" required
                          :class="[{ 'has-danger': failed }, 'input-login']"></b-form-input>
            <div class="has-danger-error">{{ errors[0] }}</div>
          </ValidationProvider>
          <ValidationProvider name="Password confirmation" rules="required|confirmed:new_password" v-slot="{ failed, errors }">
            <b-form-input type="password" placeholder="Repeat password" v-model="user.new_password_confirmation" required
                          :class="[{ 'has-danger': failed }, 'input-login']"></b-form-input>
            <div class="has-danger-error">{{ errors[0] }}</div>
          </ValidationProvider>
          <b-button type="submit" variant="primary" class="login_btn" :disabled="loading">
            <b-spinner small v-if="loading" class="mr-1"></b-spinner>
            Reset password
          </b-button>
        </form>
      </ValidationObserver>
      </div>
      <div v-else>
        <h4>{{this.$store.state.auth.unlogged.message}}</h4>
        <go-to-login-page/>
      </div>
    </b-card>
  </div>
</template>

<script>
import {extend} from 'vee-validate';
import {required, confirmed} from 'vee-validate/dist/rules';
import '../../../assets/css/login.scss';
import GoToLoginPage from "../../../components/parts/GoToLoginPage";

extend('confirmed', confirmed);
extend('required', required);

export default {
  name: "ResetPassword",
  components: {
    GoToLoginPage,
  },
  data() {
    return {
      user: {
        new_password: "",
        new_password_confirmation: ""
      }
    };
  },
  computed: {
    loading() {
      return this.$store.state.auth.loadingCurrentUser;
    },
  },
  methods: {
    submit() {
      this.$store.dispatch('auth/resetPass', {
        token: this.$route.params.token,
        email: encodeURIComponent(this.$route.query.email),
        user: this.user
      });
    }
  }
}
</script>

<style scoped>

</style>