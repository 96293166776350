var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab"},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"ml-2"},[_vm._v("Loading...")])],1)]):_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('b-row',[_c('b-col',{staticClass:"head-title"},[_vm._v("General Information")]),(!_vm.isEdit)?_c('b-col',{staticClass:"text-end"},[_vm._v("Edit")]):_c('b-col',{staticClass:"text-end"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Save")])],1)],1),_c('div',{staticClass:"title"},[_vm._v("Company Information")]),_c('ValidationProvider',{attrs:{"name":"Company name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Company name "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter company name","required":"","disabled":!_vm.isEdit},model:{value:(_vm.generalInformation.company.name),callback:function ($$v) {_vm.$set(_vm.generalInformation.company, "name", $$v)},expression:"generalInformation.company.name"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Contact person Full name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Contact person Full name "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter contact person Full name","required":"","disabled":!_vm.isEdit},model:{value:(_vm.generalInformation.company.contact_name),callback:function ($$v) {_vm.$set(_vm.generalInformation.company, "contact_name", $$v)},expression:"generalInformation.company.contact_name"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Company Site","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Company Site "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter company Site","required":"","disabled":!_vm.isEdit},model:{value:(_vm.generalInformation.company.contact_name),callback:function ($$v) {_vm.$set(_vm.generalInformation.company, "contact_name", $$v)},expression:"generalInformation.company.contact_name"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Contact email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Contact email "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter contact email","required":"","disabled":!_vm.isEdit},model:{value:(_vm.generalInformation.company.contact_email),callback:function ($$v) {_vm.$set(_vm.generalInformation.company, "contact_email", $$v)},expression:"generalInformation.company.contact_email"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Contact phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Contact phone "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter contact phone","required":"","disabled":!_vm.isEdit},model:{value:(_vm.generalInformation.company.contact_number),callback:function ($$v) {_vm.$set(_vm.generalInformation.company, "contact_number", $$v)},expression:"generalInformation.company.contact_number"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"title"},[_vm._v("Billing Information")]),_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Full Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Full Name "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter Full Name","required":"","disabled":!_vm.isEdit},model:{value:(_vm.generalInformation.billing.full_name),callback:function ($$v) {_vm.$set(_vm.generalInformation.billing, "full_name", $$v)},expression:"generalInformation.billing.full_name"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"PO Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("PO Number "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter PO Number","required":"","disabled":!_vm.isEdit},model:{value:(_vm.generalInformation.billing.po_number),callback:function ($$v) {_vm.$set(_vm.generalInformation.billing, "po_number", $$v)},expression:"generalInformation.billing.po_number"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('ValidationProvider',{attrs:{"name":"Billing Adress Street","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Billing Adress Street "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter billing Adress Street","required":"","disabled":!_vm.isEdit},model:{value:(_vm.generalInformation.billing.address),callback:function ($$v) {_vm.$set(_vm.generalInformation.billing, "address", $$v)},expression:"generalInformation.billing.address"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Email "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter email","required":"","disabled":!_vm.isEdit},model:{value:(_vm.generalInformation.billing.email),callback:function ($$v) {_vm.$set(_vm.generalInformation.billing, "email", $$v)},expression:"generalInformation.billing.email"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Phone "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter phone","required":"","disabled":!_vm.isEdit},model:{value:(_vm.generalInformation.billing.phone_number),callback:function ($$v) {_vm.$set(_vm.generalInformation.billing, "phone_number", $$v)},expression:"generalInformation.billing.phone_number"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('ValidationProvider',{attrs:{"name":"Invoice Notes","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label-work-order"},[_vm._v("Invoice Notes "),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-textarea',{class:[{ 'has-danger': failed }],attrs:{"placeholder":"Write invoice Notes","rows":"5","disabled":!_vm.isEdit},model:{value:(_vm.generalInformation.billing.invoice_notes),callback:function ($$v) {_vm.$set(_vm.generalInformation.billing, "invoice_notes", $$v)},expression:"generalInformation.billing.invoice_notes"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"title"},[_vm._v("Attachments")]),_c('label',{staticClass:"label-work-order"},[_vm._v("Your files")]),_vm._l((_vm.generalInformation.attachments),function(item,index){return _c('div',{key:index,staticClass:"files"},[_c('div',{staticClass:"file",on:{"click":function($event){return _vm.openDoc(item.url)}}},[_vm._v(_vm._s(item.name))])])})],2)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }