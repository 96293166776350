<template>
    <div class="tab">
        <div v-if="loading" class="loading">
            <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
            </div>
        </div>
        <div v-else>
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                    <b-row>
                        <b-col class="head-title">General Information</b-col>
                        <b-col v-if="!isEdit" class="text-end">Edit</b-col>
                        <b-col v-else class="text-end"><b-button type="submit" variant="primary">Save</b-button></b-col>
                    </b-row>
                    <!-- Company Information -->
                    <div class="title">Company Information</div>
                    <ValidationProvider
                        name="Company name"
                        rules="required"
                        v-slot="{ failed, errors }">
                            <label class="label-work-order">Company name <span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter company name"
                                v-model="generalInformation.company.name"
                                required
                                :disabled="!isEdit"
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Contact person Full name"
                        rules="required"
                        v-slot="{ failed, errors }">
                            <label class="label-work-order">Contact person Full name <span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter contact person Full name"
                                v-model="generalInformation.company.contact_name"
                                required
                                :disabled="!isEdit"
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <b-row>
                        <b-col>
                            <ValidationProvider
                                name="Company Site"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Company Site <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter company Site"
                                        v-model="generalInformation.company.contact_name"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider
                                name="Contact email"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Contact email <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter contact email"
                                        v-model="generalInformation.company.contact_email"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider
                                name="Contact phone"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Contact phone <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter contact phone"
                                        v-model="generalInformation.company.contact_number"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <!-- Billing Information -->
                    <div class="title">Billing Information</div>
                    <b-row>
                        <b-col>
                            <ValidationProvider
                                name="Full Name"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Full Name <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter Full Name"
                                        v-model="generalInformation.billing.full_name"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider
                                name="PO Number"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">PO Number <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter PO Number"
                                        v-model="generalInformation.billing.po_number"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <ValidationProvider
                        name="Billing Adress Street"
                        rules="required"
                        v-slot="{ failed, errors }">
                            <label class="label-work-order">Billing Adress Street <span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter billing Adress Street"
                                v-model="generalInformation.billing.address"
                                required
                                :disabled="!isEdit"
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <!-- <b-row>
                        <b-col>
                            <ValidationProvider
                                name="State"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">State <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter state"
                                        v-model="generalInformation.billing.state"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider
                                name="City"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">City <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter city"
                                        v-model="generalInformation.billing.state"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider
                                name="Zip Code"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Zip Code <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter Zip Code"
                                        v-model="generalInformation.billing.zipcode"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                    </b-row> -->
                    <b-row>
                        <b-col>
                            <ValidationProvider
                                name="Email"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Email <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter email"
                                        v-model="generalInformation.billing.email"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider
                                name="Phone"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Phone <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter phone"
                                        v-model="generalInformation.billing.phone_number"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <ValidationProvider
                        name="Invoice Notes"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }">
                            <label class="label-work-order">Invoice Notes <span class="star">*</span></label>
                            <b-form-textarea
                                v-model="generalInformation.billing.invoice_notes"
                                placeholder="Write invoice Notes"
                                rows="5"
                                :disabled="!isEdit"
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-textarea>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <!-- Billing Information -->
                    <div class="title">Attachments</div>
                    <label class="label-work-order">Your files</label>
                    <div v-for="(item, index) in generalInformation.attachments" v-bind:key="index" class="files">
                        <div v-on:click="openDoc(item.url)" class="file">{{item.name}}</div>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
extend('email', email);
extend('required', required);
export default {
    name: "GeneralInformation",
    data() {
        return {
            isEdit:false
        };
    },
    computed: {
        generalInformation() {
          return this.$store.state.workOrders.generalInfo;
        },
        loading(){
          return this.$store.state.workOrders.loadingGeneralInfo;
        },
    },
    methods: {
        openDoc(value){
            window.open(value);
        },
    },
};
</script>
<style  scoped>

</style>