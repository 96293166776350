<template>
    <div>
        <b-row :class="[paidUnpaidHours[2] != undefined ? 'hours-error' : '' ,'justify-content-center hours']">
            <b-col class="col-auto">
                <div class="time">{{(totalHours > 24 || 0 > totalHours ) ?'0' : totalHours  }}</div>
                <div class="title">TOTAL HOURS</div>
            </b-col>
            <b-col class="col-auto">
                <div class="time">{{paidUnpaidHours[0]}}</div>
                <div class="title">PAID HOURS</div>
            </b-col>
            <b-col class="col-auto">
                <div class="time">{{paidUnpaidHours[1]}}</div>
                <div class="title">UNPAID HOURS</div>
            </b-col>
        </b-row>
        <div v-if="paidUnpaidHours[2] != undefined" class="text-center error-text">{{paidUnpaidHours[2]}}</div>
    </div>
        
</template>

<script>
export default {
    name: "Hours", 
    computed: {
        days(){
            return this.$store.state.timeSheet.days;
        },
        breaksTypes(){
            return this.$store.state.timeSheet.breaksTypes;
        },
        totalHours(){
            return this.$store.getters['timeSheet/totalHours'];
        },
        paidUnpaidHours(){
            return this.$store.getters['timeSheet/paidUnpaidHours'];
        },
    }
}
</script>
