<template>
  <div class="login_main">
    <b-card class="login_card">
      <div class="user_icons_box">
        <b-icon icon="person" scale="1"></b-icon>
      </div>
      <h5 class="text-center">{{ this.$store.state.auth.unlogged.message }}</h5>
      <div v-if="this.$store.state.auth.unlogged.activated">
        <go-to-login-page/>
      </div>
    </b-card>
  </div>
</template>

<script>
import '../../../assets/css/login.scss';
import store from '../../../store/index';
import GoToLoginPage from "../../../components/parts/GoToLoginPage";

async function init(to) {
  store.dispatch('auth/activate', {
    token: to.params.token,
    email: encodeURIComponent(to.query.email)
  });
}

export default {
  name: "Activation",
  components: {
    GoToLoginPage,
  },
  data() {
    return {};
  },
  async beforeRouteEnter(to, from, next) {
    init(to);
    next();
  },
  methods: {

  }
}
</script>

<style scoped>

</style>