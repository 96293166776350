import { render, staticRenderFns } from "./WorkersDetail.vue?vue&type=template&id=6c3c8040&scoped=true&"
import script from "./WorkersDetail.vue?vue&type=script&lang=js&"
export * from "./WorkersDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c3c8040",
  null
  
)

export default component.exports