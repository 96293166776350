<template>
    <div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <div  v-bind:class="[type == 'edit' ? 'edit-tab' : '','create-or-edit-tab']">
                    <div  v-if="type == 'add'" class="head-title mb-4">Please provide billing information</div>
                    <ValidationProvider
                        name="Full name"
                        rules="required"
                        v-slot="{ failed, errors }">
                            <label class="label">Full name<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter full name"
                                v-model="billing.contact_person"
                                required
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="PO Number"
                        rules="required"
                        v-slot="{ failed, errors }">
                            <label class="label">PO Number<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter PO Number"
                                v-model="billing.po_number"
                                required
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Email"
                        rules="required|email"
                        v-slot="{ failed, errors }">
                            <label class="label">Email<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter email"
                                v-model="billing.email"
                                required
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Contact Person Phone"
                        rules="required"
                        v-slot="{ failed, errors }">
                            <label class="label">Contact Person Phone<span class="star">*</span></label>
                            <vue-phone-number-input
                                v-model="billing.phone_number"
                                v-bind="vuePhone.props"
                                class="person-phone"
                                required
                                :class="[{ 'has-danger': failed }]"
                            ></vue-phone-number-input>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Billing Address Street"
                        rules="required"
                        v-slot="{ failed, errors }">
                            <label class="label">Billing Address Street<span class="star">*</span></label>
                            <b-form-input
                                type="text"
                                placeholder="Enter billing address street"
                                v-model="billing.address"
                                required
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-input> 
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider
                        name="Invoice Notes"
                        :rules="{ required: true }" 
                        v-slot="{ failed, errors }">
                            <label class="label">Invoice Notes<span class="star">*</span></label>
                            <b-form-textarea
                                v-model="billing.invoice_notes"
                                placeholder="Write invoice notes"
                                rows="5"
                                :class="[{ 'has-danger': failed }]"
                            ></b-form-textarea>
                            <div class="has-danger-error">{{ errors[0]}}</div>
                    </ValidationProvider>
                </div>
                <div v-bind:class="type == 'add'? 'bottom-btns' : 'mt-3'">
                    <b-row >
                        <b-col v-bind:class="type == 'add' ? 'text-center' : 'pl-0'"><span v-on:click="cancel" class="cancel">Cancel</span></b-col>
                        <b-col v-bind:class="type == 'add' ? 'text-center' : 'text-end pr-0'">
                            <b-row>
                                <b-col><b-button v-on:click="previous" variant="outlined">Previous</b-button></b-col>
                                <b-col><b-button type="submit" variant="primary">Next</b-button></b-col>                            
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
extend('email', email);
extend('required', required);
export default {
    name: "Billing",
    data() {
        return {
            vuePhone: {
                phone: "",
                props: {
                clearable: true,
                fetchCountry: true,
                preferredCountries: ["US", "GB"],
                noExample: false,
                translations: {
                    countrySelectorLabel: "Country co de",
                    countrySelectorError: "Error",
                    phoneNumberLabel: "Enter your phone",
                    example: "Example:"
                }
                }
            }
        };
    },
    props:{
        type:String
    },
    computed: {
        billing(){
            return this.type == 'add' ? this.$store.state.workOrders.billing : this.$store.state.workOrders.workOrder.billing;
        },
        params(){
            return this.$store.state.workOrders.params;
        }
    },
    methods: {
        submit(){
            this.$emit('next');
        },
        previous(){
            this.$emit('previous');
        },
        cancel(){            
            this.$router.push({ name: 'WorkOrders' });
        }
    },
};
</script>
<style lang="scss" scoped>
</style>