<template>
    <div class="member-details mb-3">
        <b-row class="m-0">
            <b-col md="12" lg="3" class="title-col">Name</b-col>
            <b-col md="12" lg="3" class="p-0 name-row">
                <!-- Name private -->
                <div v-if="privatePage" :class="[isUpdate ? 'name-update' : 'name', 'pl-3']">
                    {{isUpdate ? dataEntryUpdate.user_name : currentUser.name}}
                </div>
                <!-- Name public -->
                <div v-else>
                    <b-form-input type="text" placeholder="Type name..." v-model="dataEntryUpdate.user_name" required /> 
                </div>
            </b-col>
            <b-col md="12" lg="3" class="title-col">Email</b-col>
            <b-col md="12" lg="3" class="p-0 name-row">
                <!-- Email private -->
                <div v-if="privatePage" :class="[isUpdate ? 'name-update' : 'name', 'pl-3']">
                  {{ isUpdate ? dataEntryUpdate.user_email : currentUser.email}}
                </div>
                <!-- Email public -->
                <div v-else>
                    <b-form-input type="email" placeholder="Type email..." v-model="dataEntryUpdate.user_email" required /> 
                </div>
            </b-col>
        </b-row>

        <b-row class="m-0">
            <b-col md="12" lg="3" class="title-col">{{privatePage && !isUpdate? 'Work order' : 'Company'}}</b-col>
            <!-- Work order -->
            <b-col md="12" lg="9" v-if="privatePage" class="p-0 name-row">
                <!-- Update -->
                <div v-if="isUpdate" class="name-update pl-3">{{dataEntryUpdate.company}}</div>
                <v-select 
                    v-else
                    label="work_order.name" 
                    placeholder="Select work order ..."
                    v-model="worker" 
                    :options="workOrders" 
                    :loading="loadingWorkOrders"
                    :getOptionLabel="worker => worker.work_order.name + ' (' + worker.work_order.company.name + ')'"
                    @input="checkOnFilledDataForTheSelectedDate()">
                        <template #search="{attributes, events}">
                            <input class="vs__search" :required="!worker" v-bind="attributes" v-on="events" />
                        </template>
                        <template v-slot:option="option">                                        
                            {{ option.work_order.name + ' (' + option.work_order.company.name + ')' }}
                        </template>
                </v-select>
            </b-col>
            <!-- Company -->
            <b-col md="12" lg="9" v-else class="p-0 name-row">
                <v-select label="name"  placeholder="Select company ..." v-model="dataEntryUpdate.company" :options="companiesList" :loading="loadingCompany">
                    <template #search="{attributes, events}">
                        <input class="vs__search" :required="!dataEntryUpdate.company" v-bind="attributes" v-on="events" />
                    </template>
                </v-select> 
            </b-col>
        </b-row>

        <b-row class="m-0">
            <b-col md="12" lg="3" class="title-col">Location</b-col>
            <b-col md="12" lg="3" class="p-0 name-row">
                <!-- Location private -->
                <b-col v-if="isUpdate" class="name-update">
                    {{dataEntryUpdate.user_location}}
                </b-col>
                <div v-else-if="privatePage" class="desc">
                    <div v-if="worker != null">{{worker.location.state}} {{worker.location.city}} {{worker.location.address}}</div>
                </div>
                <!-- Location public -->
                <div v-else>
                    <b-form-input type="text" placeholder="Type location..." v-model="dataEntryUpdate.user_location" required/> 
                </div>
            </b-col>
            <b-col md="12" lg="3" class="title-col">Position</b-col>
            <b-col md="12" lg="3" class="p-0 name-row">
                <!-- Position private -->
                <b-col v-if="isUpdate" class="name-update">
                    {{dataEntryUpdate.user_position}}
                </b-col>
                <div v-else-if="privatePage" class="desc">
                    <div v-if="worker != null">{{worker.position.title}}</div>
                </div>
                <!-- Position public -->
                <div v-else>
                    <b-form-input type="text" placeholder="Type position..." v-model="dataEntryUpdate.user_position" required/> 
                </div>
            </b-col>
        </b-row>
        <!-- Update -->
        <b-row v-if="isUpdate" class="m-0">
            <b-col md="12" lg="3" class="title-col">Work order</b-col>
            <b-col md="12" lg="9" class="p-0 name-row">
                <v-select 
                    label="name" 
                    placeholder="Select work order ..."
                    v-model="workOrderComputed" 
                    :options="workOrders" 
                    :loading="loadingWorkOrders"
                    :clearable="false"
                    @input="checkOnFilledDataForTheSelectedDate()">
                        <template #search="{attributes, events}">
                            <input class="vs__search" :required="!workOrderComputed" v-bind="attributes" v-on="events"/>
                        </template>
                </v-select>
            </b-col>
        </b-row>
        <b-row v-if="isUpdate" class="m-0">
            <b-col md="12" lg="3" class="title-col">Worker</b-col>
            <b-col md="12" lg="9" class="p-0 name-row">
                <v-select 
                    label="name" 
                    :rules="[(v) => !!v || 'Worker is required']" 
                    required 
                    placeholder="Select worker ..." 
                    v-model="worker" 
                    :options="workers"
                    :loading="loadingWorkers"
                    :clearable="false">
                        <template #search="{attributes, events}">
                            <input class="vs__search" :required="!worker" v-bind="attributes" v-on="events"/>
                        </template>
                </v-select>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "UserInfo", 
    props:{
        privatePage:Boolean,
        isUpdate:Boolean
    },
    computed: {
        dataEntryUpdate(){
            return this.$store.state.timeSheet.dataEntryUpdate;
        },
        currentUser(){
            return JSON.parse(localStorage.getItem('currentUser'));
        },
        companiesList() {
            return this.$store.state.common.companies;
        },
        loadingCompany() {
            return this.$store.state.common.loadingCompanies;
        },
        worker: {
            get() {
                return this.$store.state.timeSheet.worker;
            },
            set(value) {
                this.$store.state.timeSheet.worker = value;
            }
        },
        workOrders(){
            return this.isUpdate ? this.$store.state.common.workOrders : this.$store.state.timeSheet.workOrders;
        },
        loadingWorkOrders(){
            return this.isUpdate ? this.$store.state.common.loadingWorkOrders : this.$store.state.timeSheet.loadingWorkOrders;
        },
        workers(){
            return this.$store.state.common.workers;
        },
        loadingWorkers(){
            return this.$store.state.common.loadingWorkers;
        },
        days(){
            return this.$store.state.timeSheet.days;
        },
        workOrderComputed: {
            get() {
                return this.$store.state.timeSheet.workOrder
            },
            set(value) {
                this.worker = null;
                this.$store.dispatch('common/getWorkersListByWorkOrder', value.id);
                this.$store.state.timeSheet.workOrder = value;
            }
        },
    },
    methods:{
        checkOnFilledDataForTheSelectedDate(){
            for(let i = 0; i < this.days.length; i++){
                if(this.worker !=null && this.days[i].dateStart != null ){
                    this.$store.dispatch('timeSheet/checkOnFilledDataForTheSelectedDate',  { id: this.worker.worker_id == undefined ? this.worker.id : this.worker.worker_id, date: this.days[i].dateStart}).then(() =>{
                        if(this.$store.state.timeSheet.checkDate.regular.length > 0 || this.$store.state.timeSheet.checkDate.simplified.length > 0){
                            this.days[i].dateStart =  null;
                            this.isShowCheckData = true;
                        };
                    });
                }
            }
        }
        
    }
}
</script>