var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{class:[_vm.type == 'edit' ? 'edit-tab' : '','create-or-edit-tab']},[(_vm.type == 'add')?_c('div',{staticClass:"head-title mb-4"},[_vm._v("Please provide billing information")]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Full name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Full name"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter full name","required":""},model:{value:(_vm.billing.contact_person),callback:function ($$v) {_vm.$set(_vm.billing, "contact_person", $$v)},expression:"billing.contact_person"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"PO Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("PO Number"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter PO Number","required":""},model:{value:(_vm.billing.po_number),callback:function ($$v) {_vm.$set(_vm.billing, "po_number", $$v)},expression:"billing.po_number"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Email"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter email","required":""},model:{value:(_vm.billing.email),callback:function ($$v) {_vm.$set(_vm.billing, "email", $$v)},expression:"billing.email"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Contact Person Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Contact Person Phone"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('vue-phone-number-input',_vm._b({staticClass:"person-phone",class:[{ 'has-danger': failed }],attrs:{"required":""},model:{value:(_vm.billing.phone_number),callback:function ($$v) {_vm.$set(_vm.billing, "phone_number", $$v)},expression:"billing.phone_number"}},'vue-phone-number-input',_vm.vuePhone.props,false)),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Billing Address Street","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Billing Address Street"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-input',{class:[{ 'has-danger': failed }],attrs:{"type":"text","placeholder":"Enter billing address street","required":""},model:{value:(_vm.billing.address),callback:function ($$v) {_vm.$set(_vm.billing, "address", $$v)},expression:"billing.address"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Invoice Notes","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Invoice Notes"),_c('span',{staticClass:"star"},[_vm._v("*")])]),_c('b-form-textarea',{class:[{ 'has-danger': failed }],attrs:{"placeholder":"Write invoice notes","rows":"5"},model:{value:(_vm.billing.invoice_notes),callback:function ($$v) {_vm.$set(_vm.billing, "invoice_notes", $$v)},expression:"billing.invoice_notes"}}),_c('div',{staticClass:"has-danger-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{class:_vm.type == 'add'? 'bottom-btns' : 'mt-3'},[_c('b-row',[_c('b-col',{class:_vm.type == 'add' ? 'text-center' : 'pl-0'},[_c('span',{staticClass:"cancel",on:{"click":_vm.cancel}},[_vm._v("Cancel")])]),_c('b-col',{class:_vm.type == 'add' ? 'text-center' : 'text-end pr-0'},[_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"variant":"outlined"},on:{"click":_vm.previous}},[_vm._v("Previous")])],1),_c('b-col',[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Next")])],1)],1)],1)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }