<template>
    <div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <div v-bind:class="[type == 'edit' ? 'edit-tab' : '','create-or-edit-tab']">
                    <div v-if="type == 'add'" class="head-title">Which position(s) do you need?</div>
                    <position
                        v-for="item in positionCount"
                        v-bind:key="item"
                        :index="item"
                        @delete="positionCount--"
                    />
                    <b-button v-on:click="addPosition" variant="primary" class="add-position">
                        <img src="@/assets/icons/plus-circle.svg" class="mr-2 mt-n1"/>Add one more position
                    </b-button>
                </div>
                <div v-bind:class="type == 'add'? 'bottom-btns' : 'mt-3'">
                    <b-row >
                        <b-col v-bind:class="type == 'add' ? 'text-center' : 'pl-0'"><span v-on:click="cancel" class="cancel">Cancel</span></b-col>
                        <b-col v-bind:class="type == 'add' ? 'text-center' : 'text-end pr-0'">
                            <b-row>
                                <b-col><b-button v-on:click="previous" variant="outlined">Previous</b-button></b-col>
                                <b-col><b-button type="submit" variant="primary">Next</b-button></b-col>                            
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import Position from './Position.vue';

export default {
    name: "Positions",
    components: { Position },
    data() {
        return {
            positionCount:1,
        };
    },
    props:{
        type:String
    },
    computed: {
        newWorkOrder(){
            return this.$store.state.workOrders.newWorkOrder;
        },
    },
    methods: {
        submit(){
            this.$emit('next');
        },
        previous(){
            this.$emit('previous');
        },
        cancel(){            
            this.$router.push({ name: 'WorkOrders' });
        },
        addPosition(){
            this.positionCount ++;
            this.$store.state.workOrders.positions.push(
                {
                    title: null,
                    people_needed: null,
                    regular_pay_rate: null,
                    overtime_pay_rate: null,
                    doubletime_pay_rate: null,
                    payroll_hourly_calculator: null,
                    regular_bill_rate: null,
                    doubletime_bill_rate: null,
                    overtime_bill_rate: null,
                    invoice_hourly_calculator: null,
                    description: null,
                    dept_code: null,
                    comp_code: null,
                }
            );
        }
    },
};
</script>
<style lang="scss" scoped>
</style>