<template>
  <div class="details">
    <b-row class="justify-content-md-center mb-3">
      <b-col class="bread-crumbs">
        <span class="redirect" v-on:click="redirect('Dashboard')">Dashboard</span>
        <span class="arrow">></span>
        <span class="redirect" v-on:click="redirect('WorkOrders')">Work orders</span>
        <span class="arrow">></span>
        <span class="active">Work order details #{{workOrders.airtable_project_id}}</span>
      </b-col>
    </b-row>

    <div v-if="loading" class="loading">
      <div class="text-center text-secondary my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Loading...</strong>
      </div>
    </div>
    <div v-else>
      <div class="small-info-company">{{workOrders.company}} <span class="bull">&bull;</span> {{workOrders.start_date}}</div>
      <div>
        <span class="airtable_project_id">#{{workOrders.airtable_project_id}}</span>
        <span class="title-work-order">{{workOrders.title}}</span>
        <span v-bind:class="[workOrders.job_status == 'planning'  ? 'planning' 
          : workOrders.job_status == 'confirmed' || workOrders.job_status == 'complete' ? 'confirmed' 
          : workOrders.job_status == 'ongoing' ? 'ongoing' 
          : workOrders.job_status == 'cancelled' ? 'cancelled' :'','status']">
            {{getStatusName(workOrders.job_status)}}
        </span>
      </div>
      <b-row class="justify-content-md-start">
        <b-col class="col-auto people-card">
          <div>Needed</div>
          <div class="people">{{workOrders.workers.required}} people</div>
        </b-col>
        <b-col class="col-auto people-card">
          <div>Added</div>
          <div class="people">{{workOrders.workers.confirmed}} people</div>
        </b-col>
        <b-col class="col-auto people-card">
          <div>Working</div>
          <div class="people">{{workOrders.workers.working}} people</div>
        </b-col>
      </b-row>
      <b-tabs content-class="mt-3">
        <b-tab title="Job Information" active>
          <job-information/>
        </b-tab>
        <b-tab title="General Information">
          <general-information/>
        </b-tab>
        <b-tab title="Positions">
        </b-tab>
        <b-tab title="Workers">
        </b-tab>
        <b-tab title="Work Order History">
        </b-tab>
        <b-tab title="Time sheet templates">
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import GeneralInformation from '../../components/detailsTabs/GeneralInformation.vue';
import JobInformation from '../../components/detailsTabs/JobInformation.vue';
import store from '../../store/index';
async function workOrdersDetails(id) {
  store.dispatch('workOrders/jobInformation',id);
  store.dispatch('workOrders/generalInfo',id);
  store.dispatch('workOrders/history',id);
  store.dispatch('workOrders/contacts',id);
  store.dispatch('workOrders/billing',id);
}
export default {
    name: "WorkOrdersDetail",
    async beforeRouteEnter(to, from, next) {
        workOrdersDetails(to.params.id);
        next();
    },
    async beforeRouteUpdate(to, from, next) {
        workOrdersDetails(to.params.id);
        next();
    },
    components: {
      GeneralInformation,
      JobInformation
    },
    computed: {
        workOrders() {
          return this.$store.state.workOrders.details;
        },
        loading(){
          return this.$store.state.workOrders.loadingDetails;
        },
    },
    data() {
        return {
        };
    },
    methods: {
      redirect(name){            
        this.$router.push({ name: name });
      },
      getStatusName(name){
        return name.charAt(0).toUpperCase() + name.slice(1)
      },
    },
};
</script>
<style  scoped>
</style>