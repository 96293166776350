<template>
  <div class="tab">
      <b-row class="justify-content-md-center">
        <b-col class="head-title">Activity</b-col>
        <div class="id-user">{{userId}}</div>
        <b-col class="col-auto">Filter: </b-col>
        <b-col v-bind:class="[filterTab == 1 ? 'filter-active' : 'filter','col-auto']" v-on:click="filterTab = 1">All</b-col>
        <b-col v-bind:class="[filterTab == 2 ? 'filter-active' : 'filter','col-auto']" v-on:click="filterTab = 2">Notes</b-col>
        <b-col v-if="false" v-bind:class="[filterTab == 3 ? 'filter-active' : 'filter','col-auto']" v-on:click="filterTab = 3">Emails</b-col>
        <b-col v-if="false" v-bind:class="[filterTab == 4 ? 'filter-active' : 'filter','col-auto']" v-on:click="filterTab = 4">Call</b-col>
        <b-col v-if="false" v-bind:class="[filterTab == 5 ? 'filter-active' : 'filter','col-auto']" v-on:click="filterTab = 5">Other</b-col>
      </b-row>
      <div>{{activity}}</div>    
  </div>
</template>

<script>
export default {
    name: "Activity",
    props: {
        user:Object,
        type:String
    },
    watch: {
        userId(id) {
            if(id != undefined)
                this.getActivity();
        }
    },
    computed: {
        activity() {
          return this.$store.state[this.type].activity;
        },
        userId(){
            if(this.user.id != undefined)
                this.getActivity();
            return this.user.id
        }
    },
    data() {
        return {
            filterTab: 1
        };
    },
    methods: {
        getActivity() {
            this.$store.dispatch(this.type + '/activity',this.user.id);
        }
    },
};
</script>
<style  scoped>

</style>