<template>
    <div class="tab">
        <div v-if="loading" class="loading">
            <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
            </div>
        </div>
        <div v-else>
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                    <b-row>
                        <b-col class="head-title">Job Information</b-col>
                        <b-col v-if="!isEdit" class="text-end">Edit</b-col>
                        <b-col v-else class="text-end"><b-button type="submit" variant="primary">Save</b-button></b-col>
                    </b-row>
                    <!-- Summary of job -->
                    <div class="title">Summary of job</div>
                    <b-row>
                        <b-col>
                            <ValidationProvider
                                name="Name of Work Order"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Name of Work Order <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter name of Work Order"
                                        v-model="details.title"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider
                                name="Work order #"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Work order # <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter work order #"
                                        v-model="details.airtable_project_id"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider
                                name="Branch"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Branch <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter branch"
                                        v-model="details.branch"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-md-start ">
                        <b-col>
                            <ValidationProvider
                                name="Period date"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Period date <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter start date"
                                        v-model="details.start_date"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col class="col-auto p-0 date-margin pt-2">-</b-col>
                        <b-col class="date-margin">
                            <ValidationProvider
                                name="Period date"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter start date"
                                        v-model="details.start_date"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider
                                name="Job Duration"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Job Duration <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter job Duration"
                                        v-model="details.job_duration"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <ValidationProvider
                                name="People needed"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">People needed <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter people needed"
                                        v-model="details.workers.required"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider
                                name="People Added"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">People Added <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter people Added"
                                        v-model="details.workers.confirmed"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider
                                name="People Working"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">People Working <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter people Working"
                                        v-model="details.workers.workers"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <ValidationProvider
                                name="Job Category"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Job Category <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter job Category"
                                        v-model="details.job_category"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider
                                name="Job specialty"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Job specialty <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter job specialty "
                                        v-model="details.job_specialty"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="8">
                            <ValidationProvider
                                name="Job location"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Job location <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter job location"
                                        v-model="details.job_category"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider
                                name="Work hours"
                                rules="required"
                                v-slot="{ failed, errors }">
                                    <label class="label-work-order">Work hours <span class="star">*</span></label>
                                    <b-form-input
                                        type="text"
                                        placeholder="Enter work hours"
                                        v-model="details.work_hours"
                                        required
                                        :disabled="!isEdit"
                                        :class="[{ 'has-danger': failed }]"
                                    ></b-form-input> 
                                    <div class="has-danger-error">{{ errors[0]}}</div>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
extend('email', email);
extend('required', required);
export default {
    name: "JobInformation",
    data() {
        return {
            isEdit:false
        };
    },
    computed: {
        details() {
          return this.$store.state.workOrders.details;
        },
        loading(){
          return this.$store.state.workOrders.loadingDetails;
        },
    },
    methods: {
    },
};
</script>
<style  scoped>
.date-margin{
    margin-top: 53px;
}
</style>